import React, { useState, useEffect, memo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginUserPreferences } from "../store/actions/loginAction";
import ReportsGalicia from "../components/ReportsGalicia";
import Sidebar from "./Sidebar";
import firebase from "../config/firebaseIO";

const db = firebase.firestore()

const ReportsGaliciaClient = () => {

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const [arrayDeOpciones, setArrayDeOpciones] = useState([]);

  const [opcionFilter, setEl2] = useState("");
  const [fecha1, setFecha1] = useState("");
  const [fecha2, setFecha2] = useState("");
  const [search, setSearch] = useState("");

  const [surveyAnswers, SetAnswers] = useState([]);
  const [surveyPolls, SetSurveysPolls] = useState([]);


  const [FilterOn, setfilterOn] = useState(false);

  const loginUser = useSelector(state => state.user.loginUser);
  const language = useSelector(state => state.user.language);
  const darkTheme = useSelector(state => state.user.darkTheme);

  let getTime = new Date().getTime() / 1000;
  let oneWeekOld = getTime - 604800;

  const [Oldsurveys, SetOldSurveys] = useState([]);

  let getPolls1 = useCallback(async () => {
    try {
      let poll = await db
        .collection("companies")
        .doc("HZZb5MxJI1zVkQJ8MJwk")
        .collection("SURVEY_POLLS")
        .get();

      let polls = [];
      poll.docs.forEach((ele) => {
        polls.push({
          data: ele.data(),
          id: ele.id,
        });

        SetSurveysPolls(polls.slice());
        let respuesta = [];

        polls.forEach((ele) => {
          db.collection("companies")
            .doc("HZZb5MxJI1zVkQJ8MJwk")
            .collection("SURVEY_POLLS")
            .doc(ele.id)
            .collection("ANSWERS")
            .where("timestamp", ">=", oneWeekOld)
            .orderBy("timestamp", "desc")
            // .limit(35)
            .onSnapshot((querySnapshot) => {
              querySnapshot.docChanges().forEach((change) => {
                if (change.type === "added") {
                  respuesta.push(change.doc.data());
                }
              });
              SetAnswers(respuesta.slice());
            });
        });
        ;
      })

    } catch (err) {
      console.log(err);
    }


  })

  let getPolls2 = async (start, end) => {

    if (FilterOn == true) {
      try {
        return new Promise(async (resolve) => {
          let surveys = []
          let old = await db
            .collection("companies")
            .doc("HZZb5MxJI1zVkQJ8MJwk")
            .collection("SURVEY_POLLS")
            .get();


          old.docs.forEach(async (singleSurvey) => {
            await db
              .collection("companies")
              .doc("HZZb5MxJI1zVkQJ8MJwk")
              .collection("SURVEY_POLLS")
              .doc(singleSurvey.id)
              .collection("ANSWERS")
              .where("timestamp", ">=", start / 1000)
              .where("timestamp", "<=", (end + 86400000) / 1000)
              //  .limit(35)
              .get().then((docs) => {
                docs.forEach(ele => surveys.push(ele.data()));
                surveys = surveys.slice();
                resolve(SetOldSurveys(surveys));
              })

          })

        })

      } catch (err) {
        console.log(err)
      }

    } else {
      getPolls1();


    }
  };

  useEffect(() => {
    getPolls2(fecha1, fecha2);

  }, [FilterOn, fecha1, fecha2])




  let onChange = (valor) => {
    setArrayDeOpciones(valor.map(ele => ele.value))
  }

  let reset = (startDate, endDate, searchBar, arrayDeOpciones) => {
    setEl2([]);
    setFecha1("");
    setFecha2("");
    setSearch("");
    searchBar.value = "";
    startDate.value = "";
    endDate.value = "";
    arrayDeOpciones.value = [];
    setfilterOn(false);
    SetOldSurveys([]);

  };

  let handleClick = (arrayDeOpciones, startDate, endDate, searchBar) => {

    if (startDate.value != "" && endDate.value != "" && arrayDeOpciones != "" && searchBar.value != "") {
      setFecha1(new Date(startDate.value).getTime());
      setFecha2(new Date(endDate.value).getTime());
      setEl2(arrayDeOpciones);
      setSearch(searchBar.value);
      setfilterOn(true);

    } else if (startDate.value != "" && arrayDeOpciones != "") {
      setFecha1(new Date(startDate.value).getTime());
      setFecha2(new Date(endDate.value).getTime());
      setEl2(arrayDeOpciones);
      setSearch(searchBar.value);
      setfilterOn(true);


    } else if (startDate.value != "" && endDate.value != "" && searchBar.value != "") {

      setFecha1(new Date(startDate.value).getTime());
      setFecha2(new Date(endDate.value).getTime());
      setSearch(searchBar.value);
      setEl2(arrayDeOpciones);
      setfilterOn(true);

    }
    else if (searchBar.value != "" && arrayDeOpciones != "") {
      setSearch(searchBar.value);
      setEl2(arrayDeOpciones);
      setFecha1("");
      setFecha2("");
    }
    else if (arrayDeOpciones != "") {
      setEl2(arrayDeOpciones);
      setFecha1("");
      setFecha2("");
      setSearch("");
    }
    else if (startDate.value != "" && endDate.value != "") {

      setFecha1(new Date(startDate.value).getTime());
      setFecha2(new Date(endDate.value).getTime());
      setEl2("")
      setSearch("");
      setfilterOn(true);

    }
    else if (searchBar.value != "") {
      setSearch(searchBar.value);
      setEl2("");
      setFecha1("");
      setFecha2("");
    } else {
      setEl2("");
      setFecha1("");
      setFecha2("");
      setSearch("");
      setfilterOn(false);

    }
  };


  return (
    <div>
      <Sidebar />
      <ReportsGalicia
        screenSize={screenSize}
        darkModeState={darkTheme}
        language={language}
        reports={surveyAnswers}
        handleClick={handleClick}
        reset={reset}
        opcionFilter={opcionFilter}
        fecha1={fecha1}
        fecha2={fecha2}
        search={search}
        onChange={onChange}
        arrayDeOpciones={arrayDeOpciones}
        surveyPolls={surveyPolls}
        Oldsurveys={Oldsurveys}
      />
    </div>
  );
}

export default ReportsGaliciaClient;