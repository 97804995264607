import React, { useState } from "react";
import PopUpAnswers from "./PopUpAnswers";
import { Link } from "react-router-dom";

export default ({
    screenSize,
    darkModeState,
    language,
    reports,
    handleClick,
    fecha1,
    fecha2,
    search,
    reset,
    surveyPolls,
    oldsurveys,
    popUpAnswersState,
    handlePopUpAnswers,
    popUpInformationAnswers
}) => {
    const styles = {
        backColor: {
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            height: "100%",
            backgroundColor: `${darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"
                }`,
        },
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 800 ? ("3% 2% 3% 20%") : ("30px")}`,
            padding: `${screenSize > 800 ? "3% 2% 3% 280px" : "30px"}`,
            height: "100%",
            // overflow: `auto`,
        },
        containerLoader: {
            display: "flex",
            alignItem: "center",
            justifyContent: "center",
            height: "80%",
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 800 ? "0px 20px" : "0px"}`,
            marginBottom: `${screenSize > 800 ? "0px" : "30px"}`,
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
        },
        titleNavbar: {
            marginBottom: `${screenSize > 800 ? "5px" : "10px"}`,
            fontSize: "28px",
            color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
            fontFamily: "InterBold",
            lineHeight: "1.4",
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            fontFamily: "InterBold",
        },
        toogleNavbar: {
            display: "flex",
            flex: 4,
            alignItems: "center",
            justifyContent: `${screenSize > 800 ? "flex-end" : "center"}`,
            flexDirection: `${screenSize > 800 ? "row" : "column"}`,
        },
        textToogle: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: "inherit",
            border: "none",
            outline: "none",
            padding: "12px",
            backgroundColor: `transparent`,
        },
        textUser: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: "inherit",
            border: "none",
            outline: "none",
            padding: 0,
            backgroundColor: `transparent`,
        },
        buttonNew: {
            textDecoration: "none",
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: "none",
            margin: "20px",
            // margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
            width: `${screenSize > 800 ? "180px" : <></>}`,
        },
        total: {
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            margin: `${screenSize > 800 ? "10px 20px 10px" : "0px"}`,
        },
        filters: {
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "space-between",
            margin: `${screenSize > 800 ? "10px 20px 20px" : "0px"}`,
        },
        filterGroup: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            padding: '0px 20px 0px 0px'
        },
        filterDateGroup: {
            display: "flex",
            flexDirection: "row",
        },
        filterLabel: {
            fontFamily: "InterBold",
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            padding: '0px 5px'
        },
        filterInputDate: {
            border: 'none',
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            fontFamily: "InterBold",
            width: '100%',
            height: '40px',
            borderRadius: "5px",
            padding: '5px 10px',
            margin: '0px 5px 0px 0px'
        },
        filterInput: {
            border: 'none',
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            fontFamily: "InterBold",
            width: '100%',
            height: '40px',
            borderRadius: "5px",
            padding: '5px 10px'
        },
        filterButton: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textDecoration: "none",
            outline: 0,
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: 'none',
            borderRadius: 5,
            margin: 0,
            padding: '8px 13px',
            width: '100px'
        },
        resetButton: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textDecoration: "none",
            outline: 0,
            color: "#4dc2f1",
            backgroundColor: "transparent",
            border: '1px #4dc2f1 solid',
            borderRadius: 5,
            margin: "0px 0px 0px 10px",
            padding: '8px 13px',
            width: '100px'
        },
        singleReportReference: {
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            margin: `${screenSize > 800 ? "0px 20px" : "0px"}`,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "5px",
            padding: "0px 25px",
            textDecoration: "none",
            border: "none",
            outline: "none",
            // margin: `${screenSize > 800 ? "1.4% 0px -10px" : "10px"}`,
        },
        reports: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
            alignItems: "center",
            margin: `${screenSize > 800 ? "0px 20px" : "0px"}`,
            overflow: `auto`,

        },
        singleReport: {
            display: "flex",
            //   width: `${screenSize > 800 ? ("95%") : ("80vw")}`,
            width: `${screenSize > 800 ? "100%" : "80vw"}`,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "5px",
            padding: "10px 25px",
            textDecoration: "none",
            border: "none",
            outline: "none",
            margin: `${screenSize > 800 ? "1.4% 0px -10px" : "10px"}`,
        }, singleBadReport: {
            display: "flex",
            //   width: `${screenSize > 800 ? ("95%") : ("80vw")}`,
            width: `${screenSize > 800 ? "100%" : "80vw"}`,
            flexDirection: "column",
            backgroundColor: "rgba(255, 0, 0, 0.2)",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "5px",
            padding: "10px 25px",
            textDecoration: "none",
            border: "none",
            outline: "none",
            margin: `${screenSize > 800 ? "1.4% 0px -10px" : "10px"}`

        },
        lineDetail: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        date: {
            display: "flex",
            flex: 1,
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            margin: 0,
            fontSize: "12px",
            fontFamily: "InterBold",
            textAlign: 'left',
            alignSelf: "center",
            justifyContent: "flex-start",
            paddingRight: '15px'
        },
        phoneNumber: {
            display: "flex",
            flex: 1,
            color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
            margin: 0,
            fontSize: "12px",
            fontFamily: "InterBold",
            textAlign: "left",
            alignSelf: "center",
            justifyContent: "flex-start",
            paddingRight: '15px'
        },
        option: {
            display: "flex",
            flex: 2,
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            margin: 0,
            fontSize: "12px",
            fontFamily: "InterBold",
            textAlign: "left",
            alignSelf: "center",
            justifyContent: "flex-start",
            paddingRight: '15px'
        },
        information: {
            display: "flex",
            flex: 2,
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            margin: 0,
            fontSize: "12px",
            fontFamily: "InterBold",
            textAlign: "left",
            alignSelf: "center",
            justifyContent: "flex-start",
            paddingRight: '15px'
        },
        answer: {
            display: "flex",
            flex: 1,
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            margin: 0,
            fontSize: "12px",
            fontFamily: "InterBold",
            textAlign: 'left',
            alignSelf: "center",
            justifyContent: "center",
            paddingRight: '15px'
        },
        time: {
            display: "flex",
            flex: 1,
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            margin: 0,
            fontSize: "12px",
            fontFamily: "InterBold",
            textAlign: 'left',
            alignSelf: "center",
            justifyContent: "flex-start",
            //   paddingRight: '0px'
        },
        singleCounter: {
            display: "flex",
            width: `${screenSize > 800 ? "100%" : "80vw"}`,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "5px",
            padding: "10px 25px",
            textDecoration: "none",
            border: "none",
            outline: "none",
            margin: `${screenSize > 800 ? "2.4% 0px -10px" : "10px"}`,
            backgroundColor: '#4dc2f1',
            color: '#fff'
        },
        titleCounter: {
            display: "flex",
            flex: 4,
            color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
            margin: "0px",
            fontSize: "18px",
            fontFamily: "InterBold",
            textAlign: "start",
            alignSelf: "center",
            color: '#fff'
        },
        numberCounter: {
            display: "flex",
            flex: 2,
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            margin: 0,
            fontSize: "14px",
            fontFamily: "InterBold",
            textAlign: 'end',
            alignSelf: "center",
            justifyContent: "flex-end",
            color: '#fff'
        },
        downloadsContainer: {
            display: "flex",
            width: `${screenSize > 800 ? "100%" : "80vw"}`,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            borderRadius: "5px",
            padding: "10px 0px",
            textDecoration: "none",
            border: "none",
            outline: "none",
            margin: `${screenSize > 800 ? "1.4% 0px 0px" : "10px"}`,
            backgroundColor: 'transparent',
        },
        downloadsButton: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textDecoration: "none",
            outline: 0,
            color: "#4dc2f1",
            backgroundColor: "transparent",
            border: '1px #4dc2f1 solid',
            borderRadius: 5,
            margin: "0px 0px 0px 10px",
            padding: '8px 13px',
            width: '180px'
        },
        loader: {
            display: "flex",
            alignSelf: "center",
        },
        dropDownMenu: {
            display: "flex",
            width: "100%",
            justifyContent: "center",
        },
    };


    let encuestas = [];
    let counterSurveys = [];

    if (fecha1 && fecha2) {
        encuestas = oldsurveys.slice()
    } else {
        encuestas = reports.slice()
    }

    // encuestas.forEach((ele, index) => {
    //     ele.questions.forEach((e, i) => {
    //         if (e == '¿Considera que pudo resolver su consulta?' && encuestas[index].nestedAnswers[i] == "1") {
    //             encuestas[index].nestedAnswers[i] = "Sí"
    //         } else if (e == '¿Considera que pudo resolver su consulta?' && encuestas[index].nestedAnswers[i] == "2") {
    //             encuestas[index].nestedAnswers[i] = "No"
    //         }
    //     })
    //     // if (ele.phoneNumber == "anonymous" || ele.phoneNumber == "Anonymous" || ele.phoneNumber == "Anónimo" || ele.phoneNumber == "") {
    //     //     { language === "english" ? encuestas[index].phoneNumber = "Anonymous" : encuestas[index].phoneNumber = "Anónimo" }
    //     // }
    //     // if (ele.agentName == "0 - undefined" || ele.agentName == " - undefined") {
    //     //     encuestas[index].agentName = "Anónimo"
    //     //     { language === "english" ? encuestas[index].agentName = "Anonymous" : encuestas[index].agentName = "Anónimo" }
    //     // }
    // });

    //FILTROS
    //Captura los valores de los filtros
    let startDate = document.getElementById("startDate");
    let endDate = document.getElementById("endDate");
    let searchBar = document.getElementById("searchBar");

    //FILTRO POR SEARCH BAR Y FECHA
    if (fecha1 != "" && fecha2 != "" && search != "") {
        if ((fecha2 + 86400) - fecha1 >= (2629743 + 86400)) {
            window.alert("La fecha debe ser menor a un mes");
            reset(startDate, endDate, searchBar)
        } else {
            encuestas = encuestas.filter((singleReports) => {
                if ((singleReports.name.toLowerCase().match(search.toLowerCase()) &&
                    singleReports.timestamp >= fecha1 &&
                    singleReports.timestamp <= fecha2 + 86400
                ) ||
                    (singleReports.service.toLowerCase().match(search.toLowerCase()) &&
                        singleReports.timestamp >= fecha1 &&
                        singleReports.timestamp <= fecha2 + 86400
                    ) ||
                    (singleReports.phoneNumber.toLowerCase().match(search.toLowerCase()) &&
                        singleReports.timestamp >= fecha1 &&
                        singleReports.timestamp <= fecha2 + 86400
                    ) ||
                    (singleReports.agentName.toLowerCase().match(search.toLowerCase()) &&
                        singleReports.timestamp >= fecha1 &&
                        singleReports.timestamp <= fecha2 + 86400
                    ) ||
                    (singleReports.dni.toLowerCase().match(search.toLowerCase()) &&
                        singleReports.timestamp >= fecha1 &&
                        singleReports.timestamp <= fecha2 + 86400
                    ) ||
                    (singleReports.channel.toLowerCase().match(search.toLowerCase()) &&
                        singleReports.timestamp >= fecha1 &&
                        singleReports.timestamp <= fecha2 + 86400
                    )
                ) {
                    return singleReports;
                }
            });
        }
    }

    //FILTRO POR SEARCH BAR
    else if (search != "") {
        encuestas = encuestas.filter((singleReports) => {
            if (
                singleReports.name.toLowerCase().match(search.toLowerCase()) ||
                singleReports.service.toLowerCase().match(search.toLowerCase()) ||
                singleReports.dni.toLowerCase().match(search.toLowerCase()) ||
                singleReports.phoneNumber.match(search.toLowerCase()) ||
                singleReports.agentName.toLowerCase().match(search.toLowerCase()) ||
                singleReports.channel.toLowerCase().match(search.toLowerCase())
            ) {
                return singleReports;
            }
        });
    }

    //FILTRO POR FECHA
    else if (fecha1 != "" && fecha2 != "") {
        if ((fecha2 + 86400) - fecha1 >= (2629743 + 86400)) {
            window.alert("Las fechas a filtrar deben ser menor a un mes");
            reset(startDate, endDate, searchBar)
        } else {
            encuestas = encuestas.filter(
                (singleReport) => singleReport.timestamp >= fecha1 && singleReport.timestamp <= fecha2 + 86400);
        }
    }

    // encuestas.sort((a, b) => a.timestamp > b.timestamp)

    return (
        <>
            <div style={styles.backColor} />
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <h3 style={styles.titleNavbar}>{language === "english" ? "Answers" : "Respuestas"} 😃</h3>
                        <h3 style={styles.subtitleNavbar}>{language === "english" ? "All unified survey responses." : "Todos respuestas unificadas de encuestas."}</h3>
                    </div>
                </div>
                <div id="information"></div>
                <div style={styles.filters}>
                    <div style={styles.filterGroup}>
                        <label style={styles.filterLabel}>{language === "english" ? "Date filter" : "Filtro por fecha"}</label>
                        <div style={styles.filterDateGroup}>
                            <input className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.filterInputDate} type="date" id="startDate" />
                            <input className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.filterInput} type="date" id="endDate" />
                        </div>
                    </div>
                    <div style={styles.filterGroup}>
                        <label style={styles.filterLabel}>{language === "english" ? "Search filter" : "Filtro por búsqueda"}</label>
                        <input className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.filterInput} type="text" name="searchBar" id="searchBar" placeholder={language === "english" ? "Search..." : "Buscar..."} />
                    </div>
                    <button style={styles.filterButton} onClick={() => { handleClick(startDate, endDate, searchBar) }}>{language === "english" ? "Apply" : "Filtrar"}</button>
                    <button style={styles.resetButton} onClick={() => { reset(startDate, endDate, searchBar) }}>{language === "english" ? "Reset" : "Limpiar"}</button>
                </div>
                <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleReportReference}>
                    <div style={styles.date}>{language === "english" ? "Date" : "Fecha"}</div>
                    <div style={styles.phoneNumber}>{language === "english" ? "Phone Number" : "Número"}</div>
                    <div style={styles.date}>{language === "english" ? "DNI" : "DNI"}</div>
                    <div style={styles.option}>{language === "english" ? "Agent" : "Agente"}</div>
                    <div style={styles.date}>{language === "english" ? "Channel" : "Canal"}</div>
                    <div style={styles.option}>{language === "english" ? "Survey" : "Encuesta"}</div>
                    <div style={styles.date}>{language === "english" ? "Campaign" : "Campaña"}</div>
                </div>
                <div style={styles.reports}>
                    {popUpAnswersState === true ? (
                        <PopUpAnswers
                            screenSize={screenSize}
                            darkModeState={darkModeState}
                            language={language}
                            handlePopUpAnswers={handlePopUpAnswers}
                            popUpInformationAnswers={popUpInformationAnswers}
                        // handleAssign={handleAssign}
                        />
                    ) : (<></>)}
                    {encuestas.length ? (<>
                        {
                            //Se usa el metodo sort para ordenar por timestamp las interacciones
                            encuestas.sort((x, y) => { return y.timestamp - x.timestamp }).map((singleReport, index) => {
                                counterSurveys.push({ date: singleReport.date });
                                return (
                                    <button
                                        className={darkModeState ? "buttonDark" : "buttonLight"}
                                        style={singleReport.nestedAnswers[0] <= 2 || singleReport.nestedAnswers[1] == "No" || singleReport.nestedAnswers[2] <= 2 || singleReport.nestedAnswers[0] == "No" ? styles.singleBadReport : styles.singleReport}
                                        onClick={() => handlePopUpAnswers(singleReport)}
                                    >
                                        <div style={styles.dropDownMenu}>
                                            <div style={styles.date} >{singleReport.date} {singleReport.time}</div>
                                            <div style={styles.phoneNumber}>{singleReport.phoneNumber}</div>
                                            <div style={styles.date}>{singleReport.dni}</div>
                                            <div style={styles.option}>{singleReport.agentName}</div>
                                            <div style={styles.date}>{singleReport.channel == "audio" ? ("Audio") : ("WhatsApp")}</div>
                                            <div style={styles.option}>{singleReport.name}</div>
                                            <div style={styles.date}>{singleReport.service}</div>
                                        </div>
                                    </button>
                                );
                            })}
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleCounter}>
                            <div style={styles.titleCounter}>Total</div>
                            <div style={styles.numberCounter}>{counterSurveys.length}</div>
                        </div>
                    </>) : (<></>)}
                </div>
            </div>
        </>
    );
};
