import React, { useState } from "react";
import { ExportToCsv } from "export-to-csv";
import ReactExport from "react-export-excel";
import Select from 'react-select'

// Para exportar los archivos en Excel
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default ({
    screenSize,
    darkModeState,
    language,
    reports,
    dateToday,
    handleClick,
    opcionFilter,
    fecha1,
    fecha2,
    search,
    reset,
    onChange,
    arrayDeOpciones,
    oldReports,
    filterOn
}) => {
    const styles = {
        backColor: {
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            height: "100%",
            backgroundColor: `${darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"
                }`,
        },
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 800 ? ("3% 2% 3% 20%") : ("30px")}`,
            padding: `${screenSize > 800 ? "3% 2% 3% 280px" : "30px"}`,
            height: "100%",
            // overflow: `auto`,
        },
        containerLoader: {
            display: "flex",
            alignItem: "center",
            justifyContent: "center",
            height: "80%",
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 800 ? "0px 20px" : "0px"}`,
            marginBottom: `${screenSize > 800 ? "0px" : "30px"}`,
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
        },
        titleNavbar: {
            marginBottom: `${screenSize > 800 ? "5px" : "10px"}`,
            fontSize: "28px",
            color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
            fontFamily: "InterBold",
            lineHeight: "1.4",
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            fontFamily: "InterBold",
        },
        toogleNavbar: {
            display: "flex",
            flex: 4,
            alignItems: "center",
            justifyContent: `${screenSize > 800 ? "flex-end" : "center"}`,
            flexDirection: `${screenSize > 800 ? "row" : "column"}`,
        },
        textToogle: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: "inherit",
            border: "none",
            outline: "none",
            padding: "12px",
            backgroundColor: `transparent`,
        },
        textUser: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: "inherit",
            border: "none",
            outline: "none",
            padding: 0,
            backgroundColor: `transparent`,
        },
        buttonNew: {
            textDecoration: "none",
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: "none",
            margin: "20px",
            // margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
            width: `${screenSize > 800 ? "180px" : <></>}`,
        },
        total: {
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            margin: `${screenSize > 800 ? "10px 20px 10px" : "0px"}`,
        },
        filters: {
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "space-between",
            margin: `${screenSize > 800 ? "10px 20px 20px" : "0px"}`,
        },
        filterGroup: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            padding: '0px 20px 0px 0px'
        },
        filterDateGroup: {
            display: "flex",
            flexDirection: "row",
        },
        filterLabel: {
            fontFamily: "InterBold",
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            padding: '0px 5px'
        },
        filterInputDate: {
            border: 'none',
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            fontFamily: "InterBold",
            width: '100%',
            height: '40px',
            borderRadius: "5px",
            padding: '5px 10px',
            margin: '0px 5px 0px 0px'
        },
        filterInput: {
            border: 'none',
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            fontFamily: "InterBold",
            width: '100%',
            height: '40px',
            borderRadius: "5px",
            padding: '5px 10px'
        },
        filterButton: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textDecoration: "none",
            outline: 0,
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: 'none',
            borderRadius: 5,
            margin: 0,
            padding: '8px 13px',
            width: '100px'
        },
        resetButton: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textDecoration: "none",
            outline: 0,
            color: "#4dc2f1",
            backgroundColor: "transparent",
            border: '1px #4dc2f1 solid',
            borderRadius: 5,
            margin: "0px 0px 0px 10px",
            padding: '8px 13px',
            width: '100px'
        },
        singleReportReference: {
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            margin: `${screenSize > 800 ? "0px 20px" : "0px"}`,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "5px",
            padding: "0px 25px",
            textDecoration: "none",
            border: "none",
            outline: "none",
            // margin: `${screenSize > 800 ? "1.4% 0px -10px" : "10px"}`,
        },
        reports: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
            alignItems: "center",
            margin: `${screenSize > 800 ? "0px 20px" : "0px"}`,
            overflow: `auto`,

        },
        singleReport: {
            display: "flex",
            //   width: `${screenSize > 800 ? ("95%") : ("80vw")}`,
            width: `${screenSize > 800 ? "100%" : "80vw"}`,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "5px",
            padding: "10px 25px",
            textDecoration: "none",
            border: "none",
            outline: "none",
            margin: `${screenSize > 800 ? "1.4% 0px -10px" : "10px"}`,
        },
        lineDetail: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        date: {
            display: "flex",
            flex: 1,
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            margin: 0,
            fontSize: "12px",
            fontFamily: "InterBold",
            textAlign: 'left',
            alignSelf: "center",
            justifyContent: "flex-start",
            paddingRight: '15px'
        },
        phoneNumber: {
            display: "flex",
            flex: 2,
            color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
            margin: "0px",
            fontSize: "12px",
            fontFamily: "InterBold",
            textAlign: "left",
            alignSelf: "center",
            paddingRight: '15px'
        },
        option: {
            display: "flex",
            flex: 3,
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            margin: 0,
            fontSize: "12px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textAlign: "left",
            justifyContent: "flex-start",
            paddingRight: '15px'
        },
        information: {
            display: "flex",
            flex: 2,
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            margin: 0,
            fontSize: "12px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textAlign: "left",
            justifyContent: "flex-start",
            paddingRight: '15px'
        },
        time: {
            display: "flex",
            flex: 1,
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            margin: 0,
            fontSize: "12px",
            fontFamily: "InterBold",
            textAlign: 'left',
            alignSelf: "center",
            justifyContent: "flex-start",
            //   paddingRight: '0px'
        },
        singleCounter: {
            display: "flex",
            width: `${screenSize > 800 ? "100%" : "80vw"}`,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "5px",
            padding: "10px 25px",
            textDecoration: "none",
            border: "none",
            outline: "none",
            margin: `${screenSize > 800 ? "2.4% 0px -10px" : "10px"}`,
            backgroundColor: '#4dc2f1',
            color: '#fff'
        },
        titleCounter: {
            display: "flex",
            flex: 4,
            color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
            margin: "0px",
            fontSize: "18px",
            fontFamily: "InterBold",
            textAlign: "start",
            alignSelf: "center",
            color: '#fff'
        },
        numberCounter: {
            display: "flex",
            flex: 2,
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            margin: 0,
            fontSize: "14px",
            fontFamily: "InterBold",
            textAlign: 'end',
            alignSelf: "center",
            justifyContent: "flex-end",
            color: '#fff'
        },
        downloadsContainer: {
            display: "flex",
            width: `${screenSize > 800 ? "100%" : "80vw"}`,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            borderRadius: "5px",
            padding: "10px 0px",
            textDecoration: "none",
            border: "none",
            outline: "none",
            margin: `${screenSize > 800 ? "1.4% 0px 0px" : "10px"}`,
            backgroundColor: 'transparent',
        },
        downloadsButton: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textDecoration: "none",
            outline: 0,
            color: "#4dc2f1",
            backgroundColor: "transparent",
            border: '1px #4dc2f1 solid',
            borderRadius: 5,
            margin: "0px 0px 0px 10px",
            padding: '8px 13px',
            width: '180px'
        },
        loader: {
            display: "flex",
            alignSelf: "center",
        },
    };
    const opcionesArrayFiltrar = [
        { value: 'Ha iniciado la conversación', label: 'Ha iniciado la conversación' },
        { value: "Quiere proteger su casa", label: 'A - Quiere proteger su casa' },
        { value: "Quiere proteger su comercio", label: "B - Quiere proteger su comercio" },
        { value: "Quiere proteger su auto", label: "C - Quiere proteger su auto" },
        { value: "Quiere proteger su moto", label: "D - Quiere proteger su moto" },
        { value: "Otros motivos", label: "E - Otros motivos" },
        { value: "Ha cargado todos los datos", label: "Ha cargado todos los datos" },
        { value: "Fue derivado a un agente", label: "Fue derivado a un agente" },
        { value: "Se contactó fuera de horario", label: "Se contactó fuera de horario" },
        { value: "Abandono la interaccion", label: "Abandonó la interacción" },
        { value: "Abandono la interaccion por limite de tiempo", label: "Abandonó la interacción por límite de tiempo" },
        { value: "Ha ingresado una opción incorrecta", label: "Ha ingresado una opción incorrecta" }
    ];
    //array que guardara los datos a exportar y muestra la longitud de los datos mostrados en pantalla
    let csvFile = [];
    let filtrados = "";

    //opciones para la descarga de cvs

    let titleCSV = `OneReport-${dateToday}`

    const options = {
        fieldSeparator: ',',
        filename: titleCSV,
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: false,
        useBom: true,
        useTextFile: false,
        useKeysAsHeaders: false,
        headers: ["Fecha", "Número", "Nombre", "Apellido", "Opción", "Localidad", "Provincia", "Tiempo"]
    };

    if (filterOn == true) {
        reports = oldReports
    }
    //captura los valores de los filtros
    // let elemento = document.getElementById("opciones");
    let startDate = document.getElementById("startDate");
    let endDate = document.getElementById("endDate");
    let searchBar = document.getElementById("searchBar");
    // resetea los valores de los filtros

    //funcion para descargar en formato cvs
    let downloadCvs = () => {
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(csvFile);
    };


    //si los reportes tienes campos sin definir lo llena con un string vacio ""
    reports.forEach((ele) => {
        for (let property in ele) {
            if (ele[property] == undefined) {
                ele[property] = ""
            }
        }
    })

    // ACA SEA CREAN LAS VARIABLES FILTRADAS 
    //los datos indefinidos les asigna el valor "" y convierte todos los datos en minuscula


    if (opcionFilter != "" && fecha1 != "" && search != "") {
        if (fecha2 - fecha1 >= 2629743000 + 86400000) {

            window.alert("El tiempo a filtrar debe ser menor a un mes");
            reset(startDate, endDate, searchBar, arrayDeOpciones)
        } else {
            filtrados = reports.filter((singleReport) => {
                if (
                    (opcionFilter.includes(singleReport.option) &&
                        singleReport.timestamp >= fecha1 &&
                        singleReport.timestamp <= fecha2 + 86400 * 1000 &&
                        singleReport.phoneNumber.match(search)) ||
                    (opcionFilter.includes(singleReport.option) &&
                        singleReport.timestamp >= fecha1 &&
                        singleReport.timestamp <= fecha2 + 86400 * 1000 &&
                        singleReport.nombre.toLowerCase().match(search.toLowerCase())) ||
                    (opcionFilter.includes(singleReport.option) &&
                        singleReport.timestamp >= fecha1 &&
                        singleReport.timestamp <= fecha2 + 86400 * 1000 &&
                        singleReport.apellido.toLowerCase().match(search.toLowerCase())) ||
                    (opcionFilter.includes(singleReport.option) &&
                        singleReport.timestamp >= fecha1 &&
                        singleReport.timestamp <= fecha2 + 86400 * 1000 &&
                        singleReport.localidad.toLowerCase().match(search.toLowerCase())) ||
                    (opcionFilter.includes(singleReport.option) &&
                        singleReport.timestamp >= fecha1 &&
                        singleReport.timestamp <= fecha2 + 86400 * 1000 &&
                        singleReport.provincia.toLowerCase().match(search.toLowerCase()))
                ) {
                    return singleReport;
                }
            })
        }
        ;
    }
    else if (fecha1 != "" && fecha2 != "" && opcionFilter != "") {

        if (fecha2 - fecha1 >= (2629743000 + 86400000)) {
            window.alert("El tiempo a filtrar debe ser menor a un mes");
            reset(startDate, endDate, searchBar, arrayDeOpciones)
        } else {

            filtrados = reports.filter((singleReport) => {
                if (
                    (singleReport.nombre.toLowerCase().match(search.toLowerCase()) && singleReport.timestamp >= fecha1 && singleReport.timestamp <= fecha2 + 86400000) ||
                    (singleReport.phoneNumber.match(search) && singleReport.timestamp >= fecha1 && singleReport.timestamp <= fecha2 + 86400 * 1000) ||
                    (singleReport.apellido.toLowerCase().match(search.toLowerCase()) && singleReport.timestamp >= fecha1 && singleReport.timestamp <= fecha2 + 86400 * 1000) ||
                    (singleReport.localidad.toLowerCase().match(search.toLowerCase()) && singleReport.timestamp >= fecha1 && singleReport.timestamp <= fecha2 + 86400 * 1000) ||
                    (singleReport.provincia.toLowerCase().match(search.toLowerCase()) && singleReport.timestamp >= fecha1 && singleReport.timestamp <= fecha2 + 86400 * 1000)
                ) {
                    return singleReport;
                }
            })
        }



        filtrados = reports.filter((singleReport) => {
            if (
                opcionFilter.includes(singleReport.option) &&
                singleReport.timestamp >= fecha1 &&
                singleReport.timestamp <= fecha2 + 86400 * 1000
            ) {
                return singleReport;
            }
        });
    }
    else if (fecha1 != "" && fecha2 != "" && search != "") {

        if (fecha2 - fecha1 >= (2629743000 + 86400000)) {
            window.alert("El tiempo a filtrar debe ser menor a un mes");
            reset(startDate, endDate, searchBar, arrayDeOpciones)
        } else {

            filtrados = reports.filter((singleReport) => {
                if (
                    (singleReport.nombre.toLowerCase().match(search.toLowerCase()) && singleReport.timestamp >= fecha1 && singleReport.timestamp <= fecha2 + 86400000) ||
                    (singleReport.phoneNumber.match(search) && singleReport.timestamp >= fecha1 && singleReport.timestamp <= fecha2 + 86400 * 1000) ||
                    (singleReport.apellido.toLowerCase().match(search.toLowerCase()) && singleReport.timestamp >= fecha1 && singleReport.timestamp <= fecha2 + 86400 * 1000) ||
                    (singleReport.localidad.toLowerCase().match(search.toLowerCase()) && singleReport.timestamp >= fecha1 && singleReport.timestamp <= fecha2 + 86400 * 1000) ||
                    (singleReport.provincia.toLowerCase().match(search.toLowerCase()) && singleReport.timestamp >= fecha1 && singleReport.timestamp <= fecha2 + 86400 * 1000)
                ) {
                    return singleReport;
                }
            })

        }


        ;
    }
    else if (search != "" && opcionFilter != "") {
        filtrados = reports.filter((singleReport) => {
            if (
                (opcionFilter.includes(singleReport.option) && singleReport.phoneNumber.match(search)) ||
                (opcionFilter.includes(singleReport.option) && singleReport.nombre.toLowerCase().match(search.toLowerCase())) ||
                (opcionFilter.includes(singleReport.option) && singleReport.apellido.toLowerCase().match(search.toLowerCase())) ||
                (opcionFilter.includes(singleReport.option) && singleReport.provincia.toLowerCase().match(search.toLowerCase())) ||
                (opcionFilter.includes(singleReport.option) && singleReport.localidad.toLowerCase().match(search.toLowerCase()))
            ) {
                return singleReport;
            }
        });
    }
    else if (opcionFilter != "") {
        filtrados = reports.filter(
            ((singleReport) => {
                if (opcionFilter.includes(singleReport.option)) {
                    return singleReport;
                }
            }
            )
        );
    }

    else if (fecha1 != "" && fecha2 != "") {

        if (fecha2 - fecha1 >= (2629743000 + 86400000)) {
            window.alert("El tiempo a filtrar debe ser menor a un mes");
            reset(startDate, endDate, searchBar, arrayDeOpciones)
        } else {
            filtrados = reports.filter(
                (singleReport) =>
                    singleReport.timestamp >= fecha1 &&
                    singleReport.timestamp <= fecha2 + 86400000
            );
        }
    }

    else if (search != "") {
        filtrados = reports.filter((singleReports) => {
            if (
                singleReports.nombre.toLowerCase().match(search.toLowerCase()) ||
                singleReports.apellido.match(search.toLowerCase()) ||
                singleReports.localidad.match(search.toLowerCase()) ||
                singleReports.provincia.match(search.toLowerCase()) ||
                singleReports.phoneNumber.match(search)
            ) {
                return singleReports;
            }
        });
    }
    else if (opcionFilter == "" && fecha1 == "" && search == "") {
        filtrados = reports
    }
    return (
        <>
            <div style={styles.backColor} />
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <h3 style={styles.titleNavbar}>{language === "english" ? "Reports" : "Reportes"} 😃</h3>
                        <h3 style={styles.subtitleNavbar}>{language === "english" ? "All interaction reports." : "Todos los reportes de interacciones."}</h3>
                    </div>
                </div>
                <div style={styles.filters}>
                    <div style={styles.filterGroup}>
                        <label style={styles.filterLabel}>{language === "english" ? "Date filter" : "Filtro por fecha"}</label>
                        <div style={styles.filterDateGroup}>
                            <input className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.filterInputDate} type="date" id="startDate" />
                            <input className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.filterInput} type="date" id="endDate" />
                        </div>
                    </div>
                    <div style={styles.filterGroup}>

                        <label style={styles.filterLabel}>{language === "english" ? "Option filter" : "Filtro por opción"}</label>

                        <Select name="opciones" id="opciones" isMulti options={opcionesArrayFiltrar} onChange={(valor) => { onChange(valor) }} className="basic-multi-select" classNamePrefix="select" />
                    </div>
                    <div style={styles.filterGroup}>
                        <label style={styles.filterLabel}>{language === "english" ? "Search filter" : "Filtro por búsqueda"}</label>
                        <input className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.filterInput} type="text" name="searchBar" id="searchBar" placeholder={language === "english" ? "Search..." : "Buscar..."} />
                    </div>
                    <button style={styles.filterButton} onClick={() => { handleClick(arrayDeOpciones, startDate, endDate, searchBar) }}>{language === "english" ? "Apply" : "Filtrar"}</button>
                    <button style={styles.resetButton} onClick={() => { reset(startDate, endDate, searchBar, arrayDeOpciones) }}>{language === "english" ? "Reset" : "Limpiar"}</button>
                </div>
                <div
                    className={darkModeState ? "buttonDark" : "buttonLight"}
                    style={styles.singleReportReference}>

                    <div style={styles.date}>{language === "english" ? "Date" : "Fecha"}</div>
                    <div style={styles.phoneNumber}>{language === "english" ? "Phone Number" : "Número"}</div>
                    <div style={styles.information}>{language === "english" ? "Full Name" : "Nombre"}</div>
                    <div style={styles.option}>{language === "english" ? "Option" : "Opción"}</div>
                    <div style={styles.information}>{language === "english" ? "Location" : "Localidad"}</div>
                    <div style={styles.information}>{language === "english" ? "Province" : "Provincia"}</div>
                    <div style={styles.time}>{language === "english" ? "Time" : "Tiempo"}</div>
                </div>

                <div style={styles.reports}>
                    {
                        //Dependiendo de los filtros aplicados se muestra información chequea los valores aplicados los 3 filtros
                        //FILTRO POR OPCION FECHA Y BUSQUEDA
                        filtrados != [] ? (<>
                            {
                                filtrados.map((singleReport, index) => {
                                    //los usuarios encontrados se empujan a una variable, asi se muestra en pantalla todos los usuarios encontrados
                                    // y facilita su descarga en tanto en excel como en csv
                                    csvFile.push({
                                        date: singleReport.date,
                                        phoneNumber: singleReport.phoneNumber,
                                        nombre: singleReport.nombre,
                                        apellido: singleReport.apellido,
                                        option: singleReport.optionOriginal,
                                        localidad: singleReport.localidad,
                                        provincia: singleReport.provincia,
                                        tiempo: singleReport.timebetween,
                                    });
                                    //verifica si el campo tiene mas de una letra, si es asi eleva la primera letra a mayuscula, lo mismo el apellido.
                                    if (singleReport.nombre[0]) { singleReport.nombre = singleReport.nombre[0].toUpperCase() + singleReport.nombre.slice(1, singleReport.nombre.length) }
                                    if (singleReport.apellido[0]) { singleReport.apellido = singleReport.apellido[0].toUpperCase() + singleReport.apellido.slice(1, singleReport.apellido.length) }
                                    return (
                                        <div
                                            className={darkModeState ? "buttonDark" : "buttonLight"}
                                            style={styles.singleReport}>

                                            <div style={styles.date}>{singleReport.date}</div>
                                            <div style={styles.phoneNumber}>{singleReport.phoneNumber}</div>
                                            {/* <div style={styles.phoneNumber}>XXXXXXXXXXXX</div> */}
                                            <div style={styles.information}>{singleReport.nombre && singleReport.nombre != "" ? `${singleReport.nombre} ${singleReport.apellido}` : ""}</div>
                                            {/* <div style={styles.information}>{singleReport.nombre && singleReport.nombre != "" ? `Juan Rodriguez` : ""}</div> */}
                                            <div style={styles.option}>{singleReport.option == "Abandono la interaccion" ? `Abandonó la interacción (${singleReport.comment})` : singleReport.option == "Abandono la interaccion por limite de tiempo" ? `Abandonó la interacción por límite de tiempo (${singleReport.comment})` : singleReport.option == "Ha ingresado una opción incorrecta" ? singleReport.optionOriginal : singleReport.option}</div>
                                            <div style={styles.information}>{singleReport.localidad}</div>
                                            <div style={styles.information}>{singleReport.provincia}</div>
                                            <div style={styles.time}>{singleReport.timebetween}</div>
                                        </div>
                                    );
                                })}
                            <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleCounter}>
                                <div style={styles.titleCounter}>Total filtrados</div>
                                <div style={styles.numberCounter}>{csvFile.length}</div>
                            </div>

                            <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.downloadsContainer}>
                                <ExcelFile element={<button style={styles.downloadsButton}>{language === "english" ? "Download Excel" : "Descargar Excel"}</button>} filename={`OneReport - ${dateToday}`}>
                                    <ExcelSheet data={csvFile} name={`Reporte - ${dateToday}`}>
                                        <ExcelColumn label="Fecha" value="date" />
                                        <ExcelColumn label="Número" value="phoneNumber" />
                                        <ExcelColumn label="Nombre" value="nombre" />
                                        <ExcelColumn label="Apellido" value="apellido" />
                                        <ExcelColumn label="Opción" value="option" />
                                        <ExcelColumn label="Localidad" value="localidad" />
                                        <ExcelColumn label="Provincia" value="provincia" />
                                        <ExcelColumn label="Tiempo" value="tiempo" />
                                    </ExcelSheet>
                                </ExcelFile>
                                <button style={styles.downloadsButton} onClick={downloadCvs}>{language === "english" ? "Download CSV" : "Descargar CSV"}</button>
                            </div>
                        </>) : (<>
                            <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleCounter}>
                                <div style={styles.titleCounter}>Total filtrados</div>
                                <div style={styles.numberCounter}>{csvFile.length}</div>
                            </div>
                            <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.downloadsContainer}>
                                <ExcelFile element={<button style={styles.downloadsButton}>{language === "english" ? "Download Excel" : "Descargar Excel"}</button>} filename={`OneReport - ${dateToday}`}>
                                    <ExcelSheet data={csvFile} name={`Reporte - ${dateToday}`}>
                                        <ExcelColumn label="Fecha" value="date" />
                                        <ExcelColumn label="Número" value="phoneNumber" />
                                        <ExcelColumn label="Nombre" value="nombre" />
                                        <ExcelColumn label="Apellido" value="apellido" />
                                        <ExcelColumn label="Opción" value="option" />
                                        <ExcelColumn label="Localidad" value="localidad" />
                                        <ExcelColumn label="Provincia" value="provincia" />
                                        <ExcelColumn label="Tiempo" value="tiempo" />
                                    </ExcelSheet>
                                </ExcelFile>
                                <button style={styles.downloadsButton} onClick={downloadCvs}>{language === "english" ? "Download CSV" : "Descargar CSV"}</button>
                            </div>
                        </>)
                    }
                </div>
            </div>
        </>
    );
};
