import React from "react";
import PopUpRecover from "./PopUpRecover";

export default ({ screenSize, darkModeState, language, loginUser, popUp, handlePopUp, handleRecover, handleRecoverMail, recoverMessage }) => {
    const styles = {
        backColor: {
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            height: "100%",
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`
        },
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 800 ? ("3% 2% 3% 20%") : ("30px")}`,
            padding: `${screenSize > 800 ? ("3% 2% 3% 280px") : ("30px")}`,
            height: "100%",
            overflow: `auto`,
        },
        containerLoader: {
            display: "flex",
            alignItem: 'center',
            justifyContent: 'center',
            height: "80%",
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px")}`,
            marginBottom: `${screenSize > 800 ? ("0px") : ("30px")}`,
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
        },
        titleNavbar: {
            marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
        },
        toogleNavbar: {
            display: "flex",
            flex: 4,
            alignItems: "center",
            justifyContent: `${screenSize > 800 ? ("flex-end") : ("center")}`,
            flexDirection: `${screenSize > 800 ? ("row") : ("column")}`,
        },
        textToogle: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: 'inherit',
            border: 'none',
            outline: 'none',
            padding: '12px',
            backgroundColor: `transparent`
        },
        textUser: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: 'inherit',
            border: 'none',
            outline: 'none',
            padding: 0,
            backgroundColor: `transparent`
        },
        buttonNew: {
            textDecoration: "none",
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: 'none',
            margin: "20px",
            // margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
            width: `${screenSize > 800 ? ('180px') : (<></>)}`,
        },
        user: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
            alignItems: 'flex-start',
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px")}`,
        },
        singleLine: {
            display: "flex",
            width: `${screenSize > 800 ? ("100%") : ("80vw")}`,
            flexDirection: "row",
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: "25px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 800 ? ('1.4% 0px -10px') : ("10px")}`,
        },
        singleLineMargin: {
            display: "flex",
            width: `${screenSize > 800 ? ("100%") : ("80vw")}`,
            flexDirection: "row",
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: "25px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 800 ? ('40px 0px') : ("10px")}`,
        },
        type: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: 0,
            fontSize: "18px",
            fontFamily: "InterBold",
            alignSelf: 'center',
        },
        value: {
            display: "flex",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "18px",
            fontFamily: "InterBold",
            alignSelf: 'center',
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            backgroundColor: `transparent`
        },
        loader: {
            display: 'flex',
            alignSelf: 'center'
        },
        titleActivity: {
            display: "flex",
            alignItems: 'center',
            fontSize: "24px",
            fontFamily: "InterBold",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(228, 12%, 44%)")}`,
            margin: `${screenSize > 800 ? ("20px 0px") : ("0px")}`,
        },
    };

    return (
        <>
            <div style={styles.backColor} />
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <h3 style={styles.titleNavbar}>{language === 'english' ? ('My Profile') : ('Mi Perfil')} 😃</h3>
                        <h3 style={styles.subtitleNavbar}>{language === 'english' ? ('All your profile.') : ('Todo tu perfil y configuraciones.')}</h3>
                    </div>
                </div>
                <div style={styles.user}>
                    {popUp === true ? (
                        <PopUpRecover
                            screenSize={screenSize}
                            darkModeState={darkModeState}
                            language={language}
                            handlePopUp={handlePopUp}
                            handleRecover={handleRecover}
                            handleRecoverMail={handleRecoverMail}
                            recoverMessage={recoverMessage}
                        />
                    ) : (<></>)}
                    {loginUser ? (<>
                        <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleLine}>
                            <div style={styles.type}>{language === 'english' ? ('Name') : ('Nombre')}</div>
                            <div style={styles.value}>{loginUser.firstName} {loginUser.lastName}</div>
                        </div>
                        <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleLine}>
                            <div style={styles.type}>Rol</div>
                            <div style={styles.value}>{loginUser.rol}</div>
                        </div>
                        <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleLine}>
                            <div style={styles.type}>{language === 'english' ? ('Mail') : ('Correo electrónico')}</div>
                            <div style={styles.value}>{loginUser.mail}</div>
                        </div>
                        {/* <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleLineMargin}>
                            <div style={styles.type}>{language === 'english' ? ('Password') : ('Contraseña')}</div>
                            <button style={styles.value} onClick={handlePopUp}>{language === 'english' ? ('Change password') : ('Cambiar contraseña')}</button>
                        </div> */}
                        {/* <h3 style={styles.titleActivity}>{language === 'english' ? ('Recent activity') : ('Actividad reciente')}</h3>
                        <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleLine}>
                            <div style={styles.type}>{language === 'english' ? ('Name') : ('Nombre')}</div>
                            <div style={styles.value}>{loginUser.firstName} {loginUser.lastName}</div>
                        </div>
                        <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleLine}>
                            <div style={styles.type}>{language === 'english' ? ('Name') : ('Nombre')}</div>
                            <div style={styles.value}>{loginUser.firstName} {loginUser.lastName}</div>
                        </div>
                        <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleLine}>
                            <div style={styles.type}>{language === 'english' ? ('Name') : ('Nombre')}</div>
                            <div style={styles.value}>{loginUser.firstName} {loginUser.lastName}</div>
                        </div>
                        <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleLine}>
                            <div style={styles.type}>{language === 'english' ? ('Name') : ('Nombre')}</div>
                            <div style={styles.value}>{loginUser.firstName} {loginUser.lastName}</div>
                        </div> */}
                    </>) : (<></>)}
                </div>
            </div>
        </>
    );
};
