import React from "react";
import { Link } from "react-router-dom";

export default ({ handleLogout, handleProfile, loginUser, darkModeState, language, companyUser }) => {
  const styles = {
    sidebar: {
      width: '250px',
      position: 'fixed',
      top: 0,
      left: 0,
      height: '100vh',
      zIndex: 800,
      backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`,
      //  boxShadow: '0 3px 16px 0 rgba(50, 50, 255, 0.2)',
      //  boxShadow: '0 3px 16px 0 hsl(228, 28%, 1%)',
      boxShadow: `${darkModeState ? ("0 3px 16px 0 hsl(228, 28%, 11%)") : ("0 3px 16px 0 rgba(90, 90, 90, 0.2)")}`,
      opacity: 1,
      color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
      // fontSize: 15
      //  transition: all 0.3s;
    },
    logoContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "36px 30px 20px",
      backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`,
    },
    linkContainer: {
      display: "flex",
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '80%'
    },
    link: {
      padding: 20,
      paddingLeft: 50,
      display: "flex",
      alignItems: "center",
    },
    linkButton: {
      padding: "20px 40px 20px 50px",
      display: "flex",
      alignItems: "center",
      backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`,
      color: '#63687e',
      border: 'none',
      outline: 0,
      width: '100%'
    },
    icons: {
      marginRight: "15px",
      width: '18px',
      height: '20px',
      color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
    },
    option: {
      color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
      fontSize: '16.5px',
      margin: '0px',
      textAlign: "left"
    }
  };

  return (
    <div className="wrapper">
      <nav
        //id="sidebar" 
        style={styles.sidebar}>
        <div style={styles.logoContainer}>
          <img
            // src="https://i.postimg.cc/8zfzzyj3/Logo-full-color.png"
            src="https://i.postimg.cc/bN6SJ6fx/logo-insideone.png"
            height="55"
            alt="logo of Inside One"
          />
        </div>
        <hr />
        <div style={styles.linkContainer}>
          <ul className="list-unstyled" >


            {/* REPOTES INSIDEONE  COLLAB */}
            {loginUser.company == '47o0el3cqrHMAg71Yk9f' ? (
              <li>
                <Link className="nav-link active" style={styles.link} to="/licenses">
                  {/* <Link className="nav-link active" style={styles.link} to="/charts/bbva-seguros"> */}
                  <svg style={styles.icons} fill="currentColor" class="bi bi-pie-chart" viewBox="0 0 16 16">
                    <path d="M7.5 1.018a7 7 0 0 0-4.79 11.566L7.5 7.793V1.018zm1 0V7.5h6.482A7.001 7.001 0 0 0 8.5 1.018zM14.982 8.5H8.207l-4.79 4.79A7 7 0 0 0 14.982 8.5zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z" />
                  </svg>
                  {language === 'english' ? ('Licenses') : ('Licencias')}
                </Link>
              </li>
            ) : (<></>)}


            {/* REPORTES INSIDEONE  */}
            {companyUser.charts && loginUser.company == '8iJ3nj8J4B3H9vECq67J' ? (
              <li>
                <Link className="nav-link active" style={styles.link} to="/charts">
                  {/* <Link className="nav-link active" style={styles.link} to="/charts/bbva-seguros"> */}
                  <svg style={styles.icons} fill="currentColor" class="bi bi-pie-chart" viewBox="0 0 16 16">
                    <path d="M7.5 1.018a7 7 0 0 0-4.79 11.566L7.5 7.793V1.018zm1 0V7.5h6.482A7.001 7.001 0 0 0 8.5 1.018zM14.982 8.5H8.207l-4.79 4.79A7 7 0 0 0 14.982 8.5zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z" />
                  </svg>
                  {language === 'english' ? ('Charts') : ('Métricas')}
                </Link>
              </li>
            ) : (<></>)}
            {companyUser.reports && loginUser.company == '8iJ3nj8J4B3H9vECq67J' ? (
              <li>
                <Link className="nav-link active" style={styles.link} to="/reports">
                  <svg style={styles.icons} fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                  </svg>
                  {language === 'english' ? ('Reports') : ('Reportes')}
                </Link>
              </li>
            ) : (<></>)}



            {/* REPOTES BBVA  */}
            {companyUser.charts && loginUser.company != 'VF0rMotbgtFU3FapbUo6' && loginUser.company != 'HZZb5MxJI1zVkQJ8MJwk' && loginUser.company == 'd4c9s7pwy2UwYPg2OMfr' ? (
              <li>
                <Link className="nav-link active" style={styles.link} to="/charts/bbva-seguros/whatsapp">
                  {/* <Link className="nav-link active" style={styles.link} to="/charts/bbva-seguros"> */}
                  <svg style={styles.icons} fill="currentColor" class="bi bi-pie-chart" viewBox="0 0 16 16">
                    <path d="M7.5 1.018a7 7 0 0 0-4.79 11.566L7.5 7.793V1.018zm1 0V7.5h6.482A7.001 7.001 0 0 0 8.5 1.018zM14.982 8.5H8.207l-4.79 4.79A7 7 0 0 0 14.982 8.5zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z" />
                  </svg>
                  {language === 'english' ? ('Charts') : ('Métricas')}
                </Link>
              </li>
            ) : (<></>)}
            {companyUser.reports && loginUser.company != 'VF0rMotbgtFU3FapbUo6' && loginUser.company != 'HZZb5MxJI1zVkQJ8MJwk' && loginUser.company == 'd4c9s7pwy2UwYPg2OMfr' ? (
              <li>
                <Link className="nav-link active" style={styles.link} to="/reports/bbva-seguros">
                  <svg style={styles.icons} fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                  </svg>
                  {language === 'english' ? ('Reports') : ('Reportes')}
                </Link>
              </li>
            ) : (<></>)}


            {/* ENCUESTAS BBVA  */}
            {companyUser.surveys && loginUser.company == 'd4c9s7pwy2UwYPg2OMfr' ? (
              <>
                {/* <li>
                  <Link className="nav-link active" style={styles.link} to="/surveys">
                    <svg style={styles.icons} fill="currentColor" class="bi bi-card-list" viewBox="0 0 16 16">
                      <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                      <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                    </svg>
                    {language === 'english' ? ('Surveys') : ('Encuestas')}
                  </Link>
                </li> */}
                {/* <li>
                  <Link className="nav-link active" style={styles.link} to="/surveys/bbva-seguros">
                    <svg style={styles.icons} fill="currentColor" class="bi bi-clipboard-data" viewBox="0 0 16 16">
                      <path d="M4 11a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0v-1zm6-4a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0V7zM7 9a1 1 0 0 1 2 0v3a1 1 0 1 1-2 0V9z" />
                      <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                      <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                    </svg>
                    {language === 'english' ? ('Surveys') : ('Encuestas')}
                  </Link>
                </li> */}
                <li>
                  <Link className="nav-link active" style={styles.link} to="/analytics">
                    <svg style={styles.icons} fill="currentColor" class="bi bi-clipboard-data" viewBox="0 0 16 16">
                      <path d="M4 11a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0v-1zm6-4a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0V7zM7 9a1 1 0 0 1 2 0v3a1 1 0 1 1-2 0V9z" />
                      <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                      <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                    </svg>
                    {language === 'english' ? ('Surveys') : ('Encuestas')}
                  </Link>
                </li>
                <li>
                  <Link className="nav-link active" style={styles.link} to="/unified-answers">
                    <svg style={styles.icons} fill="currentColor" class="bi bi-clipboard2-pulse" viewBox="0 0 16 16">
                      <path d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5h3Z" />
                      <path d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-12Z" />
                      <path d="M9.979 5.356a.5.5 0 0 0-.968.04L7.92 10.49l-.94-3.135a.5.5 0 0 0-.926-.08L4.69 10H4.5a.5.5 0 0 0 0 1H5a.5.5 0 0 0 .447-.276l.936-1.873 1.138 3.793a.5.5 0 0 0 .968-.04L9.58 7.51l.94 3.135A.5.5 0 0 0 11 11h.5a.5.5 0 0 0 0-1h-.128L9.979 5.356Z" />
                    </svg>
                    {language === 'english' ? ('Unified Answers') : ('Respuestas agrupadas')}
                  </Link>
                </li>
                <li>
                  <Link className="nav-link active" style={styles.link} to="/answers">
                    <svg style={styles.icons} fill="currentColor" class="bi bi-clipboard2-pulse" viewBox="0 0 16 16">
                      <path d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5h3Z" />
                      <path d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-12Z" />
                      <path d="M9.979 5.356a.5.5 0 0 0-.968.04L7.92 10.49l-.94-3.135a.5.5 0 0 0-.926-.08L4.69 10H4.5a.5.5 0 0 0 0 1H5a.5.5 0 0 0 .447-.276l.936-1.873 1.138 3.793a.5.5 0 0 0 .968-.04L9.58 7.51l.94 3.135A.5.5 0 0 0 11 11h.5a.5.5 0 0 0 0-1h-.128L9.979 5.356Z" />
                    </svg>
                    {language === 'english' ? ('Answers') : ('Respuestas')}
                  </Link>
                </li>
              </>
            ) : (<></>)}

            {/* ENCUESTAS GENERALES  */}
            {companyUser.surveys && loginUser.company == '0XCHlrimdm5mi0Hmg3yU' ? (
              <>
                <li>
                  <Link className="nav-link active" style={styles.link} to="/analytics">
                    <svg style={styles.icons} fill="currentColor" class="bi bi-clipboard-data" viewBox="0 0 16 16">
                      <path d="M4 11a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0v-1zm6-4a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0V7zM7 9a1 1 0 0 1 2 0v3a1 1 0 1 1-2 0V9z" />
                      <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                      <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                    </svg>
                    {language === 'english' ? ('Charts') : ('Métricas')}
                  </Link>
                </li>
                <li>
                  <Link className="nav-link active" style={styles.link} to="/unified-answers">
                    <svg style={styles.icons} fill="currentColor" class="bi bi-clipboard2-pulse" viewBox="0 0 16 16">
                      <path d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5h3Z" />
                      <path d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-12Z" />
                      <path d="M9.979 5.356a.5.5 0 0 0-.968.04L7.92 10.49l-.94-3.135a.5.5 0 0 0-.926-.08L4.69 10H4.5a.5.5 0 0 0 0 1H5a.5.5 0 0 0 .447-.276l.936-1.873 1.138 3.793a.5.5 0 0 0 .968-.04L9.58 7.51l.94 3.135A.5.5 0 0 0 11 11h.5a.5.5 0 0 0 0-1h-.128L9.979 5.356Z" />
                    </svg>
                    {language === 'english' ? ('Unified Answers') : ('Respuestas agrupadas')}
                  </Link>
                </li>
                <li>
                  <Link className="nav-link active" style={styles.link} to="/answers">
                    <svg style={styles.icons} fill="currentColor" class="bi bi-clipboard2-pulse" viewBox="0 0 16 16">
                      <path d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5h3Z" />
                      <path d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-12Z" />
                      <path d="M9.979 5.356a.5.5 0 0 0-.968.04L7.92 10.49l-.94-3.135a.5.5 0 0 0-.926-.08L4.69 10H4.5a.5.5 0 0 0 0 1H5a.5.5 0 0 0 .447-.276l.936-1.873 1.138 3.793a.5.5 0 0 0 .968-.04L9.58 7.51l.94 3.135A.5.5 0 0 0 11 11h.5a.5.5 0 0 0 0-1h-.128L9.979 5.356Z" />
                    </svg>
                    {language === 'english' ? ('Answers') : ('Respuestas')}
                  </Link>
                </li>
              </>
            ) : (<></>)}


            {/* REPOTES X28  */}
            {(loginUser.rol === 'Developer' && loginUser.company == 'VF0rMotbgtFU3FapbUo6') || (loginUser.rol === 'Supervisor' && loginUser.company == 'VF0rMotbgtFU3FapbUo6') ? (
              <>
                <li>
                  <Link className="nav-link active" style={styles.link} to="/charts/x28">
                    <svg style={styles.icons} fill="currentColor" class="bi bi-pie-chart" viewBox="0 0 16 16">
                      <path d="M7.5 1.018a7 7 0 0 0-4.79 11.566L7.5 7.793V1.018zm1 0V7.5h6.482A7.001 7.001 0 0 0 8.5 1.018zM14.982 8.5H8.207l-4.79 4.79A7 7 0 0 0 14.982 8.5zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z" />
                    </svg>
                    {language === 'english' ? ('Charts') : ('Métricas')}
                  </Link>
                </li>
                <li>
                  <Link className="nav-link active" style={styles.link} to="/reports/x28">
                    <svg style={styles.icons} fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                      <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                      <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                    </svg>
                    {language === 'english' ? ('Reports') : ('Reportes')}
                  </Link>
                </li>
              </>
            ) : (<></>)}


            {/* ENCUESTAS GALICIA SEGUROS  */}
            {loginUser.company == 'HZZb5MxJI1zVkQJ8MJwk' ? (
              <>
                <li>
                  <Link className="nav-link active" style={styles.link} to="/analytics">
                    <svg style={styles.icons} fill="currentColor" class="bi bi-pie-chart" viewBox="0 0 16 16">
                      <path d="M7.5 1.018a7 7 0 0 0-4.79 11.566L7.5 7.793V1.018zm1 0V7.5h6.482A7.001 7.001 0 0 0 8.5 1.018zM14.982 8.5H8.207l-4.79 4.79A7 7 0 0 0 14.982 8.5zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z" />
                    </svg>
                    {language === 'english' ? ('Charts') : ('Métricas')}
                  </Link>
                </li>
                {/* <li>
                  <Link className="nav-link active" style={styles.link} to="/charts/galicia-seguros">
                    <svg style={styles.icons} fill="currentColor" class="bi bi-pie-chart" viewBox="0 0 16 16">
                      <path d="M7.5 1.018a7 7 0 0 0-4.79 11.566L7.5 7.793V1.018zm1 0V7.5h6.482A7.001 7.001 0 0 0 8.5 1.018zM14.982 8.5H8.207l-4.79 4.79A7 7 0 0 0 14.982 8.5zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z" />
                    </svg>
                    {language === 'english' ? ('Charts') : ('Métricas')}
                  </Link>
                </li> */}
                <li>
                  <Link className="nav-link active" style={styles.link} to="/unified-answers">
                    <svg style={styles.icons} fill="currentColor" class="bi bi-clipboard2-pulse" viewBox="0 0 16 16">
                      <path d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5h3Z" />
                      <path d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-12Z" />
                      <path d="M9.979 5.356a.5.5 0 0 0-.968.04L7.92 10.49l-.94-3.135a.5.5 0 0 0-.926-.08L4.69 10H4.5a.5.5 0 0 0 0 1H5a.5.5 0 0 0 .447-.276l.936-1.873 1.138 3.793a.5.5 0 0 0 .968-.04L9.58 7.51l.94 3.135A.5.5 0 0 0 11 11h.5a.5.5 0 0 0 0-1h-.128L9.979 5.356Z" />
                    </svg>
                    {language === 'english' ? ('Unified Answers') : ('Respuestas Unificadas')}
                  </Link>
                </li>
                {<li>
                  <Link className="nav-link active" style={styles.link} to="/answers">
                    <svg style={styles.icons} fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                      <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                      <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                    </svg>
                    {language === 'english' ? ('Answers') : ('Respuestas')}
                  </Link>
                </li>}
                {/* <li>
                  <Link className="nav-link active" style={styles.link} to="/newreports/galicia-seguros">
                    <svg style={styles.icons} fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                      
                    </svg>
                    {language === 'english' ? ('New Reports') : ('Nuevos Reportes')}
                  </Link>
                </li> */}
              </>
            ) : (<></>)}



            {loginUser.rol === 'Developer' || loginUser.rol === 'Agent' || loginUser.rol === 'Supervisor' ? (
              <li>
                <Link className="nav-link active" style={styles.link} to="/settings">
                  <svg style={styles.icons} fill="currentColor" class="bi bi-sliders" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z" />
                  </svg>
                  {language === 'english' ? ('Settings') : ('Ajustes')}
                </Link>
              </li>
            ) : (<></>)}

            {loginUser.company == 'VF0rMotbgtFU3FapbUo6' ? (
              <></>
            ) : (
              <li>
                <Link className="nav-link active" style={styles.link}
                  onClick={() => (window.open("https://insideone.zendesk.com/hc/es/requests/new"))}
                >
                  <svg style={styles.icons} fill="currentColor" class="bi bi-life-preserver" viewBox="0 0 16 16">
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm6.43-5.228a7.025 7.025 0 0 1-3.658 3.658l-1.115-2.788a4.015 4.015 0 0 0 1.985-1.985l2.788 1.115zM5.228 14.43a7.025 7.025 0 0 1-3.658-3.658l2.788-1.115a4.015 4.015 0 0 0 1.985 1.985L5.228 14.43zm9.202-9.202-2.788 1.115a4.015 4.015 0 0 0-1.985-1.985l1.115-2.788a7.025 7.025 0 0 1 3.658 3.658zm-8.087-.87a4.015 4.015 0 0 0-1.985 1.985L1.57 5.228A7.025 7.025 0 0 1 5.228 1.57l1.115 2.788zM8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                  </svg>
                  {language === 'english' ? ('Support') : ('Soporte')}
                </Link>
              </li>
            )}
          </ul>
          <ul className="list-unstyled" >
            {loginUser ? (
              <li>
                <button className="nav-link active" style={styles.linkButton} onClick={handleProfile}>
                  <svg style={styles.icons} fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                  </svg>
                  <h1 style={styles.option}>{language === 'english' ? (`Hello ${loginUser.firstName}!`) : (`Hola ${loginUser.firstName}!`)}</h1>
                </button>
              </li>
            ) : (<></>)}
            <li>
              <button className="nav-link active" style={styles.linkButton} onClick={handleLogout}>
                <svg style={styles.icons} fill="currentColor" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                  <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                </svg>
                <h1 style={styles.option}>{language === 'english' ? ('Log Out') : ('Salir')}</h1>
              </button>
            </li>
          </ul>
        </div>
      </nav>
    </div >
  );
};
