import React, { useState, useEffect, memo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginUserPreferences } from "../store/actions/loginAction";
import SurveysGeneral from "../components/SurveysGeneral";
import Sidebar from "./Sidebar";
import firebase from "../config/firebaseIO";


const db = firebase.firestore()

const SurveysGeneralContainer = () => {

  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [surveyPolls, setSurveysPolls] = useState([]);

  const loginUser = useSelector(state => state.user.loginUser);
  const language = useSelector(state => state.user.language);
  const darkTheme = useSelector(state => state.user.darkTheme);


  let getPolls = (async () => {
    let polls = [];
    let surveys = await db.collection('companies')
      .doc(loginUser.company)
      .collection("SURVEY_POLLS")
      .get()
      .then(info => polls = info.docs.map((ele) => {
        return { id: ele.id, data: ele.data() }
      }))
    setSurveysPolls(polls.slice());
  })

  useEffect(() => {
    getPolls();
  }, [])

  return (
    <div>
      <Sidebar />
      <SurveysGeneral
        screenSize={screenSize}
        darkModeState={darkTheme}
        language={language}
        surveyPolls={surveyPolls}
      //sendToDB={sendToDB}
      />
    </div>
  );
}

export default SurveysGeneralContainer;


  //Funciones de botones

/*let sendToDB = (name, WhatsAppchannel, question1 ,question2,question3,question4,question5,c1,c2,c3,c4,c5,ty1,ty2,ty3,ty4,ty5)=>{
 
    let valueName = document.getElementById(name).value;
    let valueWhatsAppchannel = document.getElementById(WhatsAppchannel);
    let valueChannel;
 
    if(valueWhatsAppchannel.checked == true){
      valueChannel = "web";
    }else{
      valueChannel="audio";
    }
 
    let valueQuestion1 = document.getElementById(question1).value;
    let valueQuestion2 = document.getElementById(question2).value;
    let valueQuestion3 = document.getElementById(question3).value;
    let valueQuestion4 = document.getElementById(question4).value;
    let valueQuestion5 = document.getElementById(question5).value;
    
    let chart1 = document.getElementById(c1).checked;
    let chart2 = document.getElementById(c2).checked;
    let chart3 = document.getElementById(c3).checked;
    let chart4 = document.getElementById(c4).checked;
    let chart5 = document.getElementById(c5).checked;
 
    let type1 = document.getElementById(ty1).value;
    let type2 = document.getElementById(ty2).value;
    let type3 = document.getElementById(ty3).value;
    let type4 = document.getElementById(ty4).value;
    let type5 = document.getElementById(ty5).value;
 
 
 
    let questions = [
      {question: valueQuestion1, chart:chart1, type: type1 },
      {question: valueQuestion2, chart:chart2, type: type2},
      {question: valueQuestion3, chart:chart3, type: type3},
      {question: valueQuestion4, chart:chart4, type: type4},
      {question: valueQuestion5, chart:chart5, type: type5},
      ]
 
      questions =  questions.filter((ele)=>{
        if(ele.question !=""){
          return ele
        }
      })
  
    
    let id_survey = surveyPolls.length +1;
    
    let encuesta ={
      channel: valueChannel,
      createdAt: new Date(),
      id_survey: id_survey.toString(),
      name: valueName,
      questions: questions,
      isAdmin: true,
    }
 
 
    if(valueName == "" || valueQuestion1 =="" ){
      window.alert("La encuesta debe tener un nombre y mínimo una pregunta");
      }else{
      let database = db.collection("companies2").doc('VF0rMotbgtFU3FapbUo6').collection('SURVEY_POLLS2').add(encuesta).then(()=> window.location.reload());
    }
}*/