import React, { useState, useEffect, memo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginUserPreferences } from "../store/actions/loginAction";
import EditSurvey from "../components/EditSurvey";
import Sidebar from "./Sidebar";
import firebase from "../config/firebaseIO";
import { useParams } from "react-router-dom";


const db = firebase.firestore()

const EditSurveyContainer = () => {
  // Req params ID 
  let id = useParams().id;
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [surveyPolls, SetSurveysPolls] = useState([]);
  const loginUser = useSelector(state => state.user.loginUser);
  const language = useSelector(state => state.user.language);
  const darkTheme = useSelector(state => state.user.darkTheme);


  let getPolls = (async () => {
    //traelas encuestas de la base de datos
    let polls;
    let surveys = await db.collection('companies2')
      .doc('VF0rMotbgtFU3FapbUo6')
      .collection("SURVEY_POLLS2")
      .doc(id)
      .get()
      .then(info => polls = info.data())
    //traemos el id unico, se usa para poder modificar las encuestas 
    //    .then(info => info.docs.forEach((ele) =>{
    // info.docs.forEach((ele) =>{
    //  polls.push(ele.data())
    //pollId = ele.id;//})


    SetSurveysPolls(polls);


  })

  useEffect(() => {
    getPolls();

  }, [])

  //Funciones de botones

  let sendToDB = (name, WhatsAppchannel, ...poll) => {

    let valueName = document.getElementById(name).value;
    let valueWhatsAppchannel = document.getElementById(WhatsAppchannel);
    let valueChannel;
    let questions = []

    for (let i = 1; i <= surveyPolls.questions.length + 1; i++) {

      questions.push({
        question: document.getElementById("question" + i).value,
        type: document.getElementById("type" + i).value,
        chart: document.getElementById("chart" + i).checked
      })


    };

    if (valueWhatsAppchannel.checked == true) {
      valueChannel = "web";
    } else {
      valueChannel = "audio";
    }
    questions = questions.filter((ele) => {
      if (ele.question != "") {
        return ele
      }
    })

    let encuesta = {
      channel: valueChannel,
      createdAt: new Date(),
      id_survey: surveyPolls.id_survey,
      name: valueName,
      questions: questions,
      isAdmin: true,
    }
    //Si la pregunta 1 esta vacia o el nombre de la encuesta envia una alerta  y no permite guardar la encuesta
    if (valueName == "" || document.getElementById("question1").value == "") {
      window.alert("La encuesta debe tener un nombre y mínimo una pregunta")
    } else {
      let database = db.collection("companies").doc('HZZb5MxJI1zVkQJ8MJwk').collection('SURVEY_POLLS').doc(id).set(encuesta).then(() => window.location.reload());

    }






  }


  return (
    <div>
      <Sidebar />
      <EditSurvey
        screenSize={screenSize}
        darkModeState={darkTheme}
        language={language}
        surveyPolls={surveyPolls}
        sendToDB={sendToDB}
      />
    </div>
  );
}

export default EditSurveyContainer;



//Codigo de validacion para flow de collab, valida la campaña y la envia al supervisor correspondiente
/*let servicio = "";
  let direccion ="nada";
  
  if (servicio.includes('Siniestros')){
    direccion = 'Siniestros'
    }else if(servicio.includes('Retencion')){
    direccion = 'Retención'
    }else if(servicio.includes('Consultas') || servicio == 'SV - Chat Portal DDJJ' || servicio == 'SV - Whatsapp - Bronce'
    || servicio == 'SV - Whatsapp - Cobre' || servicio == 'SV - Whatsapp - Oro' || servicio == 'SV - Whatsapp - Plata' ||
       servicio == 'SV - Whatsapp - Platino'){
    direccion = 'Consultas'
    }else if(servicio =="" || servicio == " "){
      direccion = "Vacio"
    }
    else{
    direccion = 'Otros'
    }
    console.log(direccion) */