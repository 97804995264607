import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginUserPreferences } from "../store/actions/loginAction";
import Reports from "../components/ReportsX28";
import Sidebar from "./Sidebar";
import firebase from '../config/firebaseIO'

const db = firebase.firestore()

const ReportsClient = () => {

    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [reports, setReports] = useState([]);
    const [oldReports, setOldReports] = useState([]);

    const [dateToday, setDateToday] = useState("");

    const [arrayDeOpciones, setArrayDeOpciones] = useState([]);

    const dispatch = useDispatch()

    const [opcionFilter, setEl2] = useState("");
    const [fecha1, setFecha1] = useState("");
    const [fecha2, setFecha2] = useState("");
    const [search, setSearch] = useState("");

    const [filterOn, setFilterOn] = useState(false);



    const loginUser = useSelector(state => state.user.loginUser)
    const language = useSelector(state => state.user.language)
    const darkTheme = useSelector(state => state.user.darkTheme)


    //Funcion para reportes filtrados
    const getOldReports = async (inicial, final) => {

        let companyId = loginUser.company;
        let data = await db
            .collection('companies')
            .doc(companyId)
            .collection('reports')
            .where('timestamp', '>=', inicial)
            .where('timestamp', '<=', final)
            .orderBy('timestamp', 'desc')
            //  .limit(15)
            .get();

        let reportsToState = []
        data.docs.forEach((singleReport) => {
            let optionVariable = ""
            let timeBetweenVariable = 0
            optionVariable = singleReport.data().option
            timeBetweenVariable = singleReport.data().timebetween ? singleReport.data().timebetween : "--"
            if (optionVariable.match("Ha ingresado una opción incorrecta")) {
                optionVariable = "Ha ingresado una opción incorrecta"
            }
            if (timeBetweenVariable != "--") {
                // console.log(new Date(timeBetweenVariable).toISOString().slice(11, 19))
                timeBetweenVariable = new Date(timeBetweenVariable).toISOString().slice(11, 19)
            }
            reportsToState.push({
                id: singleReport.id,
                date: singleReport.data().date,
                nombre: singleReport.data().nombre,
                apellido: singleReport.data().apellido,
                localidad: singleReport.data().localidad,
                provincia: singleReport.data().provincia,
                phoneNumber: singleReport.data().phoneNumber,
                option: optionVariable,
                optionOriginal: singleReport.data().option,
                comment: singleReport.data().comment,
                timestamp: singleReport.data().timestamp - (3600 * 1000 * 3),
                timeBefore: singleReport.data().timeBefore,
                // timebetween: singleReport.data().timebetween ? singleReport.data().timebetween : "No registrado"
                timebetween: timeBetweenVariable

            })
        });
        setOldReports(reportsToState)
    };
    // -------------

    useEffect(() => {
        let oneMonth = new Date().getTime() - 2629743000;

        window.scrollTo(0, 0);
        // window.addEventListener("resize", handleResize);
        if (loginUser.company) {
            let companyId = loginUser.company;
            db.collection('companies')
                .doc(companyId)
                .collection('reports')
                .where('timestamp', ">=", oneMonth)
                .orderBy('timestamp', "desc")
                //      .limit(10)
                .onSnapshot((reportsResult) => {
                    let reportsToState = []
                    let optionVariable = ""
                    let timeBetweenVariable = 0

                    reportsResult.forEach((singleReport) => {
                        optionVariable = singleReport.data().option
                        timeBetweenVariable = singleReport.data().timebetween ? singleReport.data().timebetween : "--"
                        if (optionVariable.match("Ha ingresado una opción incorrecta")) {
                            optionVariable = "Ha ingresado una opción incorrecta"
                        }
                        if (timeBetweenVariable != "--") {
                            // console.log(new Date(timeBetweenVariable).toISOString().slice(11, 19))
                            timeBetweenVariable = new Date(timeBetweenVariable).toISOString().slice(11, 19)
                        }
                        reportsToState.push({
                            id: singleReport.id,
                            date: singleReport.data().date,
                            nombre: singleReport.data().nombre,
                            apellido: singleReport.data().apellido,
                            localidad: singleReport.data().localidad,
                            provincia: singleReport.data().provincia,
                            phoneNumber: singleReport.data().phoneNumber,
                            option: optionVariable,
                            optionOriginal: singleReport.data().option,
                            comment: singleReport.data().comment,
                            timestamp: singleReport.data().timestamp - (3600 * 1000 * 3),
                            timeBefore: singleReport.data().timeBefore,
                            // timebetween: singleReport.data().timebetween ? singleReport.data().timebetween : "No registrado"
                            timebetween: timeBetweenVariable

                        })
                    })
                    setReports(reportsToState.slice())
                })
        }
        //DETERMINA LA HORA
        let unixTimestamp = new Date().getTime()
        let unixTimestampARG = unixTimestamp - 10800000
        let date = new Date(unixTimestampARG);
        let day = date.getDate()
        let dayString = day.toString()
        if (dayString.length === 1) { dayString = '0' + dayString }
        let month = date.getMonth() + 1
        let monthString = month.toString()
        if (monthString.length === 1) { monthString = '0' + monthString }
        let year = date.getFullYear()
        setDateToday(year + "-" + monthString + "-" + dayString)
    }, []);

    useEffect(() => {

        if (filterOn == true) {
            getOldReports(fecha1, fecha2 + 86400000);
        }

    }, [fecha1, fecha2])

    let onChange = (valor) => {
        setArrayDeOpciones(valor.map(ele => ele.value))
    }

    let reset = (startDate, endDate, searchBar, arrayDeOpciones) => {
        setEl2([]);
        setFecha1("");
        setFecha2("");
        setSearch("");
        searchBar.value = "";
        startDate.value = "";
        endDate.value = "";
        arrayDeOpciones = [];
        setFilterOn(false);
        setOldReports([])

    };

    let handleClick = (arrayDeOpciones, startDate, endDate, searchBar) => {
        reports.forEach((ele) => {
            ele.nombre = ele.nombre.toLowerCase();
            ele.apellido = ele.apellido.toLowerCase();
            ele.provincia = ele.provincia.toLowerCase();
            ele.localidad = ele.localidad.toLowerCase();
        })
        if (startDate.value != "" && endDate.value != "" && arrayDeOpciones != "" && searchBar.value != "") {
            setFecha1(new Date(startDate.value).getTime());
            setFecha2(new Date(endDate.value).getTime());
            setEl2(arrayDeOpciones);
            setSearch(searchBar.value);
            setFilterOn(true);

        } else if (startDate.value != "" && arrayDeOpciones != "") {
            setFecha1(new Date(startDate.value).getTime());
            setFecha2(new Date(endDate.value).getTime());
            setEl2(arrayDeOpciones);
            setSearch(searchBar.value);
            setFilterOn(true);


        } else if (startDate.value != "" && searchBar.value != "") {
            setFecha1(new Date(startDate.value).getTime());
            setFecha2(new Date(endDate.value).getTime());
            setSearch(searchBar.value);
            setEl2(arrayDeOpciones);
            setFilterOn(true);
            //          console.log(searchBar.value)

        }
        else if (searchBar.value != "" && arrayDeOpciones != "") {
            setSearch(searchBar.value);
            setEl2(arrayDeOpciones);
            setFecha1("");
            setFecha2("");
        }
        else if (arrayDeOpciones != "") {
            setEl2(arrayDeOpciones);
            setFecha1("");
            setFecha2("");
            setSearch("");
        }
        else if (startDate.value != "" && endDate.value != "") {
            setFecha1(new Date(startDate.value).getTime());
            setFecha2(new Date(endDate.value).getTime());
            setEl2("")
            setSearch("");
            setFilterOn(true);

        }
        else if (searchBar.value != "") {
            setSearch(searchBar.value);
            //     console.log(searchBar.value);
            setEl2("");
            setFecha1("");
            setFecha2("");
        } else {
            setEl2("");
            setFecha1("");
            setFecha2("");
            setSearch("");
        }
    };

    return (
        <div>
            <Sidebar />
            <Reports
                screenSize={screenSize}
                darkModeState={darkTheme}
                language={language}
                reports={reports}
                dateToday={dateToday}
                handleClick={handleClick}
                reset={reset}
                opcionFilter={opcionFilter}
                fecha1={fecha1}
                fecha2={fecha2}
                search={search}
                onChange={onChange}
                oldReports={oldReports}
                arrayDeOpciones={arrayDeOpciones}
                filterOn={filterOn}
            />
        </div>
    );
}

export default ReportsClient;