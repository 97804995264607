import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginUserPreferences } from "../store/actions/loginAction";
import ChartsBbva from "../components/ChartsBbva";

import Sidebar from "./Sidebar";
import firebase from '../config/firebaseIO';
const db = firebase.firestore()

const ChartClientsBbva = () => {

    const [screenSize, setScreenSize] = useState(window.innerWidth);

    const [charts3, setCharts3] = useState([]);
    const [Oldcharts, setOldcharts] = useState([]);

    const [startDate, setStartDate] = useState("");
    const [FilterOn, setFilterOn] = useState("");

    const [endDate, setEndDate] = useState("");
    const [displayCharts, setDisplayCharts] = useState(false);

    const dispatch = useDispatch()
    const loginUser = useSelector(state => state.user.loginUser)
    const language = useSelector(state => state.user.language)
    const darkTheme = useSelector(state => state.user.darkTheme)


    useEffect(() => {
        window.scrollTo(0, 0);
        if (loginUser.company && FilterOn == false) {
            let today = new Date().getTime();
            let oneMonthBefore = today - 2629743000
            let companyId = loginUser.company;

            db.collection("companies").doc(companyId).collection("reports")
                .where('timestamp', '>=', oneMonthBefore)
                .orderBy('timestamp', "desc")
                .onSnapshot((ele) => {
                    let estado = [];
                    ele.forEach(elem => {
                        estado.push(elem.data())
                    })
                    setCharts3(estado)
                });
        }
    }, []);


    let getOldSurveys = async () => {
        let encuestas = []

        if (loginUser.company && FilterOn == true) {
            let companyId = loginUser.company;
            let reports = await db.collection("companies").doc(companyId).collection("reports")
                .where('timestamp', '>', startDate + 10800000)
                .where('timestamp', '<', (endDate + 10800000 + 86399900))
                .orderBy('timestamp', 'desc')
                .get();

            //      console.log(startDate)
            //      console.log(endDate + 8640000)
            //       console.log(1671928740123)
            reports.docs.forEach(ele => encuestas.push((ele.data())))
        }
        setOldcharts(encuestas)
    }


    useEffect(() => {
        window.scrollTo(0, 0);

        getOldSurveys();



    }, [startDate, endDate])

    const reset = (date1, date2) => {
        setStartDate("");
        setEndDate("");
        date1.value = ("");
        date2.value = ("");
        setDisplayCharts(false);
        setFilterOn(false);

    };

    const handleClick = (date1, date2) => {
        if (date1.value != 0 && date2.value != 0) {
            setStartDate(new Date(date1.value).getTime());
            setEndDate(new Date(date2.value).getTime());
            setDisplayCharts(true);
            setFilterOn(true);
        }
    };





    return (
        <div>
            <Sidebar />
            <ChartsBbva
                screenSize={screenSize}
                darkModeState={darkTheme}
                language={language}
                charts3={charts3}
                startDate={startDate}
                endDate={endDate}
                reset={reset}
                handleClick={handleClick}
                FilterOn={FilterOn}
                displayCharts={displayCharts}
                Oldcharts={Oldcharts}
            />
        </div>
    );
}

export default ChartClientsBbva;