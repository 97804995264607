import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginUserPreferences } from "../store/actions/loginAction";
import ReportsGalicia from "../components/ReportsBBVA";
import Sidebar from "./Sidebar";
import firebase from '../config/firebaseIO'

const db = firebase.firestore()

const ReportsClientBBVA = () => {

    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [reports, setReports] = useState([]);
    const [dateToday, setDateToday] = useState("");

    const [arrayDeOpciones, setArrayDeOpciones] = useState([]);

    const dispatch = useDispatch()

    const [opcionFilter, setEl2] = useState("");
    const [filterOn, setFilterOn] = useState(false);
    const [OldAnwers, setOldAnwers] = useState([]);


    const [fecha1, setFecha1] = useState("");
    const [fecha2, setFecha2] = useState("");
    const [search, setSearch] = useState("");

    const loginUser = useSelector(state => state.user.loginUser)
    const language = useSelector(state => state.user.language)
    const darkTheme = useSelector(state => state.user.darkTheme)

    useEffect(() => {
        window.scrollTo(0, 0);
        // window.addEventListener("resize", handleResize);

        try {

            if (loginUser.company) {
                let companyId = loginUser.company;
                let today = new Date().getTime();
                let oneMonthBefore = today - 2629743000
                db.collection('companies').doc(companyId).collection('reports')
                    .where("timestamp", ">=", oneMonthBefore)
                    .orderBy('timestamp', "desc")
                    //     .limit(50)
                    .onSnapshot((reportsResult) => {
                        let reportsToState = [];
                        let optionVariable = "";
                        let timeBetweenVariable = 0;

                        reportsResult.forEach((singleReport) => {
                            optionVariable = singleReport.data().option
                            timeBetweenVariable = singleReport.data().timebetween ? singleReport.data().timebetween : "--"
                            if (optionVariable.match("Ha ingresado una opción incorrecta")) {
                                optionVariable = "Ha ingresado una opción incorrecta"
                            }
                            if (timeBetweenVariable != "--") {
                                timeBetweenVariable = new Date(timeBetweenVariable).toISOString().slice(11, 19)
                            }
                            reportsToState.push({
                                id: singleReport.id,
                                date: singleReport.data().date,
                                nombre: singleReport.data().nombre,
                                apellido: singleReport.data().apellido,
                                localidad: singleReport.data().localidad,
                                provincia: singleReport.data().provincia,
                                phoneNumber: singleReport.data().phoneNumber,
                                option: optionVariable,
                                optionOriginal: singleReport.data().option,
                                comment: singleReport.data().comment,
                                timestamp: singleReport.data().timestamp - (3600 * 1000 * 3),
                                timeBefore: singleReport.data().timeBefore,
                                dni: singleReport.data().dni,
                                sucursal: singleReport.data().sucursal ? singleReport.data().sucursal : "",
                                detalle: singleReport.data().detalle,
                                timebetween: timeBetweenVariable

                            })
                        })
                        setReports(reportsToState.slice())
                    })
            }

            //DETERMINA LA HORA
            let unixTimestamp = new Date().getTime()
            let unixTimestampARG = unixTimestamp - 10800000
            let date = new Date(unixTimestampARG);
            let day = date.getDate()
            let dayString = day.toString()
            if (dayString.length === 1) { dayString = '0' + dayString }
            let month = date.getMonth() + 1
            let monthString = month.toString()
            if (monthString.length === 1) { monthString = '0' + monthString }
            let year = date.getFullYear()
            setDateToday(year + "-" + monthString + "-" + dayString)
        } catch (error) {

        }
    }, []);



    //funcion asincrona que toma los datos de encuestas filtradas
    const getOldSurveys = async () => {

        try {

            let companyId = loginUser.company;
            let Oldsurveys = await db.collection('companies')
                .doc(companyId)
                .collection('reports')
                .where('timestamp', '>=', fecha1 + (3600 * 1000 * 3))
                .where('timestamp', '<=', fecha2 + 86400000)
                //     .limit(15)
                .get();
            let optionVariable;
            let timeBetweenVariable;

            Oldsurveys = Oldsurveys.docs.map((ele) => {
                optionVariable = ele.data().option
                timeBetweenVariable = ele.data().timebetween ? ele.data().timebetween : "--"
                if (optionVariable.match("Ha ingresado una opción incorrecta")) {
                    optionVariable = "Ha ingresado una opción incorrecta"
                }
                if (timeBetweenVariable != "--") {
                    timeBetweenVariable = new Date(timeBetweenVariable).toISOString().slice(11, 19)
                }

                return {
                    id: ele.id,
                    date: ele.data().date,
                    nombre: ele.data().nombre,
                    apellido: ele.data().apellido,
                    localidad: ele.data().localidad,
                    provincia: ele.data().provincia,
                    phoneNumber: ele.data().phoneNumber,
                    option: optionVariable,
                    optionOriginal: ele.data().option,
                    comment: ele.data().comment,
                    timestamp: ele.data().timestamp - (3600 * 1000 * 3),
                    timeBefore: ele.data().timeBefore,
                    dni: ele.data().dni,
                    sucursal: ele.data().sucursal ? ele.data().sucursal : "",
                    detalle: ele.data().detalle,
                    timebetween: timeBetweenVariable
                }
            });

            setOldAnwers(Oldsurveys)
        } catch (err) {
            console.log(err)
        }
    };


    useEffect(() => {
        if (filterOn == true) {
            getOldSurveys();
        }
    }, [fecha1, fecha2])


    let onChange = (valor) => {
        setArrayDeOpciones(valor.map(ele => ele.value))
    }

    let reset = (startDate, endDate, searchBar, arrayDeOpciones) => {
        setEl2([]);
        setFecha1("");
        setFecha2("");
        setSearch("");
        searchBar.value = "";
        startDate.value = "";
        endDate.value = "";
        arrayDeOpciones = [];
        setFilterOn(false)

    };

    let handleClick = (arrayDeOpciones, startDate, endDate, searchBar) => {
        reports.forEach((ele) => {
            ele.nombre = ele.nombre.toLowerCase();
            ele.apellido = ele.apellido.toLowerCase();
            ele.provincia = ele.provincia.toLowerCase();
            ele.localidad = ele.localidad.toLowerCase();
        })
        if (startDate.value != "" && endDate.value != "" && arrayDeOpciones != "" && searchBar.value != "") {
            setFecha1(new Date(startDate.value).getTime());
            setFecha2(new Date(endDate.value).getTime());
            setEl2(arrayDeOpciones);
            setSearch(searchBar.value);
            setFilterOn(true)

        } else if (startDate.value != "" && arrayDeOpciones != "") {
            setFecha1(new Date(startDate.value).getTime());
            setFecha2(new Date(endDate.value).getTime());
            setEl2(arrayDeOpciones);
            setSearch(searchBar.value);
            setFilterOn(true)


        } else if (startDate.value != "" && searchBar.value != "") {
            setFecha1(new Date(startDate.value).getTime());
            setFecha2(new Date(endDate.value).getTime());
            setSearch(searchBar.value);
            setEl2(arrayDeOpciones);
            setFilterOn(true)


        }
        else if (searchBar.value != "" && arrayDeOpciones != "") {
            setSearch(searchBar.value);
            setEl2(arrayDeOpciones);
            setFecha1("");
            setFecha2("");

        }
        else if (arrayDeOpciones != "") {
            setEl2(arrayDeOpciones);
            setFecha1("");
            setFecha2("");
            setSearch("");
        }
        else if (startDate.value != "" && endDate.value != "") {
            setFecha1(new Date(startDate.value).getTime());
            setFecha2(new Date(endDate.value).getTime());
            setEl2("")
            setSearch("");
            setFilterOn(true)

        }
        else if (searchBar.value != "") {
            setSearch(searchBar.value);
            setEl2("");
            setFecha1("");
            setFecha2("");

        } else {
            setEl2("");
            setFecha1("");
            setFecha2("");
            setSearch("");
            setFilterOn(false)

        }
    };

    return (
        <div>
            <Sidebar />
            <ReportsGalicia
                screenSize={screenSize}
                darkModeState={darkTheme}
                language={language}
                reports={reports}
                dateToday={dateToday}
                handleClick={handleClick}
                reset={reset}
                opcionFilter={opcionFilter}
                fecha1={fecha1}
                fecha2={fecha2}
                search={search}
                onChange={onChange}
                arrayDeOpciones={arrayDeOpciones}
                filterOn={filterOn}
                OldAnwers={OldAnwers}
            />
        </div>
    );
}

export default ReportsClientBBVA;