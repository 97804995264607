import React from "react";
import Select from "react-select";
import {
    Chart as ChartJS,
    CategoryScale,
    RadialLinearScale,
    LinearScale,
    PointElement,
    BarElement,
    ArcElement,
    LineElement,
    Filler,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";

export default ({
    screenSize,
    darkModeState,
    language,
    startDate,
    endDate,
    onChange,
    reset,
    handleClick,
    displayCharts,
    surveysGalicia,
    arrayDeOpciones,
    opcionFilter,
    surveyPolls,
    Oldsurveys,
    FilterOn,
    surveyCampaigns,
    surveyQuantityChat,
    surveyQuantityAudio
}) => {
    // En estas variables se almacenan todas las interacciones en los ultimos 30 y 7 dias.
    // let encuestasTitle = [];
    // let encuestasUnaSemana = { audio: 0, web: 0 };
    // let channelCounter = { audio: 0, web: 0 }

    let encuestas = [];
    if (startDate && endDate) {
        encuestas = Oldsurveys.slice()
    } else {
        encuestas = surveysGalicia.slice()
    }


    // console.log('encuestas>', encuestas)
    // console.log('surveyPolls>', surveyPolls)

    // Se crea la Variable encuestas, la cual relaciona las respuestas con las encuestas, se usa para agregar los nombres de las campañas en los filtros
    // contabilizar el tipo de encuesta que se hizo

    // surveysGalicia.forEach((ele, index) => {
    //     if (ele.service == "") {
    //         surveysGalicia[index].service = "Otros"
    //     }
    //     encuestasTitle.push(ele.service)
    // })

    // if (Oldsurveys.length >= 1) {

    //     Oldsurveys.forEach((ele, index) => {
    //         if (ele.service == "") {
    //             Oldsurveys[index].service = "Otros"
    //         }
    //         encuestasTitle.push(ele.service)
    //     })
    // }



    // if (Oldsurveys.length >= 1) {
    //     Oldsurveys.forEach((ele, index) => {
    //         encuestasTitle.push(ele.service)
    //     })
    // } else {
    //     surveysGalicia.forEach((ele, index) => {
    //         encuestasTitle.push(ele.service)
    //     })
    // }



    // if (startDate && endDate) {
    //     encuestasTitle = Oldsurveys.length
    // } else {
    //     encuestasTitle = surveysGalicia.length
    // }





    // if (startDate != "" && endDate != "" && opcionFilter != "") {

    //     // ------- triple filtrooo ------- 
    //     for (let j = 0; j < surveyPolls.length; j++) {
    //         for (let i = 0; i < Oldsurveys.length; i++) {
    //             if (Oldsurveys[i].survey_id == surveyPolls[j].id_survey) {
    //                 if (opcionFilter.includes(Oldsurveys[i].service,)) {
    //                     for (let k = 0; k < Oldsurveys[i].nestedAnswers.length; k++) {
    //                         encuestas.push({
    //                             name: surveyPolls[j].name,
    //                             service: Oldsurveys[i].service,
    //                             timestamp: Oldsurveys[i].timestamp,
    //                             date: Oldsurveys[i].date,
    //                             channel: surveyPolls[j].channel,
    //                             id_survey: surveyPolls[j].id_survey,
    //                             question: surveyPolls[j].questions[k] ? surveyPolls[j].questions[k].question : "",
    //                             value: Oldsurveys[i].nestedAnswers[k],
    //                             id_question: k + 1
    //                         })
    //                     }
    //                 }
    //             }
    //         }
    //     }
    //     encuestas.forEach((ele) => {
    //         if (ele.id_question == 1) {
    //             if (ele.channel == "audio") { channelCounter.audio += 1 } else { channelCounter.web += 1 }
    //         }
    //     }
    //     )
    // }

    // else if (startDate && endDate) {
    //     for (let j = 0; j < surveyPolls.length; j++) {
    //         for (let i = 0; i < Oldsurveys.length; i++) {
    //             if (Oldsurveys[i].survey_id == surveyPolls[j].id_survey) {

    //                 for (let k = 0; k < Oldsurveys[i].nestedAnswers.length; k++) {
    //                     encuestas.push({
    //                         name: surveyPolls[j].name,
    //                         service: Oldsurveys[i].service,
    //                         timestamp: Oldsurveys[i].timestamp,
    //                         date: Oldsurveys[i].date,
    //                         channel: surveyPolls[j].channel,
    //                         id_survey: surveyPolls[j].id_survey,
    //                         question: surveyPolls[j].questions[k] ? surveyPolls[j].questions[k].question : "",
    //                         value: Oldsurveys[i].nestedAnswers[k],
    //                         id_question: k + 1
    //                     })
    //                 }

    //             }
    //         }
    //     }


    //     // -------
    //     encuestas.forEach((ele) => {
    //         if (ele.id_question == 1) {
    //             if (ele.channel == "audio") {
    //                 channelCounter.audio += 1
    //             } else {
    //                 channelCounter.web += 1
    //             }
    //         }
    //     })

    // }

    // else if (opcionFilter) {
    //     for (let j = 0; j < surveyPolls.length; j++) {
    //         for (let i = 0; i < surveysGalicia.length; i++) {

    //             if (surveysGalicia[i].survey_id == surveyPolls[j].id_survey) {
    //                 if (opcionFilter.includes(surveysGalicia[i].service)) {
    //                     for (let k = 0; k < surveysGalicia[i].nestedAnswers.length; k++) {
    //                         encuestas.push({
    //                             name: surveyPolls[j].name,
    //                             service: surveysGalicia[i].service,
    //                             timestamp: surveysGalicia[i].timestamp,
    //                             date: surveysGalicia[i].date,
    //                             channel: surveyPolls[j].channel,
    //                             id_survey: surveyPolls[j].id_survey,
    //                             question: surveyPolls[j].questions[k] ? surveyPolls[j].questions[k].question : "",
    //                             value: surveysGalicia[i].nestedAnswers[k],
    //                             id_question: k + 1
    //                         })
    //                     }
    //                 }
    //             }
    //         }
    //     }


    //     encuestas.forEach((ele) => {
    //         if (ele.id_question == 1) {
    //             if (ele.channel == "audio") {
    //                 channelCounter.audio += 1
    //             } else {
    //                 channelCounter.web += 1
    //             }
    //         }
    //     })

    // }


    // else {
    //     for (let j = 0; j < surveyPolls.length; j++) {
    //         for (let i = 0; i < surveysGalicia.length; i++) {
    //             if (surveysGalicia[i].survey_id == surveyPolls[j].id_survey) {
    //                 for (let k = 0; k < surveysGalicia[i].nestedAnswers.length; k++) {

    //                     encuestas.push({
    //                         name: surveyPolls[j].name,
    //                         service: surveysGalicia[i].service,
    //                         timestamp: surveysGalicia[i].timestamp,
    //                         date: surveysGalicia[i].date,
    //                         channel: surveyPolls[j].channel,
    //                         id_survey: surveyPolls[j].id_survey,
    //                         question: surveyPolls[j].questions[k] ? surveyPolls[j].questions[k].question : "",
    //                         value: surveysGalicia[i].nestedAnswers[k],
    //                         id_question: k + 1
    //                     })
    //                 }

    //             }
    //         }
    //     }
    // }


    // Se crean las opciones unicas para el filtro de campañas
    let opcionesFiltro = Array.from(new Set(surveyCampaigns)).map((ele) => ({ value: ele, label: ele }));



    //Se agregan las respuestas de los clientes en surveyPolls -> valueAnwers

    //FILTROS
    //Captura los valores de los filtros
    let fechaInicio = document.getElementById("fechaInicio");
    let fechaFinal = document.getElementById("fechaFinal");
    let today = Math.round(new Date().getTime() / 1000);


    //FILTRO POR OPTION BAR Y FECHA
    if (startDate && endDate && opcionFilter) {
        if ((endDate + 86400000) - startDate >= (2629743000 + 86400000)) {
            window.alert("La fecha debe ser menor a un mes");
            reset(arrayDeOpciones, fechaInicio, fechaFinal)
        } else {
            //si existe filtro por fecha y por campaña y el tiempo de busqueda es menor a 31 dias
            encuestas = encuestas.filter((ele) => {
                if (opcionFilter.includes(ele.service)) {
                    return ele;
                }
            });
            for (let i = 0; i < surveyPolls.length; i++) {
                for (let j = 0; j < surveyPolls[i].questions.length; j++) {
                    // surveyPolls[i].questions[j].timestamp = 0;
                    surveyPolls[i].questions[j].valueAnswers = {};

                    encuestas.forEach((ele) => {
                        if (surveyPolls[i].id_survey == ele.id_survey) {
                            if (j + 1 == ele.id_question) {
                                surveyPolls[i].questions[j].valueAnswers[ele.value] = surveyPolls[i].questions[j].valueAnswers[ele.value] + 1 || 1;
                                // surveyPolls[i].questions[j].timestamp = ele.timestamp;
                            }
                        }
                    });
                }
            }
        }
    }
    //FILTRO POR FECHA
    else if (startDate && endDate) {
        if ((endDate + 86400000) - startDate >= (2629743000 + 86400000)) {
            window.alert("La fecha debe ser menor a un mes");
            reset(arrayDeOpciones, fechaInicio, fechaFinal)

        } else {
            for (let i = 0; i < surveyPolls.length; i++) {
                for (let j = 0; j < surveyPolls[i].questions.length; j++) {
                    surveyPolls[i].questions[j].answers = [];
                    surveyPolls[i].questions[j].valueAnswers = {};
                    encuestas.forEach((ele) => {
                        if (surveyPolls[i].id_survey == ele.id_survey) {
                            if (j + 1 == ele.id_question) {
                                surveyPolls[i].questions[j].valueAnswers[ele.value] =
                                    surveyPolls[i].questions[j].valueAnswers[ele.value] +
                                    1 || 1;
                            }
                        }
                    });
                }
            }
            encuestas = encuestas.filter(
                (singleReport) => singleReport.timestamp >= startDate && singleReport.timestamp <= endDate + 86400000);
        }
    }
    //FILTRO POR OPTION BAR
    else if (opcionFilter != "") {
        // surveysGalicia = surveysGalicia.filter((ele) => {
        //     if (opcionFilter.includes(ele.service)) {
        //         return ele;
        //     }
        // });
        encuestas = encuestas.filter(
            ((ele) => {
                if (opcionFilter.includes(ele.service)) {
                    return ele;
                }
            }
            )
        );

        for (let i = 0; i < surveyPolls.length; i++) {
            for (let j = 0; j < surveyPolls[i].questions.length; j++) {
                surveyPolls[i].questions[j].answers = [];
                surveyPolls[i].questions[j].valueAnswers = {};
                encuestas.forEach((ele) => {
                    if (surveyPolls[i].id_survey == ele.id_survey) {
                        if (j + 1 == ele.id_question) {
                            surveyPolls[i].questions[j].valueAnswers[ele.value] = surveyPolls[i].questions[j].valueAnswers[ele.value] + 1 || 1;
                        }
                    }
                });
            }
        }
    }
    else {
        for (let i = 0; i < surveyPolls.length; i++) {
            for (let j = 0; j < surveyPolls[i].questions.length; j++) {
                surveyPolls[i].questions[j].valueAnswers = {};

                encuestas.forEach((ele) => {
                    if (surveyPolls[i].id_survey == ele.id_survey) {
                        if (j + 1 == ele.id_question) {
                            surveyPolls[i].questions[j].valueAnswers[ele.value] = surveyPolls[i].questions[j].valueAnswers[ele.value] + 1 || 1;
                        }
                    }
                });
                // console.log("surveyPolls final>", surveyPolls)
            }
        }
    }





    // Se genera un array de objetos llamado encuestas que asocia las respuestas con las preguntas
    // encuestas.forEach((ele) => {
    //     if (ele.id_question == 1 && (today - ele.timestamp) <= 604800000) {
    //         if (ele.channel == "audio") {
    //             encuestasUnaSemana.audio += 1
    //         } else {
    //             encuestasUnaSemana.web += 1
    //         }
    //     }
    // });

    ChartJS.register(
        CategoryScale,
        LinearScale,
        RadialLinearScale,
        PointElement,
        BarElement,
        PointElement,
        ArcElement,
        LineElement,
        Filler,
        Title,
        Tooltip,
        Legend
    );

    const styles = {
        invisible: {
            display: "none",
        },
        backColor: {
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            height: "100%",
            backgroundColor: `${darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"
                }`,
        },
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 800 ? ("3% 2% 3% 20%") : ("30px")}`,
            padding: `${screenSize > 800 ? "3% 2% 0px 280px" : "30px"}`,
            height: "100%",
            //   overflow: `auto`,
        },
        containerLoader: {
            display: "flex",
            alignItem: "center",
            justifyContent: "center",
            height: "80%",
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 800 ? "0px 20px" : "0px"}`,
            marginBottom: `${screenSize > 800 ? "0px" : "30px"}`,
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
        },
        titleNavbar: {
            marginBottom: `${screenSize > 800 ? "5px" : "10px"}`,
            fontSize: "28px",
            color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
            fontFamily: "InterBold",
            lineHeight: "1.4",
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            fontFamily: "InterBold",
        },
        toogleNavbar: {
            display: "flex",
            flex: 4,
            alignItems: "center",
            justifyContent: `${screenSize > 800 ? "flex-end" : "center"}`,
            flexDirection: `${screenSize > 800 ? "row" : "column"}`,
        },
        textToogle: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: "inherit",
            border: "none",
            outline: "none",
            padding: "12px",
            backgroundColor: `transparent`,
        },
        textUser: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: "inherit",
            border: "none",
            outline: "none",
            padding: 0,
            backgroundColor: `transparent`,
        },
        buttonNew: {
            textDecoration: "none",
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: "none",
            margin: "20px",
            // margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
            width: `${screenSize > 800 ? "180px" : <></>}`,
        },
        reports: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
            margin: `${screenSize > 800 ? "0px 20px" : "0px"}`,
            overflow: `auto`,
            padding: "0px 0px 50px 0px",
            borderRadius: "10px",
            // backgroundColor: 'red'
        },
        singleLineReport: {
            display: "flex",
            flexDirection: "row",
        },
        singleDoughnutChart: {
            //       display: "flex",
            flex: 1,
            alignItems: "flex-start",
            justifyContent: "flex-start",
            borderRadius: "10px",
            //  backgroundColor: 'red',
            padding: "25px",
            textDecoration: "none",
            border: "none",
            outline: "none",
            margin: `${screenSize > 800 ? "5px" : "10px"}`,
            width: "100px",
        },
        singleBarCharts: {
            flex: 1,
            alignItems: "flex-start",
            justifyContent: "flex-start",
            borderRadius: "10px",
            //   backgroundColor: 'red',
            padding: "25px",
            textDecoration: "none",
            border: "none",
            outline: "none",
            margin: `${screenSize > 800 ? "5px" : "10px"}`,
            width: "100px",
            //  height: '500px'
        },
        singleIndicator: {
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            borderRadius: "10px",
            //    backgroundColor: 'red',
            padding: "18px 25px",
            textDecoration: "none",
            border: "none",
            outline: "none",
            margin: `${screenSize > 800 ? "5px" : "10px"}`,
            //       backgroundColor: 'red'
            //     height: '100%'
        },
        singleIndicatorInfo: {
            flex: 2,
            flexDirection: "column",
            //       backgroundColor: 'green'
        },
        singleIndicatorInfoComments: {
            flex: 2,
            flexDirection: "column",
            overflow: `auto`,
            height: '300px',
            //    height: '100%'
            //       backgroundColor: 'green'
        },
        singleIndicatorTitleComments: {
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            margin: "0px 0px 15px",
            fontSize: "18px",
            fontFamily: "InterBold",
            textAlign: "start",
        },
        singleIndicatorComments: {
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            margin: "0px",
            fontSize: "14px",
            fontFamily: "InterBold",
            textAlign: "start",
            borderTop: 'thin solid #A8AFD3',
            //   borderBottom: 'thin solid #A8AFD3',
            padding: '5px 0px',
        },
        singleIndicatorTitle: {
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            margin: "0px 0px 5px",
            fontSize: "18px",
            fontFamily: "InterBold",
            textAlign: "start",
        },
        singleIndicatorSubtitle: {
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            margin: "0px",
            fontSize: "12px",
            fontFamily: "InterBold",
            textAlign: "start",
        },

        singleIndicatorNumber: {
            flex: 1,
            alignSelf: "flex-end",
            color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
            margin: "0px",
            fontSize: "40px",
            fontFamily: "InterBold",
            textAlign: "end",
            //     backgroundColor: 'blue'
        },

        //---------------------------------------------------------------

        lineDetail: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        phoneNumber: {
            display: "flex",
            flex: 4,
            color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
            margin: "0px",
            fontSize: "18px",
            fontFamily: "InterBold",
            textAlign: "start",
            alignSelf: "center",
        },
        option: {
            display: "flex",
            flex: 6,
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            margin: 0,
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
        },
        date: {
            display: "flex",
            flex: 2,
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            margin: 0,
            fontSize: "14px",
            fontFamily: "InterBold",
            // textAlign: 'end'
            alignSelf: "center",
            justifyContent: "flex-end",
        },
        loader: {
            display: "flex",
            alignSelf: "center",
        },
        try: {
            position: "absolute",
        },
        filters: {
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "center",
            margin: `${screenSize > 800 ? "0px 25px 20px" : "0px"}`,
        },
        filterGroup: {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            padding: "0px 20px 0px 0px",
        },
        filterDateGroup: {
            display: "flex",
            flexDirection: "row",
        },
        filterLabel: {
            fontFamily: "InterBold",
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            padding: "0px 5px",
        },
        filterLabelCharts: {
            fontFamily: "InterBold",
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            padding: "0px 5px",
            margin: '10px 0px 5px'
        },
        filterInputDate: {
            border: "none",
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            fontFamily: "InterBold",
            width: "100%",
            height: "40px",
            borderRadius: "5px",
            padding: "5px 10px",
            margin: "0px 10px 0px 0px",
        },
        filterInput: {
            border: "none",
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            fontFamily: "InterBold",
            width: "100%",
            height: "40px",
            borderRadius: "5px",
            padding: "5px 10px",
        },
        filterButton: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textDecoration: "none",
            outline: 0,
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: "none",
            borderRadius: 5,
            margin: 0,
            padding: "8px 13px",
            width: "100px",
        },
        resetButton: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textDecoration: "none",
            outline: 0,
            color: "#4dc2f1",
            backgroundColor: "transparent",
            border: "1px #4dc2f1 solid",
            borderRadius: 5,
            margin: "0px 0px 0px 10px",
            padding: "8px 13px",
            width: "100px",
        },
    };
    return (
        <>
            <div style={styles.backColor} />
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <h3 style={styles.titleNavbar}>{language === "english" ? "Charts" : "Métricas"} 😃</h3>
                        <h3 style={styles.subtitleNavbar}>{language === "english" ? "All surveys charts." : "Todas las métricas de encuestas."}</h3>
                    </div>
                </div>
                <div style={styles.filters}>
                    <div style={styles.filterGroup}>
                        <label style={styles.filterLabel}>{language === "english" ? "Date filter" : "Filtro por fecha"}</label>
                        <div style={styles.filterDateGroup}>
                            <input className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.filterInputDate} type="date" id="fechaInicio" />
                            <input className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.filterInput} type="date" id="fechaFinal" />
                        </div>
                    </div>
                    <div style={styles.filterGroup}>
                        <label style={styles.filterLabel}>{language === "english" ? "Service filter" : "Filtro por campaña"}</label>
                        <Select name="opciones" id="opciones" isMulti options={opcionesFiltro} onChange={(valor) => { onChange(valor); }} className="basic-multi-select" classNamePrefix="select" />
                    </div>
                    <button style={styles.filterButton} onClick={() => { handleClick(arrayDeOpciones, fechaInicio, fechaFinal); }}>  {language === "english" ? "Apply" : "Filtrar"} </button>
                    <button style={styles.resetButton} onClick={() => { reset(arrayDeOpciones, fechaInicio, fechaFinal) }}> {language === "english" ? "Reset" : "Limpiar"} </button>
                </div>
                <div style={styles.reports}>
                    {FilterOn == true ?
                        <>
                            <label style={styles.filterLabelCharts}>{language === "english" ? "Totals" : "Totales"}</label>
                            <div style={displayCharts == false ? styles.singleLineReport : styles.invisible}>
                                <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleIndicator}>
                                    <div style={styles.singleIndicatorInfo}>
                                        <div style={styles.singleIndicatorTitle}>{language === "english" ? "Total Surveys" : "Encuestas totales"}</div>
                                        <div style={styles.singleIndicatorSubtitle}>{language === "english" ? "Call" : "Telefonía"}</div>
                                    </div>
                                    <div style={styles.singleIndicatorNumber}>{surveyQuantityAudio}</div>
                                </div>
                                <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleIndicator}>
                                    <div style={styles.singleIndicatorInfo}>
                                        <div style={styles.singleIndicatorTitle}>{language === "english" ? "Total Surveys" : "Encuestas totales"}</div>
                                        <div style={styles.singleIndicatorSubtitle}>{language === "english" ? "Messages" : "Mensajería"}</div>
                                    </div>
                                    <div style={styles.singleIndicatorNumber}>{surveyQuantityChat}</div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <label style={styles.filterLabelCharts}>{language === "english" ? "Totals" : "Totales"}</label>
                            <div style={displayCharts == false ? styles.singleLineReport : styles.invisible}>
                                <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleIndicator}>
                                    <div style={styles.singleIndicatorInfo}>
                                        <div style={styles.singleIndicatorTitle}>{language === "english" ? "Last Week Surveys" : "Encuestas Semanales"}</div>
                                        <div style={styles.singleIndicatorSubtitle}>{language === "english" ? "Call" : "Telefonía"}</div>
                                    </div>
                                    <div style={styles.singleIndicatorNumber}>{surveyQuantityAudio}</div>
                                </div>
                                <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleIndicator}>
                                    <div style={styles.singleIndicatorInfo}>
                                        <div style={styles.singleIndicatorTitle}>{language === "english" ? "Last Week Surveys" : "Encuestas Semanales"}</div>
                                        <div style={styles.singleIndicatorSubtitle}>{language === "english" ? "Messages" : "Mensajería"}</div>
                                    </div>
                                    <div style={styles.singleIndicatorNumber}>{surveyQuantityChat}</div>
                                </div>
                            </div>
                        </>
                    }
                    {
                        surveyPolls.map((singlePoll) => {
                            return (
                                <>
                                    <label style={styles.filterLabelCharts}>{singlePoll.name}</label>
                                    <div style={displayCharts == false ? styles.singleLineReport : styles.invisible}>
                                        {singlePoll.questions.map((datos) => {
                                            return (
                                                <>
                                                    {datos.question == "comentario" || datos.question == "Contanos ¿Qué aspectos valoraste o en qué podemos mejorar?" || datos.question == "Comentario" ? (
                                                        // <div>Comentario</div>
                                                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleIndicator}>
                                                            <div style={styles.singleIndicatorInfoComments}>
                                                                <div style={styles.singleIndicatorTitleComments}>{language === "english" ? "Comments" : "Comentarios"}</div>
                                                                {Object.keys(datos.valueAnswers).map(
                                                                    (ele) => <div style={styles.singleIndicatorComments}>{ele}</div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ) : Object.values(datos) != "" ? (
                                                        // ) : Object.values(datos.valueAnswers) != "" ? (
                                                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleBarCharts} >
                                                            {/* <div>Grafico</div> */}
                                                            <Bar
                                                                options={{ responsive: true, plugins: { legend: { position: "bottom", display: false }, title: { display: true, text: datos.question } } }}
                                                                data={{
                                                                    labels: datos.question == "¿Resolvimos el motivo de tu llamado?"
                                                                        || datos.question == "¿Su consulta fue resuelta en este contacto?" ? ["Sí", "No"] : Object.keys(datos.valueAnswers),

                                                                    // labels: ["Sí", "No"],

                                                                    datasets: [{
                                                                        label: "Cantidad", data: Object.values(datos.valueAnswers),
                                                                        backgroundColor: ["rgba(255, 99, 132)", "rgba(54, 162, 235)", "rgba(255, 206, 86)", "rgba(75, 192, 192)", "rgba(153, 102, 255)"]
                                                                    }]

                                                                    // datasets: [{
                                                                    //     label: "Cantidad", data: [1, 2, 3, 4, 5],
                                                                    //     backgroundColor: ["rgba(255, 99, 132)", "rgba(54, 162, 235)", "rgba(255, 206, 86)", "rgba(75, 192, 192)", "rgba(153, 102, 255)"]
                                                                    // }]
                                                                }} />
                                                        </div>
                                                    ) : (
                                                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleBarCharts}>Sin datos</div>
                                                    )
                                                    }
                                                </>
                                            );
                                        })}
                                    </div>
                                </>
                            );
                        })}
                </div>
            </div>
        </>
    );
};
