import React from "react";
import Confetti from 'react-confetti'

export default ({ screenSize, darkModeState, handleDarkMode, language, confettiBoolean, handleNewPerson, personSelected }) => {
    const styles = {
        backColor: {
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            height: "100%",
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`
        },
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 800 ? ("3% 2% 3% 20%") : ("30px")}`,
            // padding: `${screenSize > 800 ? ("3% 2% 3% 280px") : ("30px")}`,
            padding: `${screenSize > 800 ? ("3% 2%") : ("30px")}`,
            height: "100%",
            overflow: `auto`,
        },
        containerLoader: {
            display: "flex",
            alignItem: 'center',
            justifyContent: 'center',
            height: "80%",
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px")}`,
            marginBottom: `${screenSize > 800 ? ("0px") : ("30px")}`,
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
        },
        titleNavbar: {
            marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
        },
        toogleNavbar: {
            display: "flex",
            flex: 4,
            alignItems: "center",
            justifyContent: `${screenSize > 800 ? ("flex-end") : ("center")}`,
            flexDirection: `${screenSize > 800 ? ("row") : ("column")}`,
        },
        textToogle: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: 'inherit',
            border: 'none',
            outline: 'none',
            padding: '12px',
            backgroundColor: `transparent`
        },
        textUser: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: 'inherit',
            border: 'none',
            outline: 'none',
            padding: 0,
            backgroundColor: `transparent`
        },
        buttonNew: {
            textDecoration: "none",
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: 'none',
            margin: "20px",
            // margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
            width: `${screenSize > 800 ? ('180px') : (<></>)}`,
        },
        settings: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
            alignItems: 'center',
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px")}`,
        },
        singleSetting: {
            display: "flex",
            //   width: `${screenSize > 800 ? ("95%") : ("80vw")}`,
            width: `${screenSize > 800 ? ("100%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: 50,
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 800 ? ('1.4% 0px -10px') : ("10px")}`,
        },
        lineDetail: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        nameSetting: {
            display: "flex",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: "15px 20px",
            fontSize: 80,
            fontFamily: "InterBold",
            textAlign: 'center'
        },
        valueSetting: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: "15px 20px",
            fontSize: 25,
            fontFamily: "InterBold",
            textAlign: 'center'
        },
        loader: {
            display: 'flex',
            alignSelf: 'center'
        },
        logoContainer: {
            display: "flex",
            flex: '1',
            alignItems: "center",
            justifyContent: 'center',
            border: 'none',
            padding: 0,
            outline: 0,
            backgroundColor: 'transparent',
            //    backgroundColor: 'red'
            //   padding: "20px 0px"
        },
        singlelogo: {
            margin: 40
        },
        singlelogo2: {
            margin: "40px",
            paddingTop: "10px"
        },
        buttonNavbarBlue: {
            backgroundColor: '#4DC2F1',
            borderRadius: 50,
            border: 'none',
            padding: "20px 80px",
            // height: '45px',
            // width: '130px',
            outline: 0,
            margin: "80px 10px",
            alignSelf: "center"
        },
        textButtonWhite: {
            margin: 0,
            fontSize: 25,
            color: '#fff',
        },
        buttonFloat: {
            position: "absolute",
            bottom: 15,
            right: -150,
            display: "flex",
            justifyContent: "flex-end",
            height: '50vh'
        },
    };

    return (
        <>
            <div style={styles.backColor} />
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <div style={styles.logoContainer}>
                            <img
                                src="https://i.postimg.cc/R0RvH3TJ/insideone-isologo.png"
                                height={screenSize > 800 ? ("70px") : ("40px")}
                                alt="Logo of Inside One"
                                style={styles.singlelogo}
                            />
                            <img
                                src="https://i.postimg.cc/44M5sjgb/CHILE-PRESENCIAL-2024-HZ.png"
                                height={screenSize > 800 ? ("70px") : ("40px")}
                                alt="Logo of Inside One"
                                style={styles.singlelogo}
                            />
                            <img
                                src="https://i.postimg.cc/13ZPpMGt/logo-avaya-color.png"
                                height={screenSize > 800 ? ("85px") : ("40px")}
                                alt="Logo of Inside One"
                                style={styles.singlelogo2}
                            />

                        </div>
                    </div>
                </div>
                <div style={styles.settings}>
                    <button className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleSetting} onClick={handleDarkMode}>
                        <div style={styles.valueSetting}>{language === 'english' ? ('Nombre del ganador') : ('Nombre del ganador')}</div>
                        <div style={styles.nameSetting}>{personSelected ? personSelected : "-"}</div>
                    </button>
                    <button style={styles.buttonNavbarBlue} onClick={() => handleNewPerson()}><h3 style={styles.textButtonWhite}>Participar</h3></button>
                    {confettiBoolean ? (
                        <Confetti numberOfPieces={200} gravity={0.2} recycle={false} width={window.screen.width} height={window.screen.height} />
                    ) : (<></>)}
                    <img
                        style={styles.buttonFloat}
                        src="https://i.postimg.cc/SRjc48Cv/Eric-Apuntando-Alpha-baja.gif"
                        alt="Bot of Inside One"
                    />
                </div>
            </div>
        </>
    );
};
