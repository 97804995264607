import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export default ({ screenSize, darkModeState, handlePopUpAnswers, popUpInformationAnswers, language }) => {
    const styles = {
        shadowContainer: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            backgroundColor: '#000',
            opacity: '0.5',
        },
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 900,
        },
        chartContainer: {
            display: "flex",
            flexDirection: 'column',
            width: `${screenSize > 800 ? ("50vw") : ("90%")}`,
            //     height: `${screenSize > 800 ? ("70%") : ("90%")}`,
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ('hsl(0, 0%, 100%)')}`,
            alignItems: "center",
            justifyContent: "center",
            borderRadius: '18px',
            zIndex: 900,
        },
        chartTop: {
            display: "flex",
            width: "100%",
            height: "100%",
            flex: 12,
            flexDirection: 'column',
            backgroundColor: `${darkModeState ? ("hsl(232, 19%, 15%)") : ('hsl(0, 0%, 100%)')}`,
            alignItems: "center",
            padding: `${screenSize > 800 ? ("20px") : ("30px 30px 20px 30px")}`,
            borderRadius: '18px',
        },
        chartClose: {
            display: "flex",
            alignSelf: "flex-end",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ('hsl(228, 12%, 44%)')}`,
            border: 'none',
            backgroundColor: `transparent`,
            textDecoration: "none",
            outline: 'none',
            padding: '6px'
        },
        image: {
            height: '100px',
            margin: '20px'
        },
        popUpTitle: {
            display: "flex",
            margin: 0,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ('hsl(230, 17%, 14%)')}`,
            fontFamily: "InterBold",
            margin: "20px 0px 10px",
        },
        popUpSubtitle: {
            display: "flex",
            fontSize: "18px",
            fontFamily: "InterRegular",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ('hsl(230, 17%, 14%)')}`,
            margin: "0px 0px 20px",
        },
        popUpInformationGroup: {
            display: "flex",
            alignSelf: 'center',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            margin: "10px 0px 20px",
        },
        popUpInformation: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: "18px",
            fontFamily: "InterRegular",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ('hsl(230, 17%, 14%)')}`,
            margin: "10px 0px",
        },
        icon: {
            height: '15px',
            width: '15px',
            margin: '0px 20px 0px 0px'
        },
        popUpQuestions: {
            display: "flex",
            flexDirection: 'column',
            width: '100%',
            //     alignItems: 'center',
            //     justifyContent: 'center',
            margin: "10px 0px 20px",
            padding: '0px 10%',
        },
        popUpSingleQuestion: {
            display: "flex",
            flexDirection: 'row',
            // alignItems: 'space-between',
            justifyContent: 'space-between',
            margin: "10px 0px",
        },
        popUpQuestion: {
            display: "flex",
            //  flex: 1,
            fontSize: "18px",
            fontFamily: "InterRegular",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ('hsl(230, 17%, 14%)')}`,
            textAlign: 'start'
            //  margin: "0px 60px",
        },
        popUpAnswer: {
            display: "flex",
            //    flex: 1,
            fontSize: "18px",
            fontFamily: "InterBold",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ('hsl(230, 17%, 14%)')}`,
            textAlign: 'end'
            //     margin: "0px 60px",
        },
        popUpDate: {
            display: "flex",
            fontSize: "14px",
            fontFamily: "InterRegular",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ('hsl(230, 17%, 14%)')}`,
            margin: "20px 0px 30px",
        },
        chartIndicators: {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: 'center',
            //     margin: "30px",
            flexDirection: 'row'
        },
        buttonCancel: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: `${darkModeState ? ("hsl(228, 28%, 20%)") : ("hsl(228, 12%, 44%)")}`,
            color: '#fff',
            border: 'none',
            borderRadius: 25,
            margin: '20px 8px 45px',
            padding: '12px 32px',
            outline: 0,
            width: '136px'
        },
        buttonContinue: {
            //fontFamily: "InterRegular",
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            color: '#fff',
            border: 'none',
            borderRadius: 25,
            margin: '20px 8px 45px',
            padding: '12px 32px',
            outline: 0,
            width: '136px'
        },
        collabButton: {
            display: "flex",
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "center",
            textDecoration: "none",
            outline: 0,
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: 'none',
            borderRadius: 5,
            margin: "20px 0px 0px",
            padding: '8px 13px',
            width: '100%'
        },
    }
    return (
        <>
            <div style={styles.shadowContainer} />
            <div style={styles.container}>
                <div style={styles.chartContainer}>
                    <div style={styles.chartTop}>
                        <button style={styles.chartClose} onClick={handlePopUpAnswers}>
                            <svg width="25" height="25" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </button>
                        {popUpInformationAnswers.nestedAnswers[0] <= 2 || popUpInformationAnswers.nestedAnswers[1] == "No" || popUpInformationAnswers.nestedAnswers[2] <= 2 ? <img style={styles.image} src="https://i.postimg.cc/V6v41Bz8/sorprendido.png" alt="Eric from Inside One" /> : <img style={styles.image} src="https://i.postimg.cc/vHJKzjb6/eric-feliz.png" alt="Eric from Inside One" />}
                        {popUpInformationAnswers ? (
                            <>
                                <div style={styles.popUpTitle}>{popUpInformationAnswers.service}</div>
                                <div style={styles.popUpSubtitle}>{`${popUpInformationAnswers.name} - ${popUpInformationAnswers.channel == "audio" ? ("Audio") : ("WhatsApp")}`}</div>
                                <div style={styles.popUpInformationGroup}>
                                    <div style={styles.popUpInformation}>
                                        {popUpInformationAnswers.channel == "audio" ? (<svg style={styles.icon} fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                                            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                        </svg>) : (<svg style={styles.icon} fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                                            <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                                        </svg>)}
                                        {popUpInformationAnswers.phoneNumber}
                                    </div>
                                    <div style={styles.popUpInformation}><svg style={styles.icon} fill="currentColor" class="bi bi-person-vcard" viewBox="0 0 16 16">
                                        <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5ZM9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8Zm1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5Z" />
                                        <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2ZM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96c.026-.163.04-.33.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1.006 1.006 0 0 1 1 12V4Z" />
                                    </svg>{popUpInformationAnswers.dni}</div>
                                    <div style={styles.popUpInformation}><svg style={styles.icon} fill="currentColor" class="bi bi-headset" viewBox="0 0 16 16">
                                        <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5z" />
                                    </svg>{popUpInformationAnswers.agentName}</div>
                                    {popUpInformationAnswers.sessionNumber != "--" ? (
                                        <>
                                            <button style={styles.collabButton} onClick={() => (window.open(`https://system.insideone.net/portal/interactionshistory/inboundInteractionHistory/${popUpInformationAnswers.sessionNumber}`))}>{language === "english" ? "View on Collab" : "Ver en Collab"}</button>
                                        </>
                                    ) : (<></>)}
                                </div>
                                <div style={styles.popUpQuestions}>
                                    {popUpInformationAnswers.questions ? (
                                        popUpInformationAnswers.questions.map((ele, index) => {
                                            return <div style={styles.popUpSingleQuestion}>
                                                <div style={styles.popUpQuestion}>{ele}</div>
                                                <div style={styles.popUpAnswer}>{popUpInformationAnswers.nestedAnswers[index]}</div>
                                            </div>
                                        })
                                    ) : (<></>)}
                                </div>
                                <div style={styles.popUpDate}>{popUpInformationAnswers.date} {popUpInformationAnswers.time}</div>
                            </>
                        ) : (<></>)}
                    </div>
                </div>
            </div>
        </>
    );
};
