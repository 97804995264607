import React from "react";

import {
    Chart as ChartJS,
    CategoryScale,
    RadialLinearScale,
    LinearScale,
    PointElement,
    BarElement,
    ArcElement,
    LineElement,
    Filler,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Bar, Doughnut } from 'react-chartjs-2';

export default ({ screenSize, darkModeState, language, chartsBBVA }) => {
    //creamos un array vacio donde revisamos cuales son las interacciones que tienen menos de un mes y una semana
    let unMes = [];
    let unaSemana = [];
    let getTimeStamp = (new Date().getTime() / 1000) - (3600 * 3);

    chartsBBVA.forEach(ele => {
        if ((getTimeStamp - ele.timestamp) <= 604800 && (getTimeStamp - ele.timestamp) <= 2629743) {
            unMes.push(ele);
            unaSemana.push(ele);
        } else if ((getTimeStamp - ele.timestamp) <= 604800) {
            unaSemana.push(ele);
        } else if ((getTimeStamp - ele.timestamp) <= 2629743) {
            unMes.push(ele);
        }
    });


    let encuentasSemanales = [...new Set(unaSemana.map(ele => ele.identifier))].length
    let encuentasMensuales = [...new Set(unMes.map(ele => ele.identifier))].length

    //Cuan probable es que nos recomiende a otros conocidos o familiares
    let surveys1Semanal = [];
    let surveys1Semanales = [
        { valor: 0 },
        { valor: 0 },
        { valor: 0 },
        { valor: 0 },
        { valor: 0 }
    ];

    //¿Considera que pudo resolver su consulta en este llamado?
    let surveys2Semanal = [];
    let surveys2Semanales = [
        { valor: 0 },
        { valor: 0 }
    ];
    //¿Cuando probable es que nos recomiende a otros amigos, conocidos o familiares?.
    let surveys3Semanal = [];
    let survey3Semanales = [{
        valorsurveys3Semanal: 0
    }, {
        valorsurveys3Semanal: 0
    }, {
        valorsurveys3Semanal: 0
    }, {
        valorsurveys3Semanal: 0
    }, {
        valorsurveys3Semanal: 0
    }];

    //Encuestas Mensuales.
    let surveys1Mensual = [];
    let surveys1Mensuales = [
        { valor: 0 },
        { valor: 0 },
        { valor: 0 },
        { valor: 0 },
        { valor: 0 },
    ];

    //¿Considera que pudo resolver su consulta en este llamado?
    let surveys2Mensual = [];
    let surveys2Mensuales = [{
        valor: 0
    }, {
        valor: 0
    }];


    let surveys3Mensual = []
    let surveys3Mensuales = [
        { valor: 0 },
        { valor: 0 },
        { valor: 0 },
        { valor: 0 },
        { valor: 0 },
    ];

    //Encuestas Mensuales WhatsApp
    let surveys1MensualWhatsApp = [];
    let surveys1MensualesWhatsApp = [
        { valor: 0 },
        { valor: 0 }
    ];

    let surveys2MensualWhatsApp = [];
    let surveys2MensualesWhatsApp = [
        { valor: 0 },
        { valor: 0 },
        { valor: 0 },
        { valor: 0 },
        { valor: 0 },
    ];


    unaSemana.forEach(ele => {
        if (ele.id_survey == "1") {
            if (ele.id_question == "1") {
                surveys1Semanal.push({
                    id_question: "¿Cuán probable es que nos recomiende a otros amigos, conocidos o familiares?",
                    value_answers: ele.value_answer
                })
            }
            else if (ele.id_question == "2") {
                surveys2Semanal.push({
                    id_question: "¿Considera que pudo resolver su consulta en este llamado?",
                    value_answers: ele.value_answer
                })
            }
            else if (ele.id_question == "3") {
                surveys3Semanal.push({
                    id_question: "¿Cómo calificaría la atención recibida en esta comunicación?",
                    value_answers: ele.value_answer
                })
            }

        } else {
            console.log("OTRA ENCUESTA")
        }
    });

    unMes.forEach(ele => {
        if (ele.id_survey == "1") {
            if (ele.id_question == "1") {
                surveys1Mensual.push({
                    id_question: "¿Cuán probable es que nos recomiende a otros amigos, conocidos o familiares?",
                    value_answers: ele.value_answer
                })
            } else if (ele.id_question == "2") {
                surveys2Mensual.push({
                    id_question: "¿Considera que pudo resolver su consulta en este llamado?",
                    value_answers: ele.value_answer
                })
            } else if (ele.id_question == "3") {
                surveys3Mensual.push({
                    id_question: "¿Cómo calificaría la atención recibida en esta comunicación?",
                    value_answers: ele.value_answer
                })
            }

        } else if (ele.id_survey == "2") {
            if (ele.id_question == "1") {
                surveys1MensualWhatsApp.push({
                    id_question: "¿Pudiste resolver tu consulta?",
                    value_answers: ele.value_answer
                })
            } else if (ele.id_question == "2") {
                surveys2MensualWhatsApp.push({
                    id_question: "¿Cuán satisfecho estás con la respuestas otorgada?",
                    value_answers: ele.value_answer
                })
            }
        } else {
            // console.log("OTRA ENCUESTA")
        }
    });

    // let cantidadDeEncuestasSemanal = surveys1Semanal.length + surveys2Semanal.length + surveys3Semanal.length;
    // let cantidadDeEncuestasMensual = surveys1Mensual.length + surveys2Mensual.length + surveys3Mensual.length;

    //calculo de valoraciones semanales ----------------------------
    surveys1Semanal.forEach(ele => {
        if (ele.value_answers == 1) { surveys1Semanales[0].valor += 1 }
        else if (ele.value_answers == 2) { surveys1Semanales[1].valor += 1 }
        else if (ele.value_answers == 3) { surveys1Semanales[2].valor += 1 }
        else if (ele.value_answers == 4) { surveys1Semanales[3].valor += 1 }
        else if (ele.value_answers == 5) { surveys1Semanales[4].valor += 1 }
    });

    surveys2Semanal.forEach(ele => {
        if (ele.value_answers == 1) { surveys2Semanales[0].valor += 1 }
        else { surveys2Semanales[1].valor += 1 }
    });

    surveys3Semanal.forEach(ele => {
        if (ele.value_answers == 1) { survey3Semanales[0].valorsurveys3Semanal += 1 }
        else if (ele.value_answers == 2) { survey3Semanales[1].valorsurveys3Semanal += 1 }
        else if (ele.value_answers == 3) { survey3Semanales[2].valorsurveys3Semanal += 1 }
        else if (ele.value_answers == 4) { survey3Semanales[3].valorsurveys3Semanal += 1 }
        else if (ele.value_answers == 5) { survey3Semanales[4].valorsurveys3Semanal += 1 }
    });
    //-----------------------------------------------------------------------

    //calculo de valoraciones mensuales 
    surveys1Mensual.forEach(ele => {
        if (ele.value_answers == 1) { surveys1Mensuales[0].valor += 1 }
        else if (ele.value_answers == 2) { surveys1Mensuales[1].valor += 1 }
        else if (ele.value_answers == 3) { surveys1Mensuales[2].valor += 1 }
        else if (ele.value_answers == 4) { surveys1Mensuales[3].valor += 1 }
        else if (ele.value_answers == 5) { surveys1Mensuales[4].valor += 1 }
    });

    surveys2Mensual.forEach(ele => {
        if (ele.value_answers == "1") { surveys2Mensuales[0].valor += 1 }
        else { surveys2Mensuales[1].valor += 1 }
    });

    surveys3Mensual.forEach(ele => {
        if (ele.value_answers == "1") { surveys3Mensuales[0].valor += 1 }
        else if (ele.value_answers == "2") { surveys3Mensuales[1].valor += 1 }
        else if (ele.value_answers == "3") { surveys3Mensuales[2].valor += 1 }
        else if (ele.value_answers == "4") { surveys3Mensuales[3].valor += 1 }
        else if (ele.value_answers == "5") { surveys3Mensuales[4].valor += 1 }
    });


    //calculo de valoraciones mensuales WhatsApp
    surveys1MensualWhatsApp.forEach(ele => {
        if (ele.value_answers == 1) { surveys1MensualesWhatsApp[0].valor += 1 }
        else if (ele.value_answers == 2) { surveys1MensualesWhatsApp[1].valor += 1 }
    });

    surveys2MensualWhatsApp.forEach(ele => {
        if (ele.value_answers == "1") { surveys2MensualesWhatsApp[0].valor += 1 }
        else if (ele.value_answers == 2) { surveys2MensualesWhatsApp[1].valor += 1 }
        else if (ele.value_answers == 3) { surveys2MensualesWhatsApp[2].valor += 1 }
        else if (ele.value_answers == 4) { surveys2MensualesWhatsApp[3].valor += 1 }
        else if (ele.value_answers == 5) { surveys2MensualesWhatsApp[4].valor += 1 }
        else if (ele.value_answers == 6) { surveys2MensualesWhatsApp[4].valor += 1 }
        else if (ele.value_answers == 7) { surveys2MensualesWhatsApp[4].valor += 1 }
        else if (ele.value_answers == 8) { surveys2MensualesWhatsApp[4].valor += 1 }
        else if (ele.value_answers == 9) { surveys2MensualesWhatsApp[4].valor += 1 }
        else if (ele.value_answers == 10) { surveys2MensualesWhatsApp[4].valor += 1 }
    });

    //-------------------------------------------
    //GRAFICOS SEMANALES

    const options1Semanal = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: '¿Cuán probable es que nos recomiende a otros amigos, conocidos o familiares? (7 días)',
            },
        },
    };
    const data1Semanal = {
        labels: ["1", "2", "3", "4", "5"],
        datasets: [
            {
                label: '¿Cuán probable es que nos recomiende a otros amigos, conocidos o familiares?',
                data: surveys1Semanales.length ? surveys1Semanales.map(ele => ele.valor) : [],
                backgroundColor: [
                    'rgba(255, 99, 132)',
                    'rgba(153, 102, 255)',
                    'rgba(54, 162, 235)',
                    'rgba(255, 206, 86)',
                    'rgba(75, 192, 192)',
                ],
                hoverOffset: 5,
            },
        ],
    };
    //-------------------------------------------
    const options2Semanal = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: '¿Considera que pudo resolver su consulta en este llamado? (7 días)',
            },
        },
    };
    const data2Semanal = {
        labels: ["Sí", "No"],
        datasets: [
            {
                label: '¿Considera que pudo resolver su consulta en este llamado?',
                data: surveys2Semanales.length ? surveys2Semanales.map(ele => ele.valor) : [],
                backgroundColor: [
                    'rgba(75, 192, 192)',
                    'rgba(255, 99, 132)'
                ],
                hoverOffset: 5,
            },
        ],
    };
    //-------------------------------------------
    const options3Semanal = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: '¿Cómo calificaría la atención recibida en esta comunicación? (7 días)',
            },
        },
    };
    const data3Semanal = {
        labels: ["1", "2", "3", "4", "5"],
        datasets: [
            {
                label: '¿Cómo calificaría la atención recibida en esta comunicación?',
                data: survey3Semanales.length ? survey3Semanales.map(ele => ele.valorsurveys3Semanal) : [],
                backgroundColor: [
                    'rgba(255, 99, 132)',
                    'rgba(153, 102, 255)',
                    'rgba(54, 162, 235)',
                    'rgba(255, 206, 86)',
                    'rgba(75, 192, 192)',
                ],
                hoverOffset: 5,
            },
        ],
    };
    //GRAFICOS MENSUALES 
    //¿Cuan probable es que nos recomiendo a otros amigos
    const options1Mensual = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: '¿Cuán probable es que nos recomiende a otros amigos, conocidos o familiares?',
            },
        },
    };

    const data1Mensual = {
        labels: ["1", "2", "3", "4", "5"],
        datasets: [
            {
                label: `${language == "english" ? "Total quantity (30 days)" : "Cantidad total (30 días)"}`,
                data: surveys1Mensuales.length ? surveys1Mensuales.map(ele => ele.valor) : [],
                backgroundColor: [
                    'rgba(255, 99, 132)',
                    'rgba(153, 102, 255)',
                    'rgba(54, 162, 235)',
                    'rgba(255, 206, 86)',
                    'rgba(75, 192, 192)',
                ],
                hoverOffset: 5,
            },

        ],
    };
    //--------------------------------
    //¿Pudo resolver su consulta en este llamado
    const options2Mensual = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: '¿Considera que pudo resolver su consulta en este llamado?',
            },
        },
    };
    const data2Mensual = {
        labels: ["Sí", "No"],
        datasets: [
            {
                label: `${language == "english" ? "Total quantity (30 days)" : "Cantidad total (30 días)"}`,
                data: surveys2Mensuales.length ? surveys2Mensuales.map(ele => ele.valor) : [],
                backgroundColor: [
                    'rgba(75, 192, 192)',
                    'rgba(255, 99, 132)'
                ],
                hoverOffset: 5,
            },

        ],
    };
    const options3Mensual = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: '¿Cómo calificaría la atención recibida en esta comunicación?',
            },
        },
    };
    //----------------------------------------
    //¿Como calificaria la atención recibida?.
    const data3Mensual = {
        labels: ["1", "2", "3", "4", "5"],
        datasets: [
            {
                label: `${language == "english" ? "Total quantity (30 days)" : "Cantidad total (30 días)"}`,
                data: surveys3Mensuales.length ? surveys3Mensuales.map(ele => ele.valor) : [],
                backgroundColor: [
                    'rgba(255, 99, 132)',
                    'rgba(153, 102, 255)',
                    'rgba(54, 162, 235)',
                    'rgba(255, 206, 86)',
                    'rgba(75, 192, 192)',
                ],
                hoverOffset: 5,
            },

        ],
    };



    const options1MensualWhatsApp = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: '¿Pudiste resolver tu consulta? (7 días)',
            },
        },
    };
    //----------------------------------------
    //¿Pudiste resolver tu consulta?
    const data1MensualWhatsApp = {
        labels: ["Sí", "No"],
        datasets: [
            {
                label: `${language == "english" ? "Total quantity (30 days)" : "Cantidad total (30 días)"}`,
                data: surveys1MensualesWhatsApp.length ? surveys1MensualesWhatsApp.map(ele => ele.valor) : [],
                backgroundColor: [
                    'rgba(75, 192, 192)',
                    'rgba(255, 99, 132)'
                ],
                hoverOffset: 5,
            },

        ],
    };

    const options2MensualWhatsApp = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: '¿Cuán satisfecho estás con la respuestas otorgada? (7 días)',
            },
        },
    };
    //----------------------------------------
    //¿Cuán satisfecho estás con la respuestas otorgada?
    const data2MensualWhatsApp = {
        labels: ["1", "2", "3", "4", "5"],
        datasets: [
            {
                label: `${language == "english" ? "Total quantity (30 days)" : "Cantidad total (30 días)"}`,
                data: surveys2MensualesWhatsApp.length ? surveys2MensualesWhatsApp.map(ele => ele.valor) : [],
                backgroundColor: [
                    'rgba(255, 99, 132)',
                    'rgba(153, 102, 255)',
                    'rgba(54, 162, 235)',
                    'rgba(255, 206, 86)',
                    'rgba(75, 192, 192)',
                ],
                hoverOffset: 5,
            },

        ],
    };


    // const data3Semanal = {
    //     labels: ["1", "2", "3", "4", "5"],
    //     datasets: [
    //         {
    //             label: '¿Cómo calificaría la atención recibida en esta comunicación?',
    //             data: survey3Semanales.length ? survey3Semanales.map(ele => ele.valorsurveys3Semanal) : [],
    //             backgroundColor: [
    //                 'rgba(255, 99, 132)',
    //                 'rgba(54, 162, 235)',
    //                 'rgba(255, 206, 86)',
    //                 'rgba(75, 192, 192)',
    //                 'rgba(153, 102, 255)',
    //             ],
    //             hoverOffset: 5,
    //         },
    //     ],
    // };





    ChartJS.register(CategoryScale, LinearScale, RadialLinearScale, PointElement, BarElement, PointElement, ArcElement, LineElement, Filler, Title, Tooltip, Legend);

    const styles = {
        backColor: {
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            height: "100%",
            backgroundColor: `${darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"}`,
        },
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 800 ? ("3% 2% 3% 20%") : ("30px")}`,
            padding: `${screenSize > 800 ? "3% 2% 0px 280px" : "30px"}`,
            height: "100%",
            //   overflow: `auto`,
        },
        containerLoader: {
            display: "flex",
            alignItem: "center",
            justifyContent: "center",
            height: "80%",
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 800 ? "0px 20px" : "0px"}`,
            marginBottom: `${screenSize > 800 ? "0px" : "30px"}`,
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
        },
        titleNavbar: {
            marginBottom: `${screenSize > 800 ? "5px" : "10px"}`,
            fontSize: "28px",
            color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
            fontFamily: "InterBold",
            lineHeight: "1.4",
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            fontFamily: "InterBold",
        },
        subtitleSurveys: {
            margin: "20px 20px 10px",
            fontSize: "20px",
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            fontFamily: "InterBold",
        },
        toogleNavbar: {
            display: "flex",
            flex: 4,
            alignItems: "center",
            justifyContent: `${screenSize > 800 ? "flex-end" : "center"}`,
            flexDirection: `${screenSize > 800 ? "row" : "column"}`,
        },
        textToogle: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: "inherit",
            border: "none",
            outline: "none",
            padding: "12px",
            backgroundColor: `transparent`,
        },
        textUser: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: "inherit",
            border: "none",
            outline: "none",
            padding: 0,
            backgroundColor: `transparent`,
        },
        buttonNew: {
            textDecoration: "none",
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: "none",
            margin: "20px",
            // margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
            width: `${screenSize > 800 ? "180px" : <></>}`,
        },
        reports: {
            display: "flex",
            flex: 11,
            flexDirection: 'column',
            margin: `${screenSize > 800 ? "0px 20px" : "0px"}`,
            overflow: `auto`,
            padding: '0px 0px 50px 0px',
            borderRadius: "10px",
            // backgroundColor: 'red'
        },
        singleLineReport: {
            display: "flex",
            flexDirection: 'row',
        },
        singleDoughnutChart: {
            //       display: "flex",
            flex: 1,
            alignItems: "flex-start",
            justifyContent: "flex-start",
            borderRadius: "10px",
            //  backgroundColor: 'red',
            padding: "25px",
            textDecoration: "none",
            border: "none",
            outline: "none",
            margin: `${screenSize > 800 ? "5px" : "10px"}`,
            width: '100px'
        },
        singleBarCharts: {
            flex: 1,
            alignItems: "flex-start",
            justifyContent: "flex-start",
            borderRadius: "10px",
            //   backgroundColor: 'red',
            padding: "25px",
            textDecoration: "none",
            border: "none",
            outline: "none",
            margin: `${screenSize > 800 ? "5px" : "10px"}`,
            width: '100px',
            //  height: '500px'
        },
        singleIndicator: {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            alignItems: "flex-start",
            justifyContent: "flex-start",
            borderRadius: "10px",
            //    backgroundColor: 'red',
            padding: "18px 25px",
            textDecoration: "none",
            border: "none",
            outline: "none",
            margin: `${screenSize > 800 ? "5px" : "10px"}`,
            //       backgroundColor: 'red'
        },
        singleIndicatorInfo: {
            flex: 2,
            flexDirection: 'column',
            //       backgroundColor: 'green'
        },
        singleIndicatorTitle: {
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            margin: "0px 0px 5px",
            fontSize: "18px",
            fontFamily: "InterBold",
            textAlign: "start",
        },
        singleIndicatorSubtitle: {
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            margin: "0px",
            fontSize: "12px",
            fontFamily: "InterBold",
            textAlign: "start",
        },
        singleIndicatorNumber: {
            flex: 1,
            alignSelf: 'flex-end',
            color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
            margin: "0px",
            fontSize: "40px",
            fontFamily: "InterBold",
            textAlign: "end",
            //     backgroundColor: 'blue'
        },


        //---------------------------------------------------------------


        lineDetail: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        phoneNumber: {
            display: "flex",
            flex: 4,
            color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
            margin: "0px",
            fontSize: "18px",
            fontFamily: "InterBold",
            textAlign: "start",
            alignSelf: "center",
        },
        option: {
            display: "flex",
            flex: 6,
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            margin: 0,
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
        },
        date: {
            display: "flex",
            flex: 2,
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            margin: 0,
            fontSize: "14px",
            fontFamily: "InterBold",
            // textAlign: 'end'
            alignSelf: "center",
            justifyContent: "flex-end",
        },
        loader: {
            display: "flex",
            alignSelf: "center",
        },
        try: {
            position: "absolute",
        },
    };
    return (
        <>
            <div style={styles.backColor} />
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <h3 style={styles.titleNavbar}>{language == "english" ? "Surveys" : "Encuestas"} 😃 </h3>
                        <h3 style={styles.subtitleNavbar}>{language == "english" ? "All surveys charts." : "Todas las métricas de encuestas."}</h3>
                    </div>
                </div>
                <div style={styles.reports}>
                    <div style={styles.singleLineReport}>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleIndicator}>
                            <div style={styles.singleIndicatorInfo}>
                                <div style={styles.singleIndicatorTitle}>{language == "english" ? "New surveys" : "Nuevas encuestas"}</div>
                                <div style={styles.singleIndicatorSubtitle}>{language == "english" ? "Last 30 days" : "Últimos 30 días"}</div>
                            </div>
                            <div style={styles.singleIndicatorNumber}>{encuentasMensuales}</div>
                        </div>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleIndicator}>
                            <div style={styles.singleIndicatorInfo}>
                                <div style={styles.singleIndicatorTitle}>{language == "english" ? "New surveys" : "Nuevas encuestas"}</div>
                                <div style={styles.singleIndicatorSubtitle}>{language == "english" ? "Last 7 days" : "Últimos 7 días"}</div>
                            </div>
                            <div style={styles.singleIndicatorNumber}>{encuentasSemanales}</div>
                        </div>
                    </div>
                    <div style={styles.singleLineReport}>
                        <h3 style={styles.subtitleSurveys}>Telefonía</h3>
                    </div>
                    <div style={styles.singleLineReport}>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleDoughnutChart}><Doughnut options={options1Semanal} data={data1Semanal} /> </div>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleDoughnutChart}><Doughnut options={options2Semanal} data={data2Semanal} /></div>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleDoughnutChart}><Doughnut options={options3Semanal} data={data3Semanal} /></div>
                    </div>
                    <div style={styles.singleLineReport}>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleBarCharts}><Bar options={options1Mensual} data={data1Mensual} /></div>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleBarCharts}><Bar options={options2Mensual} data={data2Mensual} /></div>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleBarCharts}><Bar options={options3Mensual} data={data3Mensual} /></div>
                    </div>
                    <div style={styles.singleLineReport}>
                        <h3 style={styles.subtitleSurveys}>WhatsApp</h3>
                    </div>
                    <div style={styles.singleLineReport}>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleBarCharts}><Doughnut options={options1MensualWhatsApp} data={data1MensualWhatsApp} /></div>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleBarCharts}><Doughnut options={options2MensualWhatsApp} data={data2MensualWhatsApp} /></div>
                        {/* <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleDoughnutChart}><Doughnut options={options2MensualWhatsApp} data={data2MensualWhatsApp} /></div> */}
                    </div>
                </div>
            </div>

        </>
    );
};


