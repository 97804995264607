import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";

import AuthService from "./services/auth.service";


import OneView from "./containers/OneView";
import EventsIO from "./containers/Events";

import DemoBBVADialogFlow from "./containers/DemoBBVADialogFlow";
import Onboarding from "./containers/Onboarding";
import Login from "./containers/Login";
import Today from "./containers/Today";
import Analytics from "./containers/Analytics";
import SingleUser from "./containers/SingleUser";
import Campaigns from "./containers/Campaigns";
import NewCampaigns from "./containers/create/Campaigns";
import NewMessage from "./containers/create/Message";

//X28
import ReportsX28 from "./containers/ReportsX28";
import ChartsX28 from "./containers/ChartsX28";

//Collab
import ReportsCollab from "./containers/ReportsCollab";

//BBVA Seguros
import ChartsBBVA from "./containers/ChartsBbva";
import ChartsWhatsAppBBVA from "./containers/ChartsWhatsAppBbva";
import ReportsBBVA from "./containers/ReportsBBVA";
import SurveysBBVA from "./containers/SurveysBBVA";
import SurveyAnalytics from "./containers/SurveyAnalytics";
import SurveyAnswersUnified from "./containers/SurveyAnswersUnified";
import SurveyAnswers from "./containers/SurveyAnswers";

//Galicia Seguros
import ReportsGalicia from "./containers/ReportsGalicia";
import ChartGalicia from "./containers/ChartsGalicia";
import RespuestasUnificadasGalicia from "./containers/RespuestasUnificadasGalicia";
import NewReportsGalicia from "./containers/NewReportsGalicia";



import SurveysGeneral from "./containers/SurveysGeneral";
import EditSurvey from "./containers/EditSurvey";
import CreateSurvey from "./containers/create/Survey";
import Profile from "./containers/Profile";
import Settings from "./containers/Settings";




const Main = () => {
  const isAuth = useSelector(state => state.user.isAuth)
  const loginUser = useSelector(state => state.user.loginUser)

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      //   setCurrentUser(user);
    }
  }, []);

  const connStatus = () => {
    AuthService.connStatus();
  }

  // OJO que sin los [] es bucle infinito
  useEffect(() => { connStatus(); }, []);

  return (
    <>
      {isAuth && isAuth === true ? (
        <Switch>
          <Route exact path="/" component={Login} />

          {/* X28 */}
          <Route exact path="/charts/x28" component={ChartsX28} />
          <Route exact path="/reports/x28" component={ReportsX28} />
          {/* BBVA SEGUROS REPORTES WTP*/}
          <Route exact path="/charts/bbva-seguros" component={ChartsBBVA} />
          <Route exact path="/charts/bbva-seguros/whatsapp" component={ChartsWhatsAppBBVA} />
          <Route exact path="/reports/bbva-seguros" component={ReportsBBVA} />






          {/* GALICIA SEGUROS */}
          {/* <Route exact path="/charts/galicia-seguros" component={ChartGalicia} />/ */}
          {/* <Route exact path="/answers/galicia-seguros" component={RespuestasUnificadasGalicia} /> */}
          {/* <Route exact path="/answers/galicia-seguros" component={SurveyAnswersUnified} /> */}

          {/* REVISAR ESTO SI HAY ALGO IMPORTANTE */}
          <Route exact path="/reports/galicia-seguros" component={ReportsGalicia} />
          <Route exact path="/newreports/galicia-seguros" component={NewReportsGalicia} />

          {/* BBVA SEGUROS ENCUESTAS*/}
          <Route exact path="/surveys/bbva-seguros" component={SurveysBBVA} />
          {/* ENCUESTAS GENERALES*/}
          <Route exact path="/analytics" component={SurveyAnalytics} />
          <Route exact path="/unified-answers" component={SurveyAnswersUnified} />
          <Route exact path="/answers" component={SurveyAnswers} />









          <Route exact path="/surveys" component={SurveysGeneral} />
          <Route exact path="/surveys/create" component={CreateSurvey} />
          <Route exact path="/surveys/:idSurvey" component={EditSurvey} />
          {/* INSIDEONE DEMO */}
          <Route exact path="/licenses" component={ReportsCollab} />
          <Route exact path="/charts" component={ChartsWhatsAppBBVA} />
          <Route exact path="/reports" component={ReportsBBVA} />
          {/* GENERAL */}
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/settings" component={Settings} />
        </Switch>
      ) : (
        <Switch>
          <Route exact path="/events/:idEvent" component={EventsIO} />
          <Route exact path="/oneview/:idInteraction" component={OneView} />
          <Route path="/login" component={Login} />
          <Route path="/" component={Login} />
        </Switch>
      )}
    </>
  )
}

export default Main;