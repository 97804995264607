import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginUserPreferences } from "../store/actions/loginAction";
import ChartsX28 from "../components/ChartsX28";

import Sidebar from "./Sidebar";
import firebase from '../config/firebaseIO';
const db = firebase.firestore()

const ChartClients = () => {

    const [screenSize, setScreenSize] = useState(window.innerWidth);

    const [charts3, setCharts3] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [displayCharts, setDisplayCharts] = useState(false);



    const dispatch = useDispatch()
    const loginUser = useSelector(state => state.user.loginUser)
    const language = useSelector(state => state.user.language)
    const darkTheme = useSelector(state => state.user.darkTheme)

    const getOlcharts = async (beginin, end) => {
        let olcharts = []
        let data = await db.collection("companies")
            .doc("VF0rMotbgtFU3FapbUo6")
            .collection("reports")
            .where('timestamp', '>', beginin)
            .where('timestamp', '<', end + 86400000)
            .orderBy('timestamp', "desc")
            .get();

        data.docs.forEach((ele) => olcharts.push(ele.data()))

        setCharts3(olcharts);
    }




    useEffect(() => {

    }, []);


    useEffect(() => {
        if (startDate && endDate) {
            getOlcharts(startDate, endDate)

        } else {

            window.scrollTo(0, 0);
            let oneMonth = new Date().getTime() - 2629743000;
            db.collection("companies").doc("VF0rMotbgtFU3FapbUo6")
                .collection("reports")
                .where('timestamp', '>', oneMonth)
                .orderBy('timestamp', "desc")
                //  .limit(100)
                .onSnapshot((ele) => {
                    let estado = [];
                    ele.forEach(elem => {
                        estado.push(elem.data())
                    })
                    setCharts3(estado)
                });
        }




    }, [startDate, endDate])

    const reset = (date1, date2) => {
        setStartDate("");
        setEndDate("");
        date1.value = ("");
        date2.value = ("");
        setDisplayCharts(false);
    };

    const handleClick = (date1, date2) => {
        if (date1.value != 0 && date2.value != 0) {
            setStartDate(new Date(date1.value).getTime());
            setEndDate(new Date(date2.value).getTime());
            setDisplayCharts(true);
        }
    };



    return (
        <div>
            <Sidebar />
            <ChartsX28
                screenSize={screenSize}
                darkModeState={darkTheme}
                language={language}
                charts3={charts3}
                startDate={startDate}
                endDate={endDate}
                reset={reset}
                handleClick={handleClick}
                displayCharts={displayCharts}
            />
        </div>
    );
}

export default ChartClients;