import React, { useState, useEffect, Child } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginUserPreferences } from "../store/actions/loginAction";
import RespuestasUnificadasGalicia from "../components/RespuestasUnificadasGalicia";

import Sidebar from "./Sidebar";
import firebase from "../config/firebaseIO";

const db = firebase.firestore();

const RespuestasUnificadasGalicia2 = () => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  // const dispatch = useDispatch();

  const [fecha1, setFecha1] = useState("");
  const [fecha2, setFecha2] = useState("");
  const [search, setSearch] = useState("");


  const [surveyAnswers, SetAnswers] = useState([]);

  const [FilterOn, setfilterOn] = useState(false);
  const [displayOn, setDisplayOn] = useState(false);

  const loginUser = useSelector((state) => state.user.loginUser);
  const language = useSelector((state) => state.user.language);
  const darkTheme = useSelector((state) => state.user.darkTheme);

  const [Oldsurveys, SetOldSurveys] = useState([]);
  const [surveyPolls, SetSurveysPolls] = useState([]);

  const [popUpAnswers, setPopUpAnswers] = useState(false);
  const [popUpInformationAnswers, setPopUpInformationAnswers] = useState(false);

  // ESTE FUNCION TRAE LAS ENCUESTAS Y LAS RESPUESTAS EN UNA FECHA DADA


  //------------------------------------------

  let getPolls2 = async (start, end) => {

    if (FilterOn == true) {
      try {

        return new Promise(async (resolve) => {
          let surveys = []
          let old = await db
            .collection("companies")
            .doc("HZZb5MxJI1zVkQJ8MJwk")
            .collection("SURVEY_POLLS")
            .get();

          old.docs.forEach(async (singleSurvey) => {
            await db
              .collection("companies")
              .doc("HZZb5MxJI1zVkQJ8MJwk")
              .collection("SURVEY_POLLS")
              .doc(singleSurvey.id)
              .collection("ANSWERS")
              .where("timestamp", ">=", start)
              .where("timestamp", "<=", (end + 86400000))
              //  .limit(25)
              .get()
              .then((docs) => {
                docs.forEach(ele => surveys.push(ele.data()));
                surveys = surveys.slice();
                resolve(SetOldSurveys(surveys));
              });
          })
        })

      } catch (err) {
        console.log(err)
      }


    } else {
      try {


        let poll = await db
          .collection("companies")
          .doc("HZZb5MxJI1zVkQJ8MJwk")
          .collection("SURVEY_POLLS")
          .get();

        let polls = [];
        poll.docs.forEach((ele) => {
          polls.push({
            data: ele.data(),
            id: ele.id,
          });

          SetSurveysPolls(polls.slice());
          let respuesta = [];

          polls.forEach((ele) => {
            db.collection("companies")
              .doc("HZZb5MxJI1zVkQJ8MJwk")
              .collection("SURVEY_POLLS")
              .doc(ele.id)
              .collection("ANSWERS")
              .where("timestamp", ">=", oneWeekOld)
              .orderBy("timestamp", "desc")
              //   .limit(25)
              .onSnapshot((querySnapshot) => {
                querySnapshot.docChanges().forEach((change) => {
                  if (change.type === "added") {
                    respuesta.push(change.doc.data());
                  }
                });
                SetAnswers(respuesta.slice())
              });
          });
          ;
        })


      } catch (err) {
        console.log(err);
      }
    }
  };


  let getTime = new Date().getTime() / 1000;
  let oneWeekOld = getTime - 604800;


  useEffect(() => {
    getPolls2(fecha1, fecha2)

  }, [FilterOn, fecha1, fecha2])


  let reset = (startDate, endDate, searchBar) => {
    setFecha1("");
    setFecha2("");
    setSearch("");
    searchBar.value = "";
    startDate.value = "";
    endDate.value = "";
    setfilterOn(false);
  };

  let handleClick = (startDate, endDate, searchBar) => {
    if (startDate.value != "" && endDate.value != "" && searchBar.value != "") {
      setFecha1(new Date(startDate.value).getTime() / 1000);

      setFecha2(new Date(endDate.value).getTime() / 1000);

      setSearch(searchBar.value);
      setfilterOn(true);
    } else if (startDate.value != "") {
      setFecha1(new Date(startDate.value).getTime() / 1000);
      setFecha2(new Date(endDate.value).getTime() / 1000);
      setSearch(searchBar.value);
      setfilterOn(true);
    } else if (startDate.value != "" && searchBar.value != "") {
      setFecha1(new Date(startDate.value).getTime() / 1000);
      setFecha2(new Date(endDate.value).getTime() / 1000);
      setSearch(searchBar.value);
      setfilterOn(true);
    } else if (startDate.value != "" && endDate.value != "") {
      setFecha1(new Date(startDate.value).getTime() / 1000);
      setFecha2(new Date(endDate.value).getTime() / 1000);
      setSearch("");
      setfilterOn(true);
    } else if (searchBar.value != "") {
      setSearch(searchBar.value);
      setFecha1("");
      setFecha2("");
    } else {
      setFecha1("");
      setFecha2("");
      setSearch("");
    }
  };



  let handlePopUpAnswers = (singleReport) => {
    if (singleReport.questions) {
      setPopUpInformationAnswers(singleReport)
      setPopUpAnswers(!popUpAnswers)
    } else {
      setPopUpAnswers(!popUpAnswers)
    }
  };




  return (
    <div>
      <Sidebar />
      <RespuestasUnificadasGalicia
        screenSize={screenSize}
        darkModeState={darkTheme}
        language={language}
        handleClick={handleClick}
        reset={reset}
        reports={surveyAnswers}
        fecha1={fecha1}
        fecha2={fecha2}
        search={search}
        Oldsurveys={Oldsurveys}
        surveyPolls={surveyPolls}
        displayOn={displayOn}
        popUpAnswersState={popUpAnswers}
        handlePopUpAnswers={handlePopUpAnswers}
        popUpInformationAnswers={popUpInformationAnswers}
      />
    </div>
  );
};

export default RespuestasUnificadasGalicia2;
