import React, { useState, useEffect, memo } from "react";
import Sidebar from "../components/Sidebar";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AuthService from "../services/auth.service";
import { loginUserPreferences, logoutUser, companyInfo, surveysInfo } from "../store/actions/loginAction";
import { saveMessagesSelected, saveChatSelected, savePersonSelected, saveChannel } from "../store/actions/messageAction";
import firebase from '../config/firebaseBBVA';
const db = firebase.firestore();

const SidebarContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch()
  const loginUser = useSelector(state => state.user.loginUser)
  const companyUser = useSelector(state => state.user.companyInfo)
  const language = useSelector(state => state.user.language)
  const darkTheme = useSelector(state => state.user.darkTheme)


  // useEffect(() => {
  //   // BBVA
  //   if (loginUser.company == "d4c9s7pwy2UwYPg2OMfr") {
  //     // let timeNow = Date.now() / 1000 | 0
  //     // let oneMonthBefore = timeNow - 2629743
  //     // db.collection("SURVEYS_ANSWERS")
  //     //   .where("tenant", "==", "7hp9QpXdYcXxOgSDJXWN")
  //     //   .where("timestamp", ">", oneMonthBefore)
  //     //   .orderBy('timestamp', "desc")
  //     //   .onSnapshot((chats) => {
  //     //     let chatsToState = []
  //     //     chats.forEach((singleChat) => {
  //     //       chatsToState.push(singleChat.data())
  //     //     })
  //     //     dispatch(surveysInfo(chatsToState.slice()))
  //     //   })
  //     //GALICIA 
  //   }

  //   // else if (loginUser.company == "HZZb5MxJI1zVkQJ8MJwk") {
  //   //   let timeNow = Date.now() / 1000 | 0
  //   //   let oneWeek = timeNow - 604800
  //   //   db.collection("SURVEYS_ANSWERS")
  //   //     .where("tenant", "==", "xv1EIHTLFeqH4l8582nr")
  //   //     .where("timestamp", ">", oneWeek)
  //   //     //  .limit(10)
  //   //     .orderBy('timestamp', "desc")
  //   //     .onSnapshot((chats) => {
  //   //       let chatsToState = []
  //   //       chats.forEach((singleChat) => {
  //   //         chatsToState.push(singleChat.data())
  //   //       })
  //   //       dispatch(surveysInfo(chatsToState.slice()))
  //   //     })
  //   // }
  // }, []);


  const handleProfile = (e) => {
    history.push("/profile");
  };

  const handleLogout = (e) => {
    AuthService.logout()
    dispatch(logoutUser())
    dispatch(loginUserPreferences('status', 'pending'))
    dispatch(saveChatSelected(''))
    dispatch(savePersonSelected(''))
    dispatch(saveChannel(''))
    dispatch(companyInfo({}))
    dispatch(surveysInfo([]))
    dispatch(saveMessagesSelected([]))
    history.push("/login");
  };

  return (
    <Sidebar
      handleLogout={handleLogout}
      handleProfile={handleProfile}
      loginUser={loginUser}
      darkModeState={darkTheme}
      language={language}
      companyUser={companyUser}
    />
  );
}

export default React.memo(SidebarContainer);
