import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginUserPreferences } from "../store/actions/loginAction";
import NewReportsGaliciaSeguros from "../components/NewReportsGalicia";
import Sidebar from "./Sidebar";
import firebase from '../config/firebaseIO'

const db = firebase.firestore()

const NewReportsGaliciaSeguros2 = () => {

    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [reports, setReports] = useState([]);
    const [dateToday, setDateToday] = useState("");

    const dispatch = useDispatch();

    const [fecha1, setFecha1] = useState("");
    const [fecha2, setFecha2] = useState("");
    const [search, setSearch] = useState("");


    const [surveyPolls, SetSurveysPolls] = useState([]);
    const [Oldsurveys, SetOldSurveys] = useState([]);

    const [surveyAnswers, SetAnswers] = useState([]);

    const [FilterOn, setfilterOn] = useState(false);
    const [displayOn, setDisplayOn] = useState(false)

    const loginUser = useSelector(state => state.user.loginUser);
    const language = useSelector(state => state.user.language);
    const darkTheme = useSelector(state => state.user.darkTheme);
    const companySurveys = useSelector(state => state.user.surveys);

    // ESTE FUNCION TRAE LAS ENCUESTAS Y LAS RESPUESTAS EN UNA FECHA DADA

    let getOldSurveys = async (array) => {
        let surveys = array
        let old = await db.collection("companies")
            .doc("HZZb5MxJI1zVkQJ8MJwk")
            .collection("SURVEY_POLLS")
            .get();
        old.docs.forEach(async (singleSurvey) => {
            let database = await db.collection("companies")
                .doc("HZZb5MxJI1zVkQJ8MJwk")
                .collection("SURVEY_POLLS")
                .doc(singleSurvey.id)
                .collection("ANSWERS")
                //    .limit(30)
                .get();
            database.docs.forEach(ele => surveys.push(ele.data()))
        })
        return surveys
    }


    useEffect(() => {
        // TRAE LAS ENCUESTAS Y LOS DATOS EN TIEMPO REAL
        let respuestas = [];

        let getPolls = async () => {
            let polls = [];
            let poll = await db
                .collection("companies")
                .doc("HZZb5MxJI1zVkQJ8MJwk")
                .collection("SURVEY_POLLS")
                .get();
            poll.docs.forEach((ele) => {
                polls.push({
                    data: ele.data(),
                    id: ele.id
                })
            })
            SetSurveysPolls(polls.slice());

            let getTime = new Date().getTime() / 1000;
            let oneWeekOld = getTime - 604800;

            polls.forEach((ele) => {
                db.collection("companies")
                    .doc("HZZb5MxJI1zVkQJ8MJwk").collection("SURVEY_POLLS")
                    .doc(ele.id).collection("ANSWERS")
                    .where("timestamp", ">=", oneWeekOld)
                    .orderBy("timestamp", "desc")
                    //   .limit(10)
                    .onSnapshot(querySnapshot => {
                        querySnapshot.docChanges()
                            .forEach(change => {
                                if (change.type === 'added') {
                                    respuestas.push(change.doc.data());
                                }
                            });
                    });
            })
            return respuestas.slice()
        };
        getPolls();
        SetAnswers(respuestas)

    }, []);


    useEffect(() => {
        // TRAE LAS ENCUESTAS Y LAS RESPUESTAS EN UNA FECHA DADA
        if (FilterOn == true) {
            let array = []
            getOldSurveys(array)
            SetOldSurveys(array)
        }
    }, [fecha1, fecha2])


    let reset = (startDate, endDate, searchBar,) => {
        setFecha1("");
        setFecha2("");
        setSearch("");
        searchBar.value = "";
        startDate.value = "";
        endDate.value = "";
        setfilterOn(false);

    };

    let handleClick = (
        startDate,
        endDate,
        searchBar) => {

        if (startDate.value != "" && endDate.value != "" && searchBar.value != "") {

            setFecha1(new Date(startDate.value).getTime() / 1000);

            setFecha2(new Date(endDate.value).getTime() / 1000);

            setSearch(searchBar.value);
            setfilterOn(true);

        } else if (startDate.value != "") {
            setFecha1(new Date(startDate.value).getTime() / 1000);
            setFecha2(new Date(endDate.value).getTime() / 1000);
            setSearch(searchBar.value);
            setfilterOn(true);


        } else if (startDate.value != "" && searchBar.value != "") {

            setFecha1(new Date(startDate.value).getTime() / 1000);
            setFecha2(new Date(endDate.value).getTime() / 1000);
            setSearch(searchBar.value);
            setfilterOn(true);

        }
        else if (startDate.value != "" && endDate.value != "") {
            setFecha1(new Date(startDate.value).getTime() / 1000);
            setFecha2(new Date(endDate.value).getTime() / 1000);
            setSearch("");
            setfilterOn(true);

        }
        else if (searchBar.value != "") {
            setSearch(searchBar.value);
            setFecha1("");
            setFecha2("");
        } else {
            setFecha1("");
            setFecha2("");
            setSearch("");
        }
    };

    let popUp = (id, preguntas, respuestas) => {

        if (displayOn == false && !id.innerHTML) {
            setDisplayOn(true);
            id.innerHTML += preguntas.map((ele, index) => { return ele + " " + respuestas[index] + " " })

        }
        else {
            id.innerHTML = "";
            setDisplayOn(false)
        }
    }
    return (
        <div>
            <Sidebar />
            <NewReportsGaliciaSeguros
                screenSize={screenSize}
                darkModeState={darkTheme}
                language={language}
                dateToday={dateToday}
                handleClick={handleClick}
                reset={reset}
                reports={surveyAnswers}
                fecha1={fecha1}
                fecha2={fecha2}
                search={search}
                Oldsurveys={Oldsurveys}
                surveyPolls={surveyPolls}
                displayOn={displayOn}
                popUp={popUp}
            />
        </div>
    );
}

export default NewReportsGaliciaSeguros2;