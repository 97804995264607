import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, } from "react-redux";
import { useParams } from "react-router-dom";
import EventsIO from "../components/Events";
import firebase from '../config/firebaseIO'

const db = firebase.firestore()

const EventsIOContainer = () => {

    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [lastMessage, setLastMessage] = useState("");
    const [arrayRespuestas, setArrayRespuestas] = useState("");

    const [infoPeople, setInfoPeople] = useState([]);
    const [personSelected, setPersonSelected] = useState()
    const [confettiBoolean, setConfettiBoolean] = useState(false)


    const { idEvent } = useParams();
    const [darkTheme, setdarkTheme] = useState(false);
    const [language, setlanguage] = useState(false);


    useEffect(() => {
        window.scrollTo(0, 0);
        db.collection('sorteos-eventos').where("instance", "==", idEvent)
            .onSnapshot((people) => {
                let peopleArray = []
                people.forEach((singlePerson) => {
                    // console.log("lo que viene", singlePerson.data())
                    peopleArray.push(singlePerson.data().name)
                })
                console.log("Personas>", peopleArray)
                setInfoPeople(peopleArray.slice())
            })
    }, []);



    const handleNewPerson = () => {



        let newPersonSelected = infoPeople[Math.floor(Math.random() * infoPeople.length)]
        setPersonSelected(newPersonSelected)

        setConfettiBoolean(true)


        setTimeout(() => {
            setConfettiBoolean(false)
        }, "5000");


        let indexArray = infoPeople.indexOf(newPersonSelected);
        if (indexArray > -1) { // only splice array when item is found
            infoPeople.splice(indexArray, 1); // 2nd parameter means remove one item only
        }




        console.log('como queda el array:', infoPeople)



    };



    return (
        <div>
            <EventsIO
                screenSize={screenSize}
                darkModeState={darkTheme}
                language={language}
                // idInteraction={idInteraction}
                lastMessage={lastMessage}
                arrayRespuestas={arrayRespuestas}
                personSelected={personSelected}
                handleNewPerson={handleNewPerson}
                confettiBoolean={confettiBoolean}
            />
        </div>
    );
}

export default EventsIOContainer;