import React from "react";


export default ({
  screenSize,
  darkModeState,
  language,
  surveyPolls,
}) => {

  const styles = {
    backColor: {
      position: "fixed",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      height: "100%",
      backgroundColor: `${darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"
        }`,
    },
    container: {
      position: "fixed",
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
      display: "flex",
      flexDirection: "column",
      //   padding: `${screenSize > 800 ? ("3% 2% 3% 20%") : ("30px")}`,
      padding: `${screenSize > 800 ? "3% 2% 3% 280px" : "30px"}`,
      height: "100%",
      // overflow: `auto`,
    },
    containerLoader: {
      display: "flex",
      alignItem: "center",
      justifyContent: "center",
      height: "80%",
    },
    navbar: {
      display: "flex",
      flex: 1.5,
      margin: `${screenSize > 800 ? "0px 20px" : "0px"}`,
      marginBottom: `${screenSize > 800 ? "0px" : "30px"}`,
    },
    textsNavbar: {
      display: "flex",
      flex: 8,
      flexDirection: "column",
    },
    titleNavbar: {
      marginBottom: `${screenSize > 800 ? "5px" : "10px"}`,
      fontSize: "28px",
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
      fontFamily: "InterBold",
      lineHeight: "1.4",
    },
    subtitleNavbar: {
      margin: 0,
      fontSize: "14px",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      fontFamily: "InterBold",
    },
    toogleNavbar: {
      display: "flex",
      flex: 4,
      alignItems: "center",
      justifyContent: `${screenSize > 800 ? "flex-end" : "center"}`,
      flexDirection: `${screenSize > 800 ? "row" : "column"}`,
    },
    textToogle: {
      fontSize: "14px",
      fontFamily: "InterBold",
      alignSelf: "center",
      textDecoration: "none",
      color: "inherit",
      border: "none",
      outline: "none",
      padding: "12px",
      backgroundColor: `transparent`,
    },
    textUser: {
      fontSize: "14px",
      fontFamily: "InterBold",
      alignSelf: "center",
      textDecoration: "none",
      color: "inherit",
      border: "none",
      outline: "none",
      padding: 0,
      backgroundColor: `transparent`,
    },
    buttonNew: {
      textDecoration: "none",
      color: "#fff",
      backgroundColor: "#4dc2f1",
      border: "none",
      margin: "20px",
      // margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
      width: `${screenSize > 800 ? "180px" : <></>}`,
    },
    total: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      margin: `${screenSize > 800 ? "10px 20px 10px" : "0px"}`,
    },
    filters: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      alignItems: "flex-end",
      justifyContent: "space-between",
      margin: `${screenSize > 800 ? "10px 20px 20px" : "0px"}`,
    },
    filterGroup: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      padding: "0px 20px 0px 0px",
    },
    filterDateGroup: {
      display: "flex",
      flexDirection: "row",
    },
    filterLabel: {
      fontFamily: "InterBold",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      padding: "0px 5px",
    },
    filterInputDate: {
      border: "none",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      fontFamily: "InterBold",
      width: "100%",
      height: "40px",
      borderRadius: "5px",
      padding: "5px 10px",
      margin: "0px 5px 0px 0px",
    },
    filterInput: {
      border: "none",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      fontFamily: "InterBold",
      width: "100%",
      height: "40px",
      borderRadius: "5px",
      padding: "5px 10px",
    },
    filterButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textDecoration: "none",
      outline: 0,
      color: "#fff",
      backgroundColor: "#4dc2f1",
      border: "none",
      borderRadius: 5,
      margin: 0,
      padding: "8px 13px",
      width: "100px",
    },
    resetButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textDecoration: "none",
      outline: 0,
      color: "#4dc2f1",
      backgroundColor: "transparent",
      border: "1px #4dc2f1 solid",
      borderRadius: 5,
      margin: "0px 0px 0px 10px",
      padding: "8px 13px",
      width: "100px",
    },
    singleReportReference: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      margin: `${screenSize > 800 ? "0px 20px" : "0px"}`,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: "5px",
      padding: "0px 25px",
      textDecoration: "none",
      border: "none",
      outline: "none",
      // margin: `${screenSize > 800 ? "1.4% 0px -10px" : "10px"}`,
    },
    reports: {
      display: "flex",
      flex: 11,
      flexDirection: "column",
      alignItems: "center",
      margin: `${screenSize > 800 ? "0px 20px" : "0px"}`,
      overflow: `auto`,
    },
    singleReport: {
      display: "flex",
      //   width: `${screenSize > 800 ? ("95%") : ("80vw")}`,
      width: `${screenSize > 800 ? "100%" : "80vw"}`,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: "5px",
      padding: "10px 25px",
      textDecoration: "none",
      border: "none",
      outline: "none",
      margin: `${screenSize > 800 ? "1.4% 0px -10px" : "10px"}`,
    },

    singleBadReport: {
      display: "flex",
      //   width: `${screenSize > 800 ? ("95%") : ("80vw")}`,
      width: `${screenSize > 800 ? "100%" : "80vw"}`,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: "5px",
      padding: "10px 25px",
      textDecoration: "none",
      border: "none",
      outline: "none",
      margin: `${screenSize > 800 ? "1.4% 0px -10px" : "10px"}`,
      backgroundColor: "rgba(255, 0, 0, 0.2)",
    },

    lineDetail: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    date: {
      display: "flex",
      flex: 1,
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      margin: 0,
      fontSize: "12px",
      fontFamily: "InterBold",
      textAlign: "left",
      alignSelf: "center",
      justifyContent: "flex-start",
      paddingRight: "15px",
    },
    phoneNumber: {
      display: "flex",
      flex: 2,
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
      margin: 0,
      fontSize: "12px",
      fontFamily: "InterBold",
      textAlign: "left",
      alignSelf: "center",
      justifyContent: "flex-start",
      paddingRight: "15px",
    },
    option: {
      display: "flex",
      flex: 3,
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      margin: 0,
      fontSize: "12px",
      fontFamily: "InterBold",
      textAlign: "left",
      alignSelf: "center",
      justifyContent: "flex-start",
      paddingRight: "15px",
    },
    information: {
      display: "flex",
      flex: 2,
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      margin: 0,
      fontSize: "12px",
      fontFamily: "InterBold",
      textAlign: "left",
      alignSelf: "center",
      justifyContent: "flex-start",
      paddingRight: "15px",
    },
    answer: {
      display: "flex",
      flex: 1,
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      margin: 0,
      fontSize: "12px",
      fontFamily: "InterBold",
      textAlign: "left",
      alignSelf: "center",
      justifyContent: "center",
      paddingRight: "15px",
    },
    time: {
      display: "flex",
      flex: 1,
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      margin: 0,
      fontSize: "12px",
      fontFamily: "InterBold",
      textAlign: "left",
      alignSelf: "center",
      justifyContent: "flex-start",
      //   paddingRight: '0px'
    },
    singleCounter: {
      display: "flex",
      width: `${screenSize > 800 ? "100%" : "80vw"}`,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: "5px",
      padding: "10px 25px",
      textDecoration: "none",
      border: "none",
      outline: "none",
      margin: `${screenSize > 800 ? "2.4% 0px -10px" : "10px"}`,
      backgroundColor: "#4dc2f1",
      color: "#fff",
    },
    titleCounter: {
      display: "flex",
      flex: 4,
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
      margin: "0px",
      fontSize: "18px",
      fontFamily: "InterBold",
      textAlign: "start",
      alignSelf: "center",
      color: "#fff",
    },
    numberCounter: {
      display: "flex",
      flex: 2,
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      margin: 0,
      fontSize: "14px",
      fontFamily: "InterBold",
      textAlign: "end",
      alignSelf: "center",
      justifyContent: "flex-end",
      color: "#fff",
    },
    downloadsContainer: {
      display: "flex",
      width: `${screenSize > 800 ? "100%" : "80vw"}`,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
      borderRadius: "5px",
      padding: "10px 0px",
      textDecoration: "none",
      border: "none",
      outline: "none",
      margin: `${screenSize > 800 ? "1.4% 0px 0px" : "10px"}`,
      backgroundColor: "transparent",
    },
    downloadsButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textDecoration: "none",
      outline: 0,
      color: "#4dc2f1",
      backgroundColor: "transparent",
      border: "1px #4dc2f1 solid",
      borderRadius: 5,
      margin: "0px 0px 0px 10px",
      padding: "8px 13px",
      width: "180px",
    },
    loader: {
      display: "flex",
      alignSelf: "center",
    },
    CreationForm: {
      with: "100%",
    },
    surveyName: {
      fontSize: "18px",
      color: "blue"
    }
  };
  // console.log(surveyPolls);
  return (
    <>
      <div style={styles.backColor} />
      <div style={styles.container}>
        <div style={styles.navbar}>
          <div style={styles.textsNavbar}>
            <h3 style={styles.titleNavbar}>{language === "english" ? "Surveys creation" : "Encuestas"} 😃</h3>
            <h3 style={styles.subtitleNavbar}> {language === "english" ? "All surveys information." : "Todos la información de encuestas."}</h3>
          </div>
        </div>
        <div style={styles.reports}>



          <div className={darkModeState ? "buttonDark" : "buttonLight"}>
            {surveyPolls != [] ? (
              surveyPolls.map((survey, index) => {
                return (
                  <div key={survey.data.id_survey}>
                    <a style={styles.surveyName} href={'/surveys/galicia-seguros/' + surveyPolls[index].id}>{survey.data.name}</a>
                    <p>{survey.data.channel == "audio" ? "Audio" : "Mensajería"}</p>
                    <p>Preguntas: {survey.data.questions.length}</p>
                    <ol >
                      {survey.data.questions.map((question, index) => {
                        return (
                          <div key={index}>
                            <li key={question.question}>{question.question}, Tipo de pregunta: {question.type == "afirmative" ? "Si / No" : question.type == "comment " ? "Comentario" : "Puntuación"}, Grafico: {question.chart == true ? "Si" : "No"}, Comentario: {question.comment == true ? "Si" : "No"}</li>
                          </div>
                        );
                      })}
                    </ol>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
          {/* 
<h3>Formulario de encuestas</h3>
          <form  method="" id="CreationForm" style={styles.CreationForm}>
            <ul>
              <li>
                <label for="name">Nombre de encuesta:</label>
                <input type="text" id="surveyName" name="surveyName" required/>
              </li>
              <li>
                <label for="surveyChannel">Canal:</label>
                <label>WhatsApp </label>
                <input type="radio" id="surveyChannelWhatsApp" name="surveyChannel" value={true} defaultChecked/>
                <label>Audio </label>
                <input type="radio" id="surveyChannelAudio" name="surveyChannel" value="Audio"/>
              </li>

              <li>
                <label for="question1">Pregunta 1:</label>
                <input type="text" id="question1" name="question1"/>
          
                <label>Grafico</label>
                <input type="checkBox" id="chart1" name="" value={true}/>
                <label for="type">Tipo de pregunta</label>
                <select name="type1" id="type1">
                <option value="rating">Puntuación</option>
                <option value="comment">Comentario</option>
                <option value="afirmative">Si / No</option>

                </select>
              </li>
              <li>
                <label for="name">Pregunta 2:</label>
                <input type="text" id="question2" name="question2"/>
             
                <label>Grafico</label>
                <input type="checkBox" id="chart2" name="" value={true}/>
              
                <label for="type">Tipo de pregunta</label>
                <select name="type2" id="type2">
                <option value="rating">Puntuación</option>
                <option value="comment">Comentario</option>
                <option value="afirmative">Si / No</option>
                </select>
              </li>

              <li>
                <label for="name">Pregunta 3:</label>
                <input type="text" id="question3" name="question3"/>
              
                <label> Grafico</label>
                <input type="checkBox" id="chart3" name="" value={true}/>
              

                <label for="type">Tipo de pregunta</label>
                <select name="type3" id="type3">
                <option value="rating">Puntuación</option>
                <option value="comment">Comentario</option>
                <option value="afirmative">Si / No</option>

                </select>
              </li>

              <li>
                <label for="name">Pregunta 4:</label>
                <input type="text" id="question4" name="question4"/>
                         
                <label>Grafico</label>
                <input type="checkBox" id="chart4" name="" value={true}/>
            

                <label for="type">Tipo de pregunta</label>
                <select name="type4" id="type4">
                <option value="rating">Puntuación</option>
                <option value="comment">Comentario</option>
                <option value="afirmative">Si / No</option>
                </select>
              </li>
              <li>
                <label for="name">Pregunta 5:</label>
                <input type="text" id="question5" name="question5"/>
             
                <label>Grafico</label>
                <input type="checkBox" id="chart5" name="" value={true}/>
               
                <label for="type">Tipo de pregunta</label>

                <select name="type5" id="type5">
                <option value="rating">Puntuación</option>
                <option value="comment">Comentario</option>
                <option value="afirmative">Si / No</option>

                </select>

              </li>
            </ul>
            <button type="button" onClick={()=>{sendToDB(
            "surveyName","surveyChannelWhatsApp",
            "question1","question2","question3","question4","question5",
            "chart1","chart2","chart3","chart4","chart5",
            "type1","type2","type3","type4","type5"

            
            )}}>Guardar</button>

            <button type="button">Reset</button>

          </form>




*/}
          <a type="button" href='create/galicia-seguros'>Crear una nueva encuesta</a>

        </div>
      </div>
    </>
  );
};
