import React, { useState, useEffect, Child } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginUserPreferences } from "../store/actions/loginAction";
import SurveyAnswersUnified from "../components/SurveyAnswersUnified";

import Sidebar from "./Sidebar";
import firebase from "../config/firebaseIO";

const db = firebase.firestore();

const SurveyAnswersUnifiedContainer = () => {
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    // const dispatch = useDispatch();
    const [fecha1, setFecha1] = useState("");
    const [fecha2, setFecha2] = useState("");
    const [search, setSearch] = useState("");

    const [surveyAnswers, setAnswers] = useState([]);

    const [oldSurveys, setOldSurveys] = useState([]);
    const [surveyPolls, setSurveysPolls] = useState([]);

    const [filterOn, setFilterOn] = useState(false);
    const [displayOn, setDisplayOn] = useState(false);

    const loginUser = useSelector((state) => state.user.loginUser);
    const language = useSelector((state) => state.user.language);
    const darkTheme = useSelector((state) => state.user.darkTheme);

    const [popUpAnswers, setPopUpAnswers] = useState(false);
    const [popUpInformationAnswers, setPopUpInformationAnswers] = useState(false);


    let getTime = new Date().getTime() / 1000;
    // let oneWeekOld = getTime - 604800;
    let oneDayOld = getTime - 86400;


    let getPolls2 = async (start, end) => {
        if (filterOn == true) {
            try {
                return new Promise(async (resolve) => {
                    let surveys = []
                    let oldPolls = await db
                        .collection("companies")
                        .doc(loginUser.company)
                        .collection("SURVEY_POLLS")
                        .get();
                    oldPolls.docs.forEach(async (singleSurvey) => {
                        await db
                            .collection("companies")
                            .doc(loginUser.company)
                            .collection("SURVEY_POLLS")
                            .doc(singleSurvey.id)
                            .collection("ANSWERS")
                            .where("timestamp", ">=", start)
                            .where("timestamp", "<=", (end + 86400000))
                            //  .limit(25)
                            .get()
                            .then((docsAnswers) => {
                                docsAnswers.forEach(singleAnswer => surveys.push(
                                    // ele.data()
                                    {
                                        name: singleSurvey.data().name ? singleSurvey.data().name : "",
                                        channel: singleSurvey.data().channel ? singleSurvey.data().channel : "",
                                        questions: singleSurvey.data().questions.slice(0, singleAnswer.data().nestedAnswers.length).map(element => element.question),
                                        nestedAnswers: singleAnswer.data().nestedAnswers,
                                        agentName: singleAnswer.data().agentName ? (singleAnswer.data().agentName == "0 - undefined" || singleAnswer.data().agentName == " - undefined" ? language == "english" ? "Anonymous" : "Anónimo" : singleAnswer.data().agentName) : "---",
                                        date: singleAnswer.data().date,
                                        dni: singleAnswer.data().dni ? singleAnswer.data().dni : "---",
                                        sessionNumber: singleAnswer.data().sessionNumber ? singleAnswer.data().sessionNumber : "--",
                                        service: singleAnswer.data().service ? singleAnswer.data().service : "Otros",
                                        phoneNumber: singleAnswer.data().nroTelefono ? singleAnswer.data().nroTelefono == "Unknown" || singleAnswer.data().nroTelefono == "anonymous" || singleAnswer.data().nroTelefono == "Anonymous" || singleAnswer.data().nroTelefono == "Anónimo" || singleAnswer.data().nroTelefono == "" ? language == "english" ? "Anonymous" : "Anónimo" : singleAnswer.data().nroTelefono : "---",
                                        time: singleAnswer.data().time,
                                        timestamp: singleAnswer.data().timestamp
                                    }
                                ));
                                surveys = surveys.slice();
                                resolve(setOldSurveys(surveys));
                            });
                    })
                })
            } catch (err) {
                console.log(err)
            }
        } else {
            try {
                let polls = [];
                let poll = await db
                    .collection("companies")
                    .doc(loginUser.company)
                    .collection("SURVEY_POLLS")
                    .get()
                    .then((querySnapshot) => {
                        querySnapshot.forEach((ele) => {
                            polls.push({
                                ...ele.data(),
                                id: ele.id,
                            });
                            setSurveysPolls(polls.slice());
                            let respuesta = [];
                            polls.forEach((ele) => {
                                db.collection("companies")
                                    .doc(loginUser.company)
                                    .collection("SURVEY_POLLS")
                                    .doc(ele.id)
                                    .collection("ANSWERS")
                                    // .where("timestamp", ">=", oneWeekOld)
                                    .where("timestamp", ">=", oneDayOld)
                                    .orderBy("timestamp", "desc")
                                    //   .limit(25)
                                    .onSnapshot((querySnapshot) => {
                                        querySnapshot.docChanges().forEach((change) => {
                                            if (change.type === "added") {
                                                respuesta.push({
                                                    name: ele.name ? ele.name : "",
                                                    channel: ele.channel ? ele.channel : "",
                                                    questions: ele.questions.slice(0, change.doc.data().nestedAnswers.length).map(element => element.question),
                                                    nestedAnswers: change.doc.data().nestedAnswers,
                                                    // agentName: change.doc.data().agentName ? change.doc.data().agentName : "---",
                                                    agentName: change.doc.data().agentName ? (change.doc.data().agentName == "0 - undefined" || change.doc.data().agentName == " - undefined" ? language == "english" ? "Anonymous" : "Anónimo" : change.doc.data().agentName) : "---",
                                                    date: change.doc.data().date,
                                                    dni: change.doc.data().dni ? change.doc.data().dni : "---",
                                                    sessionNumber: change.doc.data().sessionNumber ? change.doc.data().sessionNumber : "--",
                                                    service: change.doc.data().service ? change.doc.data().service : "Otros",
                                                    // phoneNumber: change.doc.data().nroTelefono ? change.doc.data().nroTelefono : "",
                                                    phoneNumber: change.doc.data().nroTelefono ? change.doc.data().nroTelefono == "Unknown" || change.doc.data().nroTelefono == "anonymous" || change.doc.data().nroTelefono == "Anonymous" || change.doc.data().nroTelefono == "Anónimo" || change.doc.data().nroTelefono == "" ? language == "english" ? "Anonymous" : "Anónimo" : change.doc.data().nroTelefono : "",
                                                    time: change.doc.data().time,
                                                    timestamp: change.doc.data().timestamp
                                                })
                                                // respuesta.push(change.doc.data());
                                            }
                                        });
                                        setAnswers(respuesta.slice())
                                    });
                            });
                        });
                    });
            } catch (err) {
                console.log(err);
            }
        }
    };


    useEffect(() => {
        getPolls2(fecha1, fecha2)
    }, [filterOn, fecha1, fecha2])

    let reset = (startDate, endDate, searchBar) => {
        setFecha1("");
        setFecha2("");
        setSearch("");
        startDate.value = "";
        endDate.value = "";
        searchBar.value = "";
        setFilterOn(false);
    };

    let handleClick = (startDate, endDate, searchBar) => {
        if (startDate.value != "" && endDate.value != "" && searchBar.value != "") {
            setFecha1(new Date(startDate.value).getTime() / 1000);
            setFecha2(new Date(endDate.value).getTime() / 1000);
            setSearch(searchBar.value);
            setFilterOn(true);
            // } else if (startDate.value != "") {
            //     setFecha1(new Date(startDate.value).getTime() / 1000);
            //     setFecha2(new Date(endDate.value).getTime() / 1000);
            //     setSearch(searchBar.value);
            //     setFilterOn(true);
            // } else if (startDate.value != "" && searchBar.value != "") {
            //     setFecha1(new Date(startDate.value).getTime() / 1000);
            //     setFecha2(new Date(endDate.value).getTime() / 1000);
            //     setSearch(searchBar.value);
            //     setFilterOn(true);
        } else if (startDate.value != "" && endDate.value != "") {
            setFecha1(new Date(startDate.value).getTime() / 1000);
            setFecha2(new Date(endDate.value).getTime() / 1000);
            setSearch("");
            setFilterOn(true);
        } else if (searchBar.value != "") {
            setSearch(searchBar.value);
            setFecha1("");
            setFecha2("");
        } else {
            setFecha1("");
            setFecha2("");
            setSearch("");
        }
    };

    let handlePopUpAnswers = (singleReport) => {
        if (singleReport.questions) {
            setPopUpInformationAnswers(singleReport)
            setPopUpAnswers(!popUpAnswers)
        } else {
            setPopUpAnswers(!popUpAnswers)
        }
    };

    return (
        <div>
            <Sidebar />
            <SurveyAnswersUnified
                screenSize={screenSize}
                darkModeState={darkTheme}
                language={language}
                handleClick={handleClick}
                reset={reset}
                reports={surveyAnswers}
                fecha1={fecha1}
                fecha2={fecha2}
                search={search}
                oldsurveys={oldSurveys}
                surveyPolls={surveyPolls}
                displayOn={displayOn}
                popUpAnswersState={popUpAnswers}
                handlePopUpAnswers={handlePopUpAnswers}
                popUpInformationAnswers={popUpInformationAnswers}
            />
        </div>
    );
};

export default SurveyAnswersUnifiedContainer;
