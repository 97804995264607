import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, } from "react-redux";
import { useParams } from "react-router-dom";
import { loginUserPreferences } from "../store/actions/loginAction";
import OneView from "../components/OneView";
import firebase from '../config/firebaseIO'
import Sidebar from "./Sidebar";
import axios from "axios";

const db = firebase.firestore()

const OneViewContainer = () => {

    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [lastMessage, setLastMessage] = useState("");
    const [arrayRespuestas, setArrayRespuestas] = useState("");

    const [infoClient, setInfoClient] = useState({});

    const dispatch = useDispatch()

    const { idInteraction } = useParams();

    // const language = useSelector(state => state.user.language)
    // const darkTheme = useSelector(state => state.user.darkTheme)
    const [darkTheme, setdarkTheme] = useState(false);
    const [language, setlanguage] = useState(false);


    useEffect(() => {
        window.scrollTo(0, 0);
        // window.addEventListener("resize", handleResize);
        if (idInteraction != "") {



            try {
                var data = JSON.stringify({ "client": "28659337" });

                var config = {
                    method: 'post',
                    url: 'https://us-central1-insideone-onereport.cloudfunctions.net/CLIENT_VALIDATION_POLICIES_GS_ONEVIEW?instance=galiciaseguros',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };


                axios(config)
                    .then(function (response) {


                        // setInfoClient(response.data)


                        // console.log("info client>", response.data)


                        console.log(JSON.stringify(response.data));



                        // let respuestaFinal = response.data;

                        // let respuestaFinalUsuario = respuestaFinal.choices[0].message.content;

                        // let arrayFinal = respuestaFinalUsuario.split("/")

                        // setArrayRespuestas(arrayFinal)

                        // setLastMessage(respuestaFinalUsuario)
                        // res.status(200).send(respuestaFinalUsuario);
                    })
                    .catch(function (error) {
                        console.log(error);
                        // res.status(500).send("Ha ocurrido un error.");
                    });
            } catch (error) {
                console.error(error);
                // res.status(500).send("Ha ocurrido un error.");
            }





        }
    }, []);



    const handleDarkMode = () => {
        // if (darkTheme === false) {
        //     dispatch(loginUserPreferences('darkTheme', true))
        // } else {
        //     dispatch(loginUserPreferences('darkTheme', false))
        // }
    };

    const handleLanguage = () => {
        // if (language === 'english') {
        //     dispatch(loginUserPreferences('language', 'spanish'))
        // } else {
        //     dispatch(loginUserPreferences('language', 'english'))
        // }
    };

    return (
        <div>
            {/* <Sidebar /> */}
            <OneView
                screenSize={screenSize}
                darkModeState={darkTheme}
                handleDarkMode={handleDarkMode}
                language={language}
                handleLanguage={handleLanguage}
                idInteraction={idInteraction}
                lastMessage={lastMessage}
                arrayRespuestas={arrayRespuestas}
            />
        </div>
    );
}

export default OneViewContainer;