import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyAqMg3im1Ws-ZKGIYZTk_yBLBu-r4JvvIc",
    authDomain: "insideone.firebaseapp.com",
    databaseURL: "https://insideone.firebaseio.com",
    projectId: "insideone",
    storageBucket: "insideone.appspot.com",
    messagingSenderId: "556974847103",
    appId: "1:556974847103:web:4cc4925f109edf5bcc3b4a",
    measurementId: "G-0TLCP2B4DV"
};

const app_two = firebase.initializeApp(firebaseConfig, 'appTwo');

export default app_two;
