import React from "react";
import dateFormat from "dateformat";
import TimeFormat from "hh-mm-ss";
import {
    Chart as ChartJS,
    CategoryScale,
    RadialLinearScale,
    LinearScale,
    PointElement,
    BarElement,
    ArcElement,
    LineElement,
    Filler,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';


import { Bar, Doughnut } from 'react-chartjs-2';

export default ({ screenSize, darkModeState, language, charts3, startDate, endDate, reset, handleClick, displayCharts, FilterOn, Oldcharts }) => {

    // En estas variables se almacenan todas las interacciones en los ultimos 30 y 7 dias.
    let unMes = [];
    let unaSemana = [];
    // valores de tiempode respuesta de hace 30 días
    let valoresUnMes = {
        fueDerivado: 0,
        fueraDeHorario: 0,
        abandonoPorTiempo: 0,
        abandono: 0,
        haCargadoTodosLosDatos: 0,
        inicioConversacion: 0,
        incorrectos: 0,
        cargo: 0
    };

    let valoresUnaSemana = {
        fueDerivado: 0,
        fueraDeHorario: 0,
        abandonoPorTiempo: 0,
        abandono: 0,
        haCargadoTodosLosDatos: 0,
        inicioConversacion: 0,
        incorrectos: 0,
        cargo: 0
    };

    // Opciones que unicas, que iran en los graficos de Seleccion de opciones 30 y 7 días.
    let opcionesContadasPorMes = [];
    let opcionesContadasPorSemana = [];

    let opcionesContadasPorMesPolizas = [];
    let opcionesContadasPorSemanaPolizas = [];

    let opcionesContadasPorMesSiniestros = [];
    let opcionesContadasPorSemanaSiniestros = [];
    // Creamos un array vacio donde revisamos cuales son las interacciones que tienen menos de un mes

    let hash = {};
    let hashSemanal = {};
    let hashSemanalPolizas = {};
    let hashSemanalSiniestros = {};
    let opcionesUnicasMes = [];
    let opcionesUnicasSemana = []

    let opcionesUnicasSemanaPolizas = []
    let opcionesUnicasSemanaSiniestros = [];



    //Tiempo de respuesta de hace 30 dias
    let tiempoRespuestaUnMes = {
        unahora: 0,
        docehoras: 0,
        veinticuatrohoras: 0,
        promedio: 0,
        promedioCargaDatos: 0
    };

    // Abandonaron en una semana
    let abandonaronLaConversacionSemana = 0;
    let abandonaronLaConversacionTiempoSemana = 0;

    // Array de objetos de inicio de conversaciones por dia de la semana
    // NO TOCAR JAMAS

    let InicioConversacion = [
        [
            {
                dia: "Lunes",
                valor: 0,
            }, {
                dia: "Martes",
                valor: 0,
            }, {
                dia: "Miercoles",
                valor: 0,
            }, {
                dia: "Jueves",
                valor: 0,
            }, {
                dia: "Viernes",
                valor: 0,
            }, {
                dia: "Sabado",
                valor: 0,
            }, {
                dia: "Domingo",
                valor: 0,
            },
        ], [
            {
                dia: "Martes",
                valor: 0,
            },
            {
                dia: "Miercoles",
                valor: 0,
            },
            {
                dia: "Jueves",
                valor: 0,
            },
            {
                dia: "Viernes",
                valor: 0,
            }, {
                dia: "Sabado",
                valor: 0,
            }, {
                dia: "Domingo",
                valor: 0,
            }, {
                dia: "Lunes",
                valor: 0,
            },
        ], [
            {
                dia: "Miercoles",
                valor: 0,
            }, {
                dia: "Jueves",
                valor: 0,
            }, {
                dia: "Viernes",
                valor: 0,
            }, {
                dia: "Sabado",
                valor: 0,
            }, {
                dia: "Domingo",
                valor: 0,
            }, {
                dia: "Lunes",
                valor: 0,
            }, {
                dia: "Martes",
                valor: 0,
            },
        ], [
            {
                dia: "Jueves",
                valor: 0,
            }, {
                dia: "Viernes",
                valor: 0,
            }, {
                dia: "Sabado",
                valor: 0,
            }, {
                dia: "Domingo",
                valor: 0,
            }, {
                dia: "Lunes",
                valor: 0,
            }, {
                dia: "Martes",
                valor: 0,
            }, {
                dia: "Miercoles",
                valor: 0,
            },
        ], [
            {
                dia: "Viernes",
                valor: 0,
            }, {
                dia: "Sabado",
                valor: 0,
            }, {
                dia: "Domingo",
                valor: 0,
            }, {
                dia: "Lunes",
                valor: 0,
            }, {
                dia: "Martes",
                valor: 0,
            }, {
                dia: "Miercoles",
                valor: 0,
            }, {
                dia: "Jueves",
                valor: 0,
            },
        ],
        [
            {
                dia: "Sabado",
                valor: 0,
            }, {
                dia: "Domingo",
                valor: 0,
            }, {
                dia: "Lunes",
                valor: 0,
            }, {
                dia: "Martes",
                valor: 0,
            }, {
                dia: "Miercoles",
                valor: 0,
            }, {
                dia: "Jueves",
                valor: 0,
            }, {
                dia: "Viernes",
                valor: 0,
            }],
        [
            {
                dia: "Domingo",
                valor: 0,
            }, {
                dia: "Lunes",
                valor: 0,
            }, {
                dia: "Martes",
                valor: 0,
            }, {
                dia: "Miercoles",
                valor: 0,
            }, {
                dia: "Jueves",
                valor: 0,
            }, {
                dia: "Viernes",
                valor: 0,
            }, {
                dia: "Sabado",
                valor: 0,
            },
        ],

    ];

    // Dia actual
    let dayToday = new Date().getDay();
    // Captura los inputs de fecha.
    let fechaInicio = document.getElementById("fechaInicio");
    let fechaFinal = document.getElementById("fechaFinal");

    //Acciones hechas por numero telefonico en un mes: 
    let accionesPorNumeroTelefonico = [];


    charts3.forEach(ele => {
        if ((new Date().getTime() - ele.timestamp) <= 604800000 && (new Date().getTime() - ele.timestamp) <= 2629743000) {
            // if ((new Date().getTime() - ele.timestamp) <= 86400000 && (new Date().getTime() - ele.timestamp) <= 2629743000) {
            unMes.push(ele);
            unaSemana.push(ele);
        } else if ((new Date().getTime() - ele.timestamp) <= 604800000) {
            // } else if ((new Date().getTime() - ele.timestamp) <= 86400000) {
            unaSemana.push(ele);
        } else if ((new Date().getTime() - ele.timestamp) <= 2629743000) {
            unMes.push(ele);
        };
    });

    // Cuentas veces fueron seleccionadas las opciones en un mes y en una semana.

    unMes.forEach((ele) => {
        opcionesUnicasMes.push({
            option: ele.option,
            isChart: ele.isChart
        })
    });

    unaSemana.forEach((ele) => {
        // console.log('ele>', ele)
        opcionesUnicasSemana.push({
            option: ele.option,
            isChart: ele.isChart,
        })
        if (ele.option == "Se le ha enviado la póliza" || ele.option == "No tiene pólizas contratadas" || ele.option == "Hubo algún problema con la póliza") {

            // console.log('ele filtrado>', ele)

            opcionesUnicasSemanaPolizas.push({
                option: ele.option,
                isChart: true
            })
        }
        if (ele.option == "Quiere denunciar un siniestro" || ele.option == "Quiere consultar un siniestro") {
            opcionesUnicasSemanaSiniestros.push({
                option: ele.option,
                isChart: true
            })
        }
        if (ele.option == "Fue derivado a un agente") {
            valoresUnaSemana.fueDerivado += 1
        } else if (ele.option == "Se contacto fuera de horario") {
            valoresUnaSemana.fueraDeHorario += 1
        }
    });

    opcionesUnicasMes = opcionesUnicasMes.filter(ele => hash[ele.option] ? false : hash[ele.option] = true)
    opcionesUnicasSemana = opcionesUnicasSemana.filter(ele => hashSemanal[ele.option] ? false : hashSemanal[ele.option] = true);

    opcionesUnicasSemanaPolizas = opcionesUnicasSemanaPolizas.filter(ele => hashSemanalPolizas[ele.option] ? false : hashSemanalPolizas[ele.option] = true);
    opcionesUnicasSemanaSiniestros = opcionesUnicasSemanaSiniestros.filter(ele => hashSemanalSiniestros[ele.option] ? false : hashSemanalSiniestros[ele.option] = true);

    // Opciones fijas 

    opcionesUnicasMes.forEach((opcion) => {
        if (opcion.isChart == "true") {
            opcionesContadasPorMes.push({
                name: opcion.option,
                value: 0,
            })
        }
    });

    opcionesUnicasSemana.forEach((opcion) => {

        // console.log('opcionesUnicasSemana>', opcionesUnicasSemana)

        if (opcion.isChart == "true") {
            opcionesContadasPorSemana.push({
                name: opcion.option,
                value: 0,
            })
        }
    });

    opcionesUnicasSemanaPolizas.forEach((opcion) => {
        // console.log('opcionesUnicasSemanaPolizas>', opcionesUnicasSemanaPolizas)
        if (opcion.isChart == true) {
            // console.log('entre aca>')
            opcionesContadasPorSemanaPolizas.push({
                name: opcion.option,
                value: 0,
            })
        }
    });

    opcionesUnicasSemanaSiniestros.forEach((opcion) => {
        if (opcion.isChart == true) {
            opcionesContadasPorSemanaSiniestros.push({
                name: opcion.option,
                value: 0,
            })
        }
    });

    //cuenta la cantidad de veces que se marcaron las opciones variables de cada cliente
    for (let i = 0; i < opcionesContadasPorMes.length; i++) {
        unMes.forEach((ele) => {
            if (opcionesContadasPorMes[i].name == ele.option) {
                opcionesContadasPorMes[i].value += 1;
            }
        })
    };

    for (let i = 0; i < opcionesContadasPorSemana.length; i++) {
        unaSemana.forEach((ele) => {
            if (opcionesContadasPorSemana[i].name == ele.option) {
                opcionesContadasPorSemana[i].value += 1
            }
        })
    };

    for (let i = 0; i < opcionesContadasPorSemanaPolizas.length; i++) {
        // console.log('opcionesContadasPorSemanaPolizasConteo>', opcionesContadasPorSemanaPolizas)
        unaSemana.forEach((ele) => {
            if (opcionesContadasPorSemanaPolizas[i].name == ele.option) {
                opcionesContadasPorSemanaPolizas[i].value += 1
            }
        })
    };

    for (let i = 0; i < opcionesContadasPorSemanaSiniestros.length; i++) {
        unaSemana.forEach((ele) => {
            if (opcionesContadasPorSemanaSiniestros[i].name == ele.option) {
                opcionesContadasPorSemanaSiniestros[i].value += 1
            }
        })
    };

    // asigna los valores a 30 dias
    unMes.forEach((ele) => {
        if (ele.timeBefore != 0 && ele.timeBefore) {
            tiempoRespuestaUnMes.promedio += ele.timebetween
            if (ele.timebetween <= 3600000) {
                tiempoRespuestaUnMes.unahora += 1;
            } else if (ele.timebetween <= 4320000) {
                tiempoRespuestaUnMes.docehoras += 1
            } else if (ele.timebetween <= 86399999) {
                tiempoRespuestaUnMes.veinticuatrohoras += 1
            }

            if (ele.option == "Ha cargado todos los datos") {
                valoresUnMes.haCargadoTodosLosDatos += 1
                tiempoRespuestaUnMes.promedioCargaDatos += ele.timebetween
            }
        };

        if (ele.option == "Abandono la interaccion por limite de tiempo") {
            valoresUnMes.abandonoPorTiempo += 1;
        } else if (ele.option == "Abandono la interaccion") {
            valoresUnMes.abandono += 1;
        } else if (ele.option.match("Ha ingresado una opción incorrecta")) {
            valoresUnMes.incorrectos += 1;
        } else if (ele.option == "Ha iniciado la conversación") {
            valoresUnMes.inicioConversacion += 1;
        } else if (ele.option == "Ha cargado todos los datos") {
            valoresUnMes.cargo += 1
        } else if (ele.option == "Fue derivado a un agente") {
            valoresUnMes.fueDerivado += 1
        } else if (ele.option == "Se contacto fuera de horario") {
            valoresUnMes.fueraDeHorario += 1
        }
    });


    // asigna los valores a 7 dias
    unaSemana.forEach((ele) => {
        if (ele.option == "Abandono la interaccion por limite de tiempo") {
            valoresUnaSemana.abandonoPorTiempo += 1;
        } else if (ele.option == "Abandono la interaccion") {
            valoresUnaSemana.abandono += 1;
        } else if (ele.option.match("Ha ingresado una opción incorrecta")) {
            valoresUnaSemana.incorrectos += 1;
        } else if (ele.option == "Ha iniciado la conversación") {
            valoresUnaSemana.inicioConversacion += 1;
        } else if (ele.option == "Ha cargado todos los datos") {
            valoresUnaSemana.cargo += 1
        } else if (ele.option == "Fue derivado a un agente") {
            valoresUnaSemana.fueDerivado += 1
        } else if (ele.option == "Se contacto fuera de horario") {
            valoresUnaSemana.fueraDeHorario += 1
        }
    });


    let promedioRespuestaUnMes = Math.round(tiempoRespuestaUnMes.promedio / tiempoRespuestaUnMes.unahora + tiempoRespuestaUnMes.docehoras + tiempoRespuestaUnMes.docehoras);
    let promedioDeCargaDatos = Math.round(tiempoRespuestaUnMes.promedioCargaDatos / valoresUnMes.haCargadoTodosLosDatos);

    //si el promedio es un numero lo convierte en formato hh:mm:ss
    if (!isNaN(promedioDeCargaDatos)) {
        promedioDeCargaDatos = TimeFormat.fromS(promedioDeCargaDatos / 1000, 'hh:mm:ss').slice(0, 8);
    };

    //si el promedio es un numero lo convierte en formato hh:mm:ss
    if (!isNaN(promedioRespuestaUnMes)) {
        promedioRespuestaUnMes = TimeFormat.fromS(promedioRespuestaUnMes / 1000, 'hh:mm:ss').slice(0, 8);
    };

    unMes.forEach(ele => {
        accionesPorNumeroTelefonico.push(
            {
                phoneNumber: ele.phoneNumber,
                option: ele.option
            }
        )
    });

    //Usuarios que completaron sus datos y usuarios que no.
    let CompletaronSusDatos = [];
    let noCompletaronFull = [];

    accionesPorNumeroTelefonico.forEach(ele => {
        if (ele.option == "Ha cargado todos los datos") {
            CompletaronSusDatos.push(ele.phoneNumber)
        }
        else if (ele.option != "Ha cargado todos los datos") {
            noCompletaronFull.push(ele.phoneNumber)
        }
    });

    let noCompletaron = new Set(noCompletaronFull);
    let noCompletaroArray = [...noCompletaron];
    let NoCompletaronSusDatos = noCompletaroArray.filter(ele => !CompletaronSusDatos.includes(ele));

    //Han iniciado la conversacion en una semana va rotando el orden dependiendo el día de la semana.
    //ESTA NO SE TOCA 
    for (let i = 0; i < unaSemana.length; i++) {
        if ((new Date(unaSemana[i].timestamp).getDay() - dayToday - 1) >= 0) {
            if (unaSemana[i].option == 'Ha iniciado la conversación') {
                InicioConversacion[dayToday][new Date(unaSemana[i].timestamp).getDay() - dayToday - 1].valor += 1
            }
        } else {
            if (unaSemana[i].option == 'Ha iniciado la conversación') {
                InicioConversacion[dayToday][7 + (new Date(unaSemana[i].timestamp).getDay() - dayToday - 1)].valor += 1
            }
        }
    };
    //---------------------------------------
    //Suma las opciones marcadas por semana  
    unaSemana.forEach(ele => {
        if (ele.option == "Abandono la interaccion") {
            abandonaronLaConversacionSemana += 1
        } else if (ele.option == "Abandono la interaccion por limite de tiempo") {
            abandonaronLaConversacionTiempoSemana += 1
        }
    });
    //Llamadas totales atendidas y abandonadas en un mes.
    const totalAbandonadasMes = valoresUnMes.abandono + valoresUnMes.abandonoPorTiempo;
    const totalAtendidasMes = valoresUnMes.fueDerivado + valoresUnMes.fueraDeHorario;
    const totalInteraccionesMes = totalAbandonadasMes + totalAtendidasMes;
    //Variables para graficos abandonados vs abadonados por tiempo en el MES.
    const porcentajeAbadonaron = Math.round((totalAbandonadasMes * 100) / totalInteraccionesMes);
    const porcentajeAtendieron = Math.round((totalAtendidasMes * 100) / totalInteraccionesMes);
    const decidieronAbandonarMensual = Math.round((valoresUnMes.abandono * 100) / totalAbandonadasMes);
    const abandonaronPorTiempoMensual = Math.round((valoresUnMes.abandonoPorTiempo * 100) / totalAbandonadasMes);
    //llamadas totales y atendidas en una semana
    const totalesAbandonadasSemana = abandonaronLaConversacionSemana + abandonaronLaConversacionTiempoSemana;
    const totalAtendidasSemana = valoresUnaSemana.fueDerivado + valoresUnaSemana.fueraDeHorario;
    const totalinteraccionesSemana = totalAtendidasSemana + totalesAbandonadasSemana;
    const porcentajeAbadonoSemana = Math.round((totalesAbandonadasSemana * 100) / totalinteraccionesSemana);
    const porcentajeAtendidoSemana = Math.round((totalAtendidasSemana * 100) / totalinteraccionesSemana);


    // Ha inciado una conversacion por dia de la semana.
    const IniciaConversacionDia = InicioConversacion[dayToday].map(ele => ele.dia)
    const IniciaConversacionValor = InicioConversacion[dayToday].map(ele => ele.valor)

    // todas las opciones semanales

    ChartJS.register(CategoryScale, LinearScale, RadialLinearScale, PointElement, BarElement, PointElement, ArcElement, LineElement, Filler, Title, Tooltip, Legend);

    //###########################################################################
    //      ACA EMPIEZAN LAS VARIABLES FILTRADAS POR FECHA:
    //###########################################################################
    //###########################################################################

    let opcionesMarcadasEntreFechas = {
        otrosMotivos: 0,
        fueDerivado: 0,
        fueraDeHorario: 0,
        inicio: 0,
        cargo: 0,
        abandono: 0,
        abandonoTiempo: 0,
        promedioCargo: 0,
        incorrectos: 0
    };
    let opcionesVariablesEntreFechas = [];

    let numerosTelefonicosOpcion = [];

    let tiempoDeRespuesta = {
        unaHora: 0,
        doceHoras: 0,
        veinticuatroHoras: 0,
        promedio: 0,
        promedioCargaDatos: 0
    };

    let hashVariable = {};
    //Variables para las calcular quienes completaron sus datos y quienes no
    let completaron = [];
    let nocompletaron = [];
    //para filtar las opciones unicas

    let objetoOpcionesUnicas2 = [];

    //Aca se aplica el filtro por fechas
    if (startDate != 0 && endDate != 0) {
        startDate = startDate + 10800000;
        endDate = endDate + 10800000 + 86399900;

        if (endDate - startDate > 2629743000 + 86399900) {
            alert("Solo se debe filtrar un mes de diferencia");
            reset(fechaInicio, fechaFinal);
        } else {

            Oldcharts.forEach((ele) => {
                if (ele.timestamp >= startDate && ele.timestamp <= endDate) {
                    numerosTelefonicosOpcion.push({
                        numero: ele.phoneNumber,
                        option: ele.option
                    });

                    if (ele.timeBefore > 0) {
                        tiempoDeRespuesta.promedio += ele.timebetween;

                        if (ele.timebetween <= 3600000) {

                            tiempoDeRespuesta.unaHora += 1
                        } else if (ele.timebetween <= 4320000) {
                            tiempoDeRespuesta.doceHoras += 1
                        } else if (ele.timebetween <= 86399999) {
                            tiempoDeRespuesta.veinticuatroHoras += 1
                        }

                        if (ele.option == "Ha cargado todos los datos") {
                            opcionesMarcadasEntreFechas.cargo += 1;
                            opcionesMarcadasEntreFechas.promedioCargo += ele.timebetween;
                        }
                    }

                    if (ele.option !== "Otros motivos" &&
                        ele.option !== "Fue derivado a un agente" &&
                        ele.option !== "Ha iniciado la conversación" &&
                        ele.option !== "Abandono la interaccion" &&
                        ele.option !== "Abandono la interaccion por limite de tiempo" &&
                        ele.option !== "Ha ingresado una opción incorrecta" &&
                        ele.option !== "Ha cargado todos los datos") {
                        opcionesVariablesEntreFechas.push({
                            option: ele.option,
                            isChart: ele.isChart
                        }

                        )
                    }
                    if (ele.option === "Otros motivos") {
                        opcionesMarcadasEntreFechas.otrosMotivos += 1
                    } else if (ele.option === "Fue derivado a un agente") {
                        opcionesMarcadasEntreFechas.fueDerivado += 1
                    } else if (ele.option === "Se contactó fuera de horario") {
                        opcionesMarcadasEntreFechas.fueraDeHorario += 1
                    } else if (ele.option === "Ha iniciado la conversación") {
                        opcionesMarcadasEntreFechas.inicio += 1;
                    } else if (ele.option === "Abandono la interaccion") {
                        opcionesMarcadasEntreFechas.abandono += 1
                    } else if (ele.option === "Abandono la interaccion por limite de tiempo") {
                        opcionesMarcadasEntreFechas.abandonoTiempo += 1
                    } else if (ele.option.match("Ha ingresado una opción incorrecta")) {
                        opcionesMarcadasEntreFechas.incorrectos += 1
                    }
                }
            })



        }


    };


    let objetoOpcionesUnicas = opcionesVariablesEntreFechas.filter(ele => hashVariable[ele.option] ? false : hashVariable[ele.option] = true);

    objetoOpcionesUnicas = objetoOpcionesUnicas.filter(ele => ele.isChart == "true");

    objetoOpcionesUnicas.forEach((ele) => {
        objetoOpcionesUnicas2.push({
            option: ele.option,
            value: 0
        })
    });

    for (let i = 0; i < objetoOpcionesUnicas.length; i++) {
        opcionesVariablesEntreFechas.forEach((ele) => {
            if (objetoOpcionesUnicas2[i].option == ele.option) {
                objetoOpcionesUnicas2[i].value += 1
            }
        });
    }
    //Se calculan los promedios de respuestas
    let betweenDays = startDate > 0 ? dateFormat(startDate, "dd/mm/yyyy") + " - " + dateFormat(endDate, "dd/mm/yyyy") : " ";
    let promedioRespuesta = Math.round(tiempoDeRespuesta.promedio / tiempoDeRespuesta.unaHora + tiempoDeRespuesta.doceHoras + tiempoDeRespuesta.veinticuatroHoras);
    let promediocargoEntreFecha = Math.round(opcionesMarcadasEntreFechas.promedioCargo / opcionesMarcadasEntreFechas.cargo)

    let promedio2 = 0;
    let promediocargoEntreFecha2 = 0;

    // Se asigna un valor a los promedios de respuesta
    if (!isNaN(promediocargoEntreFecha)) {
        promediocargoEntreFecha2 = TimeFormat.fromS(promediocargoEntreFecha / 1000, 'hh:mm:ss').slice(0, 8);
    }
    if (!isNaN(promedioRespuesta)) {
        promedio2 = TimeFormat.fromS(promedioRespuesta / 1000, 'hh:mm:ss').slice(0, 8);
    };

    numerosTelefonicosOpcion.forEach((ele) => {
        if (ele.option == "Ha cargado todos los datos") {
            completaron.push(ele.numero)
        } else if (ele.option != "Ha cargado todos los datos") {
            nocompletaron.push(ele.numero)
        }
    });

    //Personas que completaron sus datos
    let nocompletaron2 = new Set(nocompletaron);
    let nocompletaron3 = [...nocompletaron2];
    let nocompletaron4 = nocompletaron3.filter(ele => !completaron.includes(ele));
    //Porcentaje para las ultimas graficas, asigna el titulo dependiendo del valor del porcentaje
    let abandonaronPorcentaje = Math.round((opcionesMarcadasEntreFechas.abandono + opcionesMarcadasEntreFechas.abandonoTiempo) * 100 / (opcionesMarcadasEntreFechas.abandono + opcionesMarcadasEntreFechas.abandonoTiempo + opcionesMarcadasEntreFechas.fueDerivado + opcionesMarcadasEntreFechas.fueraDeHorario))
    let atendieronPorcentaje = Math.round((opcionesMarcadasEntreFechas.fueDerivado + opcionesMarcadasEntreFechas.fueraDeHorario) * 100 / (opcionesMarcadasEntreFechas.abandono + opcionesMarcadasEntreFechas.abandonoTiempo + opcionesMarcadasEntreFechas.fueDerivado + opcionesMarcadasEntreFechas.fueraDeHorario))

    let abandonadoTitulo = isNaN(abandonaronPorcentaje) ? "Abandonaron (0%)" : `Abandonaron (${abandonaronPorcentaje}%)`;
    let atendidasTitulo = isNaN(atendieronPorcentaje) ? "Atendieron (0%)" : `Atendieron (${atendieronPorcentaje}%)`;
    //Abandonaron por fecha y desicidieron abandonar
    let porcentajeAbandonaronDesicion = Math.round(opcionesMarcadasEntreFechas.abandono * 100 / (opcionesMarcadasEntreFechas.abandono + opcionesMarcadasEntreFechas.abandonoTiempo));
    let porcentajeAbadonaronTiempo = Math.round(opcionesMarcadasEntreFechas.abandonoTiempo * 100 / (opcionesMarcadasEntreFechas.abandono + opcionesMarcadasEntreFechas.abandonoTiempo));

    let abandonadasPorDecision = isNaN(porcentajeAbandonaronDesicion) ? "Abandoron por desición (0%)" : `Abandonadas por desición (${porcentajeAbandonaronDesicion}%)`;
    let abandonadasPorTiempo = isNaN(porcentajeAbadonaronTiempo) ? "Abandonadas por tiempo (0)%" : `Abandonadas por tiempo (${porcentajeAbadonaronTiempo}%)`;

    let abandonadasDesicionTreintaDias = isNaN(decidieronAbandonarMensual) ? "Por desición (0%)" : `Por desición (${decidieronAbandonarMensual}%)`;
    let abandonadasPorTiempoTreintaDias = isNaN(abandonaronPorTiempoMensual) ? "Por Tiempo (0%)" : `Por tiempo (${abandonaronPorTiempoMensual}%)`;

    //GRAFICO VARIABLE POR FECHA:

    //Abandonaron vs Abandonaron por tiempo
    const optAbandonoPorFecha = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                display: false,
            },
            title: {
                display: true,
                text: `${language === "english" ? "Abandonos entre las fechas: " : "Abandonos entre las fechas: " + betweenDays}`,
            },
        },
    };
    const dataAbandonoPorFecha = {
        labels: [abandonadasPorDecision, abandonadasPorTiempo],
        datasets: [
            {
                data: [porcentajeAbandonaronDesicion, porcentajeAbadonaronTiempo],
                backgroundColor: [
                    "#ffc107"
                ],
                datalabels: {
                    color: 'hsl(227, 47%, 96%)',
                    formatter: function (value, context) {
                        return value + "%";
                    },
                    font: {
                        weight: 'bold',
                        size: 16,

                    },
                },
            },
        ],

    };

    //Abandonaron vs Atendidas
    const optAbandonoVsAtendidas = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                display: false,
            },
            title: {
                display: true,
                text: `${language === "english" ? "Abandonos entre las fechas: " : "Abandonos vs Atendidas: " + betweenDays}`,
            },
        },
    };

    const dataAbandonoVsAtendidasPorFecha = {
        labels:
            [abandonadoTitulo, atendidasTitulo],
        datasets: [
            {
                data: [abandonaronPorcentaje, atendieronPorcentaje],
                backgroundColor: ['rgba(75, 192, 192)', 'rgba(153, 102, 255)'],
                datalabels: {
                    color: 'hsl(227, 47%, 96%)',

                    font: {
                        weight: 'bold',
                        size: 16,

                    },
                },
            },


        ],


    };
    //Seleccion de diferentes opciones por fecha Filtrada 
    const optionesPorFechaFiltrada = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: `${language === "english" ? "Selection of options (30 days)" : "Selección de opciones entre: " + betweenDays}`,
            },
        },
    };

    const dataPorFechaFiltrada = {
        labels: objetoOpcionesUnicas2.map(ele => ele.option),
        datasets: [
            {
                label: 'Selección de opciones',
                data: objetoOpcionesUnicas2.map(ele => ele.value),
                backgroundColor: [
                    'rgb(255, 99, 132)',
                    'rgb(54, 162, 245)',
                    'rgb(255, 206, 86)',
                    'rgb(165, 199, 139)',
                    'rgb(153, 102, 255)',
                    'rgb(75, 192, 192)',
                    'rgb(155, 131, 189)',
                    'rgb(155, 184, 166)',
                    'rgb(210, 203, 113)',
                    'rgb(114, 147, 224)',
                ],
                hoverOffset: 10,
            },
        ]
    };

    // Fue derivado a un agente vs se contacto fuera de horario
    const optionsFueDerivadoFueraHorario = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: `${language === "english" ? "Selection of options (30 days)" : "Derivado a un agente vs Fuera de Horario entre: " + betweenDays}`,
            },
        },
    };

    const dataFueDerivadoFueraHorario = {
        labels: ["Fue derivado a un agente", "Se contacto fuera de horario"],
        datasets: [
            {
                label: 'Selección de opciones',
                data: [opcionesMarcadasEntreFechas.fueDerivado, opcionesMarcadasEntreFechas.fueraDeHorario],
                backgroundColor: [
                    'rgba(255, 99, 132)',
                    'rgba(54, 162, 235)',
                    'rgba(255, 206, 86)',
                    'rgba(75, 192, 192)',
                    'rgba(153, 102, 255)',
                ],
                hoverOffset: 10,
            },
        ]
    };
    //Completaron sus datos vs No completaron sus datos.
    const optCompletaroVsNo = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: `${language === "english" ? "Completed data between days " + betweenDays : "Datos completados entre: " + betweenDays}`,
            },
        },
    }

    const dataCompletaronVsNo = {
        labels: ["No completaron sus datos", "Completaron sus datos"],
        datasets: [
            {
                label: 'Selección de opciones',
                data: [nocompletaron4.length, completaron.length],
                backgroundColor: [
                    'rgba(255, 99, 132)',
                    'rgba(54, 162, 235)',
                ],
                hoverOffset: 10,
            },
        ]
    }

    //-------
    //Opciones seleccionadas en un mes, primer Grafico 
    const options30Days = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: `${language === "english" ? "Selection of options (30 days)" : "Selección de opciones (30 días)"}`,
            },
        },
    };


    const data30Days = {
        labels: opcionesContadasPorMes.map(ele => ele.name),
        datasets: [
            {
                label: 'Selección de opciones',
                data: opcionesContadasPorMes.map(ele => ele.value),
                // backgroundColor: [
                //     'rgb(255, 99, 132)',
                //     'rgb(155, 131, 189)',
                //     'rgb(54, 162, 245)',
                //     'rgb(155, 184, 166)',
                //     'rgb(255, 206, 86)',
                //     'rgb(210, 203, 113)',
                //     'rgb(165, 199, 139)',
                //     'rgb(75, 192, 192)',
                //     'rgb(114, 147, 224)',
                //     'rgb(153, 102, 255)',
                // ],
                backgroundColor: [
                    'rgb(255, 99, 132)',
                    'rgb(54, 162, 245)',
                    'rgb(255, 206, 86)',
                    'rgb(165, 199, 139)',
                    'rgb(153, 102, 255)',
                    'rgb(75, 192, 192)',
                    'rgb(155, 131, 189)',
                    'rgb(155, 184, 166)',
                    'rgb(210, 203, 113)',
                    'rgb(114, 147, 224)',
                ],
                hoverOffset: 5,
            },
        ]
    };

    //Opciones seleccionadas en una semana, primer Grafico 
    const options7Days = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: `${language === "english" ? "Selection of options (7 days)" : "Selección de opciones (7 días)"}`,
            },
        },
    };


    // console.log("opcionesContadasPorSemana:", opcionesContadasPorSemana)

    const data7Days = {

        labels: opcionesContadasPorSemana.map(ele => ele.name),
        datasets: [
            {
                label: 'Selección de opciones',
                data: opcionesContadasPorSemana.map(ele => ele.value),
                // backgroundColor: [
                //     'rgb(255, 99, 132)',
                //     'rgb(155, 131, 189)',
                //     'rgb(54, 162, 245)',
                //     'rgb(155, 184, 166)',
                //     'rgb(255, 206, 86)',
                //     'rgb(210, 203, 113)',
                //     'rgb(165, 199, 139)',
                //     'rgb(75, 192, 192)',
                //     'rgb(114, 147, 224)',
                //     'rgb(153, 102, 255)',
                // ],

                backgroundColor: [
                    'rgb(255, 99, 132)',
                    'rgb(54, 162, 245)',
                    'rgb(255, 206, 86)',
                    'rgb(165, 199, 139)',
                    'rgb(153, 102, 255)',
                    'rgb(75, 192, 192)',
                    'rgb(155, 131, 189)',
                    'rgb(155, 184, 166)',
                    'rgb(210, 203, 113)',
                    'rgb(114, 147, 224)',
                ],
                hoverOffset: 5,
            },
        ]
    };





    //Opciones seleccionadas en una semana polizas, primer Grafico 
    const optionsPoliza7dias = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: `${language === "english" ? "Selection of options (7 days)" : "Descarga de pólizas (7 días)"}`,
            },
        },
    };




    // console.log("opcionesContadasPorSemanaPolizas:", opcionesContadasPorSemanaPolizas)
    const dataPoliza7dias = {
        labels: opcionesContadasPorSemanaPolizas.map(ele => ele.name),
        datasets: [
            {
                label: 'Selección de opciones',
                data: opcionesContadasPorSemanaPolizas.map(ele => ele.value),
                backgroundColor: [
                    'rgba(75, 192, 192)',
                    'rgba(255, 99, 132)',
                ],
                hoverOffset: 5,
            },
        ]
    };



    //Opciones seleccionadas en una semana siniestros, primer Grafico 
    const optionsSiniestros7dias = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: `${language === "english" ? "Selection of options (7 days)" : "Siniestros (7 días)"}`,
            },
        },
    };


    const dataSiniestros7dias = {
        labels: opcionesContadasPorSemanaSiniestros.map(ele => ele.name),
        datasets: [
            {
                label: 'Selección de opciones',
                data: opcionesContadasPorSemanaSiniestros.map(ele => ele.value),
                backgroundColor: [
                    'rgba(54, 162, 235)',
                    'rgba(153, 102, 255)',
                ],
                hoverOffset: 5,
            },
        ]
    };







    // Se revolvio en el Bot VS  Fue derivado aun agente 
    const optionsBotvsAgente = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: `${language === "english" ? "Solved by chatbot vs Ask for an agent (30 days)" : "Resuelto por chatbot vs Solicitó un agente (30 días)"}`,
            },
        },
    };

    let resueltosSinAgente = valoresUnMes.inicioConversacion - valoresUnMes.fueDerivado - valoresUnMes.fueraDeHorario
    let resueltosConAgente = valoresUnMes.fueDerivado + valoresUnMes.fueraDeHorario

    const dataBotvsAgente = {
        labels: ["Resuelto por chatbot", "Solicitó un agente"],
        datasets: [
            {
                label: `${language === "english" ? "Solved by chatbot vs Ask for an agent" : "Resuelto por chatbot vs Solicitó un agente"}`,
                data: [resueltosSinAgente, resueltosConAgente],
                backgroundColor: [
                    'rgba(75, 192, 192)',
                    'rgba(255, 206, 86)',
                ],
                hoverOffset: 5
            }
        ],
    };




    // Se revolvio en el Bot VS  Fue derivado aun agente 7 dias
    const optionsBotvsAgente7dias = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: `${language === "english" ? "Solved by chatbot vs Ask for an agent (7 days)" : "Resuelto por chatbot vs Solicitó un agente (7 días)"}`,
            },
        },
    };

    let resueltosSinAgente7dias = valoresUnaSemana.inicioConversacion - valoresUnaSemana.fueDerivado - valoresUnaSemana.fueraDeHorario
    let resueltosConAgente7dias = valoresUnaSemana.fueDerivado + valoresUnaSemana.fueraDeHorario

    const dataBotvsAgente7dias = {
        labels: ["Resuelto por chatbot", "Solicitó un agente"],
        datasets: [
            {
                label: `${language === "english" ? "Solved by chatbot vs Ask for an agent" : "Resuelto por chatbot vs Solicitó un agente"}`,
                data: [resueltosSinAgente7dias, resueltosConAgente7dias],
                backgroundColor: [
                    'rgba(75, 192, 192)',
                    'rgba(255, 206, 86)',
                ],
                hoverOffset: 5
            }
        ],
    };



    //2 Fue derivado aun agente Vs se Contacto fuera de horario
    const optionsAgentevsFuera = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: `${language === "english" ? "Referred to an agent vs Out of hours (30 days)" : "Derivado a un agente vs Fuera de horario (30 días)"}`,
            },
        },
    };
    const dataAgentesvsFuera = {
        labels: ["Fue derivado a un agente", "Se contacto fuera de horario"],
        datasets: [
            {
                label: `${language === "english" ? "Referred to an agent vs Out of hours" : "Derivado a un agente vs Fuera de horario"}`,
                data: [valoresUnMes.fueDerivado, valoresUnMes.fueraDeHorario],
                backgroundColor: [
                    'rgba(255, 206, 86)',
                    'rgba(255, 99, 132)',
                ],
                hoverOffset: 5
            }
        ],
    };



    //2 Fue derivado aun agente Vs se Contacto fuera de horario
    const optionsAgentevsFuera7dias = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: `${language === "english" ? "Referred to an agent vs Out of hours (7 days)" : "Derivado a un agente vs Fuera de horario (7 días)"}`,
            },
        },
    };
    const dataAgentesvsFuera7dias = {
        labels: ["Fue derivado a un agente", "Se contacto fuera de horario"],
        datasets: [
            {
                label: `${language === "english" ? "Referred to an agent vs Out of hours" : "Derivado a un agente vs Fuera de horario"}`,
                data: [valoresUnaSemana.fueDerivado, valoresUnaSemana.fueraDeHorario],
                backgroundColor: [
                    'rgba(255, 206, 86)',
                    'rgba(255, 99, 132)',
                ],
                hoverOffset: 5
            }
        ],
    };












    //Usuarios en 30 dias que completaron sus datos y usuarios que no.
    const optionsCompletaron = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: `${language === "english" ? "Completed data (30 days)" : "Datos completados (30 días)"}`,
            },
        },
    };

    const dataCompletaron = {
        labels: ["No completaron sus datos", "Completaron sus Datos"],
        datasets: [
            {
                label: 'Selección de opciones',
                data: [NoCompletaronSusDatos.length, CompletaronSusDatos.length],
                backgroundColor: [
                    'rgba(255, 99, 132)',
                    'rgba(54, 162, 235)',
                ],
                hoverOffset: 5
            }
        ],
    }

    //3 Han iniciado la conversacion en los ultimos 7 días.

    const optionsBarraNuevasInteracciones = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: `${language === "english" ? "New interactions (7 days)" : "Nuevas interacciones (7 días)"}`,
            },
        },
    };

    const dataBarraNuevasInteracciones = {
        labels: IniciaConversacionDia,
        datasets: [
            {
                label: `${language === "english" ? "Quantity per day" : "Cantidad por día"}`,
                data: IniciaConversacionValor,
                backgroundColor: 'rgba(255, 99, 132)',

            },
        ],
    };
    // Todas las opciones seleccionadas en los ultimos 7 días

    const optionsBarraSemanal = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: `${language === "english" ? "Selection of options (7 days)" : "Selección de opciones (7 días)"}`,
            },
        },
    };
    const dataBarraTodasOpcionesSemanal = {
        labels: opcionesContadasPorSemana.map(ele => ele.name),
        datasets: [
            {
                label: `${language === "english" ? "Total quantity" : "Cantidad total"}`,
                data: opcionesContadasPorSemana.map(ele => ele.value),
                backgroundColor: 'rgba(54, 162, 235)',
            },
        ],
    }


    //Porcentaje de abandonadas en vs abandonadas por tiempo
    const optionsPorcentajeMensualAbandono = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                display: false,
            },
            title: {
                display: true,
                text: `${language === "english" ? "Abandonos (30 días)" : "Abandonos (30 días)"}`,
            },
        },
    };

    const porcentajeDataMensual = {
        labels: [abandonadasDesicionTreintaDias, abandonadasPorTiempoTreintaDias],
        datasets: [
            {
                label: `${language === "english" ? "Total quantity" : "Cantidad total"}`,
                data: [decidieronAbandonarMensual, abandonaronPorTiempoMensual],
                backgroundColor: 'rgba(255, 206, 86)'
            },

        ],
    };

    //interacciones Semanales atendidas vs abandonadas

    const optionsPorcentajeSemanal = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                display: false,
            },
            title: {
                display: true,
                text: `${language === "english" ? "Abandonos vs Atendidas (7 días)" : "Abandonos vs Atendidas (7 días)"}`,
            },
        },
    };

    let abandonadasSemanales = isNaN(porcentajeAbadonoSemana) ? "Abandonadas (0)%" : `Abandonadas (${porcentajeAbadonoSemana}%)`;
    let atendidasSemanales = isNaN(porcentajeAtendidoSemana) ? "Atendidas (0)%" : `Atendidas (${porcentajeAtendidoSemana}%)`;
    const porcentajeDataSemanal = {
        labels: [abandonadasSemanales, atendidasSemanales],
        datasets: [

            {
                data: [porcentajeAbadonoSemana, porcentajeAtendidoSemana],
                backgroundColor: ['rgba(75, 192, 192)', 'rgba(153, 102, 255)'],
            },
        ],
    };

    //Llamadas atendidas vs llamadas abandonadas en los ultimos 30 dias
    const optionsAbandonadasMensual = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                display: false,
            },
            title: {
                display: true,
                text: `${language === "english" ? "Abandonos vs Atendidas (30 días)" : "Abandonos vs Atendidas (30 días)"}`,
            },
        },
    };
    let abandonadasPorcentajeMes = isNaN(porcentajeAbadonaron) ? "Abandonadas (0)%" : `Abandonadas (${porcentajeAbadonaron}%)`
    let atendieronPorcentajeMes = isNaN(porcentajeAtendieron) ? "Atendidas (0%)" : `Atendidas (${porcentajeAtendieron}%)`;
    const dataAbandonadasMensual = {
        //   labels: ["Porcentaje de interacciones"],
        labels: [abandonadasPorcentajeMes, atendieronPorcentajeMes],
        datasets: [

            {
                data: [porcentajeAbadonaron, porcentajeAtendieron],
                // backgroundColor: 'rgba(75, 192, 192)', 
                backgroundColor: ['rgba(75, 192, 192)', 'rgba(153, 102, 255)'],
            },
        ],
    };



    const styles = {
        invisible: {
            display: "none"
        },
        backColor: {
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            height: "100%",
            backgroundColor: `${darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"}`,
        },
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 800 ? ("3% 2% 3% 20%") : ("30px")}`,
            padding: `${screenSize > 800 ? "3% 2% 0px 280px" : "30px"}`,
            height: "100%",
            //   overflow: `auto`,
        },
        containerLoader: {
            display: "flex",
            alignItem: "center",
            justifyContent: "center",
            height: "80%",
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 800 ? "0px 20px" : "0px"}`,
            marginBottom: `${screenSize > 800 ? "0px" : "30px"}`,
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
        },
        titleNavbar: {
            marginBottom: `${screenSize > 800 ? "5px" : "10px"}`,
            fontSize: "28px",
            color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
            fontFamily: "InterBold",
            lineHeight: "1.4",
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            fontFamily: "InterBold",
        },
        subtitleSurveys: {
            margin: "20px 20px 10px",
            fontSize: "20px",
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            fontFamily: "InterBold",
        },
        toogleNavbar: {
            display: "flex",
            flex: 4,
            alignItems: "center",
            justifyContent: `${screenSize > 800 ? "flex-end" : "center"}`,
            flexDirection: `${screenSize > 800 ? "row" : "column"}`,
        },
        textToogle: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: "inherit",
            border: "none",
            outline: "none",
            padding: "12px",
            backgroundColor: `transparent`,
        },
        textUser: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: "inherit",
            border: "none",
            outline: "none",
            padding: 0,
            backgroundColor: `transparent`,
        },
        buttonNew: {
            textDecoration: "none",
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: "none",
            margin: "20px",
            // margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
            width: `${screenSize > 800 ? "180px" : <></>}`,
        },
        reports: {
            display: "flex",
            flex: 11,
            flexDirection: 'column',
            margin: `${screenSize > 800 ? "0px 20px" : "0px"}`,
            overflow: `auto`,
            padding: '0px 0px 50px 0px',
            borderRadius: "10px",
            // backgroundColor: 'red'
        },
        singleLineReport: {
            display: "flex",
            flexDirection: 'row',
        },
        singleDoughnutChart: {
            //       display: "flex",
            flex: 1,
            alignItems: "flex-start",
            justifyContent: "flex-start",
            borderRadius: "10px",
            //  backgroundColor: 'red',
            padding: "25px",
            textDecoration: "none",
            border: "none",
            outline: "none",
            margin: `${screenSize > 800 ? "5px" : "10px"}`,
            width: '100px'
        },
        singleBarCharts: {
            flex: 1,
            alignItems: "flex-start",
            justifyContent: "flex-start",
            borderRadius: "10px",
            //   backgroundColor: 'red',
            padding: "25px",
            textDecoration: "none",
            border: "none",
            outline: "none",
            margin: `${screenSize > 800 ? "5px" : "10px"}`,
            width: '100px',
            //  height: '500px'
        },
        singleIndicator: {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            alignItems: "flex-start",
            justifyContent: "flex-start",
            borderRadius: "10px",
            //    backgroundColor: 'red',
            padding: "18px 25px",
            textDecoration: "none",
            border: "none",
            outline: "none",
            margin: `${screenSize > 800 ? "5px" : "10px"}`,
            //       backgroundColor: 'red'
        },
        singleIndicatorInfo: {
            flex: 2,
            flexDirection: 'column',
            //       backgroundColor: 'green'
        },
        singleIndicatorTitle: {
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            margin: "0px 0px 5px",
            fontSize: "18px",
            fontFamily: "InterBold",
            textAlign: "start",
        },
        singleIndicatorSubtitle: {
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            margin: "0px",
            fontSize: "12px",
            fontFamily: "InterBold",
            textAlign: "start",
        },
        singleIndicatorNumber: {
            flex: 1,
            alignSelf: 'flex-end',
            color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
            margin: "0px",
            fontSize: "40px",
            fontFamily: "InterBold",
            textAlign: "end",
            //     backgroundColor: 'blue'
        },


        //---------------------------------------------------------------


        lineDetail: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        phoneNumber: {
            display: "flex",
            flex: 4,
            color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
            margin: "0px",
            fontSize: "18px",
            fontFamily: "InterBold",
            textAlign: "start",
            alignSelf: "center",
        },
        option: {
            display: "flex",
            flex: 6,
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            margin: 0,
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
        },
        date: {
            display: "flex",
            flex: 2,
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            margin: 0,
            fontSize: "14px",
            fontFamily: "InterBold",
            // textAlign: 'end'
            alignSelf: "center",
            justifyContent: "flex-end",
        },
        loader: {
            display: "flex",
            alignSelf: "center",
        },
        try: {
            position: "absolute",
        },
        filters: {
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "center",
            margin: `${screenSize > 800 ? "0px 25px 20px" : "0px"}`,
        },
        filterGroup: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            padding: '0px 20px 0px 0px'
        },
        filterDateGroup: {
            display: "flex",
            flexDirection: "row",
        },
        filterLabel: {
            fontFamily: "InterBold",
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            padding: '0px 5px'
        },
        filterInputDate: {
            border: 'none',
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            fontFamily: "InterBold",
            width: '100%',
            height: '40px',
            borderRadius: "5px",
            padding: '5px 10px',
            margin: '0px 10px 0px 0px'
        },
        filterInput: {
            border: 'none',
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            fontFamily: "InterBold",
            width: '100%',
            height: '40px',
            borderRadius: "5px",
            padding: '5px 10px'
        },
        filterButton: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textDecoration: "none",
            outline: 0,
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: 'none',
            borderRadius: 5,
            margin: 0,
            padding: '8px 13px',
            width: '100px'
        },
        resetButton: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textDecoration: "none",
            outline: 0,
            color: "#4dc2f1",
            backgroundColor: "transparent",
            border: '1px #4dc2f1 solid',
            borderRadius: 5,
            margin: "0px 0px 0px 10px",
            padding: '8px 13px',
            width: '100px'
        },
    };
    return (
        <>
            <div style={styles.backColor} />
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <h3 style={styles.titleNavbar}>{language === "english" ? "Charts" : "Métricas"} 😃 </h3>
                        <h3 style={styles.subtitleNavbar}>{language === "english" ? "All interaction charts." : "Todas las métricas de interacciones."}</h3>
                    </div>
                </div>
                <div style={styles.filters}>
                    <div style={styles.filterGroup}>
                        <label style={styles.filterLabel}>{language === "english" ? "Date filter" : "Filtro por fecha"}</label>
                        <div style={styles.filterDateGroup}>
                            <input className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.filterInputDate} type="date" id="fechaInicio" />
                            <input className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.filterInput} type="date" id="fechaFinal" />

                        </div>
                    </div>
                    <button style={styles.filterButton} onClick={() => { handleClick(fechaInicio, fechaFinal) }}>{language === "english" ? "Apply" : "Filtrar"}</button>
                    <button style={styles.resetButton} onClick={() => { reset(fechaInicio, fechaFinal) }}>{language === "english" ? "Reset" : "Limpiar"}</button>
                </div>
                <div style={styles.reports}>
                    <div style={displayCharts == false ? styles.singleLineReport : styles.invisible}>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleIndicator}>
                            <div style={styles.singleIndicatorInfo}>
                                <div style={styles.singleIndicatorTitle}>{language === "english" ? "New interactions" : "Nuevas interacciones"}</div>
                                <div style={styles.singleIndicatorSubtitle}>{language === "english" ? "Last 30 days" : "Últimos 30 días"}</div>
                            </div>
                            <div style={styles.singleIndicatorNumber}>{valoresUnMes.inicioConversacion}</div>
                        </div>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleIndicator}>
                            <div style={styles.singleIndicatorInfo}>
                                <div style={styles.singleIndicatorTitle}>{language === "english" ? "New interactions" : "Nuevas interacciones"}</div>
                                <div style={styles.singleIndicatorSubtitle}>{language === "english" ? "Last 7 days" : "Últimos 7 días"}</div>
                            </div>
                            <div style={styles.singleIndicatorNumber}>{valoresUnaSemana.inicioConversacion}</div>
                        </div>
                    </div>


                    <div style={displayCharts == false ? styles.singleLineReport : styles.invisible}>
                        <h3 style={styles.subtitleSurveys}>Resumen</h3>
                    </div>


                    <div style={displayCharts == false ? styles.singleLineReport : styles.invisible}>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleBarCharts}><Bar options={optionsBarraNuevasInteracciones} data={dataBarraNuevasInteracciones} /></div>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleBarCharts}><Bar options={optionsBarraSemanal} data={dataBarraTodasOpcionesSemanal} /></div>
                    </div>



                    <div style={displayCharts == false ? styles.singleLineReport : styles.invisible}>
                        <h3 style={styles.subtitleSurveys}>Opciones totales</h3>
                    </div>

                    <div style={displayCharts == false ? styles.singleLineReport : styles.invisible}>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleDoughnutChart}><Doughnut options={options30Days} data={data30Days} /> </div>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleDoughnutChart}><Doughnut options={options7Days} data={data7Days} /> </div>
                        {/* <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleDoughnutChart}><Doughnut options={optionsAgentevsFuera} data={dataAgentesvsFuera} /></div> */}
                        {/* <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleDoughnutChart}><Doughnut options={optionsCompletaron} data={dataCompletaron} /></div> */}
                    </div>



                    <div style={displayCharts == false ? styles.singleLineReport : styles.invisible}>
                        <h3 style={styles.subtitleSurveys}>Promedios</h3>
                    </div>

                    <div style={displayCharts == false ? styles.singleLineReport : styles.invisible}>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleIndicator}>
                            <div style={styles.singleIndicatorInfo}>
                                <div style={styles.singleIndicatorTitle}>{language === "english" ? "Average time - Responses" : "Respuestas de clientes"}</div>
                                <div style={styles.singleIndicatorSubtitle}>{language === "english" ? "Last 30 days" : "Últimos 30 días"}</div>
                            </div>
                            <div style={styles.singleIndicatorNumber}>{promedioRespuestaUnMes}</div>
                        </div>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleIndicator}>
                            <div style={styles.singleIndicatorInfo}>
                                <div style={styles.singleIndicatorTitle}>{language === "english" ? "Average time - Data completed" : "Carga de datos"}</div>
                                <div style={styles.singleIndicatorSubtitle}>{language === "english" ? "Last 30 days" : "Últimos 30 días"}</div>
                            </div>
                            <div style={styles.singleIndicatorNumber}>{promedioDeCargaDatos}</div>
                        </div>
                    </div>





                    <div style={displayCharts == false ? styles.singleLineReport : styles.invisible}>
                        <h3 style={styles.subtitleSurveys}>Eficiencia</h3>
                    </div>

                    <div style={displayCharts == false ? styles.singleLineReport : styles.invisible}>
                        {/* <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleDoughnutChart}><Doughnut options={options30Days} data={data30Days} /> </div> */}
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleDoughnutChart}><Doughnut options={optionsBotvsAgente} data={dataBotvsAgente} /></div>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleDoughnutChart}><Doughnut options={optionsAgentevsFuera} data={dataAgentesvsFuera} /></div>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleDoughnutChart}><Doughnut options={optionsBotvsAgente7dias} data={dataBotvsAgente7dias} /></div>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleDoughnutChart}><Doughnut options={optionsAgentevsFuera7dias} data={dataAgentesvsFuera7dias} /></div>
                    </div>

                    <div style={displayCharts == false ? styles.singleLineReport : styles.invisible}>
                        <h3 style={styles.subtitleSurveys}>Opciones destacadas</h3>
                    </div>

                    <div style={displayCharts == false ? styles.singleLineReport : styles.invisible}>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleDoughnutChart}><Doughnut options={optionsPoliza7dias} data={dataPoliza7dias} /></div>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleDoughnutChart}><Doughnut options={optionsSiniestros7dias} data={dataSiniestros7dias} /></div>
                    </div>






                    {/* <div style={displayCharts == false ? styles.singleLineReport : styles.invisible}>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleIndicator}>
                            <div style={styles.singleIndicatorInfo}>
                                <div style={styles.singleIndicatorTitle}>{language === "english" ? "Total abandoned" : "Abandonos totales"}</div>
                                <div style={styles.singleIndicatorSubtitle}>{language === "english" ? "Last 30 days" : "Últimos 30 días"}</div>
                            </div>
                            <div style={styles.singleIndicatorNumber}>{totalAbandonadasMes}</div>
                        </div>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleIndicator}>
                            <div style={styles.singleIndicatorInfo}>
                                <div style={styles.singleIndicatorTitle}>{language === "english" ? "Abandoned by decision" : "Abandonos por decisión"}</div>
                                <div style={styles.singleIndicatorSubtitle}>{language === "english" ? "Last 30 days" : "Últimos 30 días"}</div>
                            </div>
                            <div style={styles.singleIndicatorNumber}>{valoresUnMes.abandono}</div>
                        </div>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleIndicator}>
                            <div style={styles.singleIndicatorInfo}>
                                <div style={styles.singleIndicatorTitle}>{language === "english" ? "Abandoned by time" : "Abandonos por tiempo"}</div>
                                <div style={styles.singleIndicatorSubtitle}>{language === "english" ? "Last 30 days" : "Últimos 30 días"}</div>
                            </div>
                            <div style={styles.singleIndicatorNumber}>{valoresUnMes.abandonoPorTiempo}</div>
                        </div>
                    </div> */}




                    {/* <div style={displayCharts == false ? styles.singleLineReport : styles.invisible}>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleBarCharts}><Bar options={optionsPorcentajeMensualAbandono} data={porcentajeDataMensual} /></div>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleBarCharts}><Bar options={optionsAbandonadasMensual} data={dataAbandonadasMensual} /></div>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleBarCharts}><Bar options={optionsPorcentajeSemanal} data={porcentajeDataSemanal} /></div>
                    </div> */}





                    {/* <div style={displayCharts == false ? styles.singleLineReport : styles.invisible}>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleIndicator}>
                            <div style={styles.singleIndicatorInfo}>
                                <div style={styles.singleIndicatorTitle}>{language === "english" ? "Response time" : "Tiempo de respuesta"}</div>
                                <div style={styles.singleIndicatorSubtitle}>{language === "english" ? "Between 0 and 1 hour" : "Entre 0 y 1 hora"}</div>
                            </div>
                            <div style={styles.singleIndicatorNumber}>{tiempoRespuestaUnMes.unahora}</div>
                        </div>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleIndicator}>
                            <div style={styles.singleIndicatorInfo}>
                                <div style={styles.singleIndicatorTitle}>{language === "english" ? "Response time" : "Tiempo de respuesta"}</div>
                                <div style={styles.singleIndicatorSubtitle}>{language === "english" ? "Between 1 and 12 hours" : "Entre 1 y 12 horas"}</div>
                            </div>
                            <div style={styles.singleIndicatorNumber}>{tiempoRespuestaUnMes.docehoras}</div>
                        </div>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleIndicator}>
                            <div style={styles.singleIndicatorInfo}>
                                <div style={styles.singleIndicatorTitle}>{language === "english" ? "Response time" : "Tiempo de respuesta"}</div>
                                <div style={styles.singleIndicatorSubtitle}>{language === "english" ? "Between 12 and 24 hours" : "Entre 12 y 24 horas"}</div>
                            </div>
                            <div style={styles.singleIndicatorNumber}>{tiempoRespuestaUnMes.veinticuatrohoras}</div>
                        </div>
                    </div> */}








                    <div style={displayCharts == false ? styles.invisible : styles.singleLineReport}>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleIndicator}>
                            <div style={styles.singleIndicatorInfo}>
                                <div style={styles.singleIndicatorTitle}>{language === "english" ? "New interactions" : "Nuevas interacciones"}</div>
                                <div style={styles.singleIndicatorSubtitle}>{betweenDays}</div>
                            </div>
                            <div style={styles.singleIndicatorNumber}>{opcionesMarcadasEntreFechas.inicio}</div>
                        </div>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleIndicator}>
                            <div style={styles.singleIndicatorInfo}>
                                <div style={styles.singleIndicatorTitle}>{language === "english" ? "Uploaded data" : "Datos cargados"}</div>
                                <div style={styles.singleIndicatorSubtitle}>{betweenDays}</div>
                            </div>
                            <div style={styles.singleIndicatorNumber}>{opcionesMarcadasEntreFechas.cargo}</div>
                        </div>
                        {/* <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleIndicator}>
                            <div style={styles.singleIndicatorInfo}>
                                <div style={styles.singleIndicatorTitle}>{language === "english" ? "Incorrect optiones" : "Opciones incorrectas"}</div>
                                <div style={styles.singleIndicatorSubtitle}>{betweenDays}</div>
                            </div>
                            <div style={styles.singleIndicatorNumber}>{opcionesMarcadasEntreFechas.incorrectos}</div>
                        </div> */}
                    </div>






                    <div style={displayCharts == false ? styles.invisible : styles.singleLineReport}>
                        <h3 style={styles.subtitleSurveys}>Opciones totales</h3>
                    </div>

                    <div style={displayCharts == false ? styles.invisible : styles.singleLineReport}>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleDoughnutChart}><Doughnut options={optionesPorFechaFiltrada} data={dataPorFechaFiltrada} /> </div>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleDoughnutChart}><Doughnut options={options30Days} data={data30Days} /> </div>
                        {/* <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleDoughnutChart}><Doughnut options={options7Days} data={data7Days} /> </div> */}
                    </div>
                    <div style={displayCharts == false ? styles.invisible : styles.singleLineReport}>





                        {/* <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleDoughnutChart}><Doughnut options={optionsFueDerivadoFueraHorario} data={dataFueDerivadoFueraHorario} /> </div> */}




                        {/* <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleIndicator}>
                            <div style={styles.singleIndicatorInfo}>
                                <div style={styles.singleIndicatorTitle}>{language === "english" ? "Total abandoned" : "Abandonos totales"}</div>
                                <div style={styles.singleIndicatorSubtitle}>{betweenDays}</div>
                            </div>
                            <div style={styles.singleIndicatorNumber}>{opcionesMarcadasEntreFechas.abandono + opcionesMarcadasEntreFechas.abandonoTiempo}</div>
                        </div>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleIndicator}>
                            <div style={styles.singleIndicatorInfo}>
                                <div style={styles.singleIndicatorTitle}>{language === "english" ? "Abandoned by decision" : "Abandonos por decisión"}</div>
                                <div style={styles.singleIndicatorSubtitle}>{betweenDays}</div>
                            </div>
                            <div style={styles.singleIndicatorNumber}>{opcionesMarcadasEntreFechas.abandono}</div>
                        </div>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleIndicator}>
                            <div style={styles.singleIndicatorInfo}>
                                <div style={styles.singleIndicatorTitle}>{language === "english" ? "Abandoned by time" : "Abandonos por tiempo"}</div>
                                <div style={styles.singleIndicatorSubtitle}>{betweenDays}</div>
                            </div>
                            <div style={styles.singleIndicatorNumber}>{opcionesMarcadasEntreFechas.abandonoTiempo}</div>
                        </div> */}
                    </div>
                    {/* <div style={displayCharts == false ? styles.invisible : styles.singleLineReport}>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleBarCharts}><Bar options={optAbandonoPorFecha} data={dataAbandonoPorFecha} /></div>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleBarCharts}><Bar options={optAbandonoVsAtendidas} data={dataAbandonoVsAtendidasPorFecha} /></div>
                    </div>
                    <div style={displayCharts == false ? styles.invisible : styles.singleLineReport}>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleIndicator}>
                            <div style={styles.singleIndicatorInfo}>
                                <div style={styles.singleIndicatorTitle}>{language === "english" ? "Response time" : "Tiempo de respuesta"}</div>
                                <div style={styles.singleIndicatorSubtitle}>{language === "english" ? "Between 0 and 1 hours" : "Entre 0 y 1 hora"}</div>
                            </div>
                            <div style={styles.singleIndicatorNumber}>{tiempoDeRespuesta.unaHora}</div>
                        </div>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleIndicator}>
                            <div style={styles.singleIndicatorInfo}>
                                <div style={styles.singleIndicatorTitle}>{language === "english" ? "Response time" : "Tiempo de respuesta"}</div>
                                <div style={styles.singleIndicatorSubtitle}>{language === "english" ? "Between 1 and 12 hours" : "Entre 1 y  12 horas"}</div>
                            </div>
                            <div style={styles.singleIndicatorNumber}>{tiempoDeRespuesta.doceHoras}</div>
                        </div>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleIndicator}>
                            <div style={styles.singleIndicatorInfo}>
                                <div style={styles.singleIndicatorTitle}>{language === "english" ? "Response time" : "Tiempo de respuesta"}</div>
                                <div style={styles.singleIndicatorSubtitle}>{language === "english" ? "Between 12 and 24 hours" : "Entre 12 y 24 horas"}</div>
                            </div>
                            <div style={styles.singleIndicatorNumber}>{tiempoDeRespuesta.veinticuatroHoras}</div>
                        </div>
                    </div> */}

                    <div style={displayCharts == false ? styles.invisible : styles.singleLineReport}>
                        <h3 style={styles.subtitleSurveys}>Promedios</h3>
                    </div>
                    <div style={displayCharts == false ? styles.invisible : styles.singleLineReport}>
                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleIndicator}>
                            <div style={styles.singleIndicatorInfo}>
                                <div style={styles.singleIndicatorTitle}>{language === "english" ? "Average time - Responses" : "Promedio de tiempo - Respuestas"}</div>
                                <div style={styles.singleIndicatorSubtitle}>{betweenDays}</div>
                            </div>
                            <div style={styles.singleIndicatorNumber}>{promedio2}</div>
                        </div>


                        <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleIndicator}>
                            <div style={styles.singleIndicatorInfo}>
                                <div style={styles.singleIndicatorTitle}>{language === "english" ? "Average time - Data completed" : "Promedio de tiempo - Carga de datos"}</div>
                                <div style={styles.singleIndicatorSubtitle}>{betweenDays}</div>
                            </div>
                            <div style={styles.singleIndicatorNumber}>{promediocargoEntreFecha2}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


