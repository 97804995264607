import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginUserPreferences } from "../store/actions/loginAction";

import Sidebar from "./Sidebar";
import SurveysBBVA from "../components/SurveysBBVA";

// import firebase from '../config/firebaseBBVA';

// const db = firebase.firestore();

const SurverysClient = () => {

    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [chartsBBVA, setChartsBBVA] = useState([]);
    const dispatch = useDispatch()
    const loginUser = useSelector(state => state.user.loginUser)
    useSelector(state => console.log(state.user));
    const companySurveys = useSelector(state => state.user.surveys)
    const language = useSelector(state => state.user.language)
    const darkTheme = useSelector(state => state.user.darkTheme)


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    useEffect(() => {
        setChartsBBVA(companySurveys)
    }, [companySurveys]);

    return (
        <div>
            <Sidebar />
            <SurveysBBVA
                screenSize={screenSize}
                darkModeState={darkTheme}
                language={language}
                chartsBBVA={chartsBBVA}
            />
        </div>
    );
}

export default SurverysClient;