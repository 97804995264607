import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginUserPreferences } from "../store/actions/loginAction";
import SurveyAnalytics from "../components/SurveyAnalytics";

import Sidebar from "./Sidebar";
import firebase from "../config/firebaseIO";

const db = firebase.firestore();

const SurveyAnalyticsContainer = () => {

    const [screenSize, setScreenSize] = useState(window.innerWidth);

    const [arrayDeOpciones, setArrayDeOpciones] = useState([]);
    const [optionFilter, setOptionFilter] = useState("");

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const [surveyAnswers, setAnswers] = useState([]);

    const [oldSurveys, setOldSurveys] = useState([]);
    const [surveyPolls, setSurveysPolls] = useState([]);

    const [surveyCampaigns, setSurveyCampaigns] = useState([]);
    const [surveyQuantityChat, setSurveyQuantityChat] = useState(0);
    const [surveyQuantityAudio, setSurveyQuantityAudio] = useState(0);

    const [filterOn, setFilterOn] = useState(false);
    const [displayCharts, setDisplayCharts] = useState(false);

    const loginUser = useSelector(state => state.user.loginUser)
    const language = useSelector(state => state.user.language)
    const darkTheme = useSelector(state => state.user.darkTheme)


    let getTime = new Date().getTime() / 1000;
    let oneWeekOld = getTime - 604800;
    // let oneDayOld = getTime - 86400; 


    const getPolls = async (startDate, endDate) => {
        if (filterOn == true) {
            try {
                return new Promise(async (resolve) => {
                    let surveys = []
                    let surveysTitles = []
                    let quantityChat = 0
                    let quantityAudio = 0
                    let oldPolls = await db
                        .collection("companies")
                        .doc(loginUser.company)
                        .collection("SURVEY_POLLS")
                        .get();
                    oldPolls.docs.forEach(async (singleSurvey) => {
                        await db
                            .collection("companies")
                            .doc(loginUser.company)
                            .collection("SURVEY_POLLS")
                            .doc(singleSurvey.id)
                            .collection("ANSWERS")
                            .where("timestamp", ">=", startDate)
                            .where("timestamp", "<=", (endDate + 86400000))
                            //  .limit(25)
                            .get()
                            .then((docsAnswers) => {
                                docsAnswers.forEach(singleAnswer => {
                                    singleSurvey.data().questions.slice(0, singleAnswer.data().nestedAnswers.length).map((element, index) =>
                                        surveys.push(
                                            {
                                                name: singleSurvey.data().name ? singleSurvey.data().name : "",
                                                channel: singleSurvey.data().channel ? singleSurvey.data().channel : "",
                                                id_survey: singleSurvey.data().id_survey ? singleSurvey.data().id_survey : "",
                                                id_question: index + 1,
                                                question: element.question ? element.question : "",
                                                value: singleAnswer.data().nestedAnswers[index] ? singleAnswer.data().nestedAnswers[index] : "",
                                                agentName: singleAnswer.data().agentName ? (singleAnswer.data().agentName == "0 - undefined" || singleAnswer.data().agentName == " - undefined" ? language == "english" ? "Anonymous" : "Anónimo" : singleAnswer.data().agentName) : "---",
                                                date: singleAnswer.data().date,
                                                agentUser: singleAnswer.data().agentUser ? singleAnswer.data().agentUser : "---",
                                                dni: singleAnswer.data().dni ? singleAnswer.data().dni : "---",
                                                sessionNumber: singleAnswer.data().sessionNumber ? singleAnswer.data().sessionNumber : "--",
                                                service: singleAnswer.data().service ? singleAnswer.data().service : "Otros",
                                                phoneNumber: singleAnswer.data().nroTelefono ? singleAnswer.data().nroTelefono == "Unknown" || singleAnswer.data().nroTelefono == "anonymous" || singleAnswer.data().nroTelefono == "Anonymous" || singleAnswer.data().nroTelefono == "Anónimo" || singleAnswer.data().nroTelefono == "" ? language == "english" ? "Anonymous" : "Anónimo" : singleAnswer.data().nroTelefono : "",
                                                time: singleAnswer.data().time,
                                                timestamp: singleAnswer.data().timestamp
                                            }
                                        ))

                                    //Guardar titulos de encuestas
                                    surveysTitles.push(singleAnswer.data().service ? singleAnswer.data().service : "Otros")
                                    //Guardar cantidades de encuestas
                                    if (singleSurvey.data().channel && singleSurvey.data().channel == "audio") {
                                        quantityAudio = quantityAudio + 1
                                    } else if (singleSurvey.data().channel && singleSurvey.data().channel == "web") {
                                        quantityChat = quantityChat + 1
                                    }

                                }
                                );
                                surveysTitles = surveysTitles.slice();
                                setSurveyCampaigns(surveysTitles)
                                setSurveyQuantityAudio(quantityAudio)
                                setSurveyQuantityChat(quantityChat)
                                surveys = surveys.slice();
                                resolve(setOldSurveys(surveys));
                            });
                    })
                })
            } catch (err) {
                console.log(err)
            }
            // try {

            //     let singlePollId = await db.collection("companies")
            //         .doc(loginUser.company)
            //         .collection("SURVEY_POLLS").get();

            //     let oldAnswers = [];

            //     singlePollId.docs.forEach(async (singleSurvey) => {
            //         await db
            //             .collection("companies")
            //             .doc(loginUser.company)
            //             .collection("SURVEY_POLLS")
            //             .doc(singleSurvey.id)
            //             .collection("ANSWERS")
            //             .where("timestamp", ">=", (startDate + 10800000) / 1000)
            //             .where("timestamp", "<=", ((endDate + 86400000 + 10799900) / 1000))
            //             //    .limit(15)
            //             .orderBy("timestamp", "desc")
            //             .get().then((docs) => {
            //                 docs.forEach((ele) => oldAnswers.push(ele.data()));
            //                 oldAnswers = oldAnswers.slice();
            //                 setOldSurveys(oldAnswers);

            //             });

            //     });
            // }
            // catch (err) {
            //     console.log(err)
            // }
        }
        else {
            try {
                let polls = [];
                let surveysTitles = []
                let quantityChat = 0
                let quantityAudio = 0
                let poll = await db
                    .collection("companies")
                    .doc(loginUser.company)
                    .collection("SURVEY_POLLS")
                    .get()
                    .then((responseSurveys) => {
                        responseSurveys.forEach((singleSurveyToPoll) => {
                            polls.push({
                                ...singleSurveyToPoll.data(),
                                id: singleSurveyToPoll.id
                            });
                        })
                        setSurveysPolls(polls.slice());
                        let respuesta = [];
                        polls.forEach((singleSurvey) => {
                            db.collection("companies")
                                .doc(loginUser.company)
                                .collection("SURVEY_POLLS")
                                .doc(singleSurvey.id)
                                .collection("ANSWERS")
                                .where("timestamp", ">=", oneWeekOld)
                                .orderBy("timestamp", "desc")
                                .onSnapshot((querySnapshot) => {
                                    querySnapshot.docChanges().forEach((change) => {
                                        if (change.type === "added") {
                                            singleSurvey.questions.slice(0, change.doc.data().nestedAnswers.length).map((element, index) => {
                                                respuesta.push({
                                                    name: singleSurvey.name ? singleSurvey.name : "",
                                                    channel: singleSurvey.channel ? singleSurvey.channel : "",
                                                    id_survey: singleSurvey.id_survey ? singleSurvey.id_survey : "",
                                                    id_question: index + 1,
                                                    question: element.question ? element.question : "",
                                                    value: change.doc.data().nestedAnswers[index] ? change.doc.data().nestedAnswers[index] : "",
                                                    agentName: change.doc.data().agentName ? (change.doc.data().agentName == "0 - undefined" || change.doc.data().agentName == " - undefined" ? language == "english" ? "Anonymous" : "Anónimo" : change.doc.data().agentName) : "---",
                                                    agentUser: change.doc.data().agentUser ? change.doc.data().agentUser : "---",
                                                    date: change.doc.data().date,
                                                    dni: change.doc.data().dni ? change.doc.data().dni : "---",
                                                    sessionNumber: change.doc.data().sessionNumber ? change.doc.data().sessionNumber : "--",
                                                    service: change.doc.data().service ? change.doc.data().service : "Otros",
                                                    phoneNumber: change.doc.data().nroTelefono ? change.doc.data().nroTelefono == "Unknown" || change.doc.data().nroTelefono == "anonymous" || change.doc.data().nroTelefono == "Anonymous" || change.doc.data().nroTelefono == "Anónimo" || change.doc.data().nroTelefono == "" ? language == "english" ? "Anonymous" : "Anónimo" : change.doc.data().nroTelefono : "",
                                                    time: change.doc.data().time,
                                                    timestamp: change.doc.data().timestamp,
                                                })
                                            }
                                            )
                                            //Guardar titulos de encuestas
                                            surveysTitles.push(change.doc.data().service ? change.doc.data().service : "Otros")
                                            //Guardar cantidades de encuestas
                                            if (singleSurvey.channel && singleSurvey.channel == "audio") {
                                                quantityAudio = quantityAudio + 1
                                            } else if (singleSurvey.channel && singleSurvey.channel == "web") {
                                                quantityChat = quantityChat + 1
                                            }
                                        }
                                    });
                                    surveysTitles = surveysTitles.slice();
                                    setSurveyCampaigns(surveysTitles)
                                    setSurveyQuantityAudio(quantityAudio)
                                    setSurveyQuantityChat(quantityChat)
                                    setAnswers(respuesta.slice())
                                });
                        });
                    })
            } catch (err) {
                console.log(err);
            }
            // try {
            //     let today = new Date().getTime();
            //     let answers = [];
            //     let singlePollId = await db.collection("companies")
            //         .doc(loginUser.company)
            //         .collection("SURVEY_POLLS").get();

            //     let ids = [];
            //     singlePollId.docs.forEach((ele) => ids.push(ele.id));
            //     let single = singlePollId;
            //     singlePollId = singlePollId.docs.map(ele => ele.data());

            //     setSurveysPolls(singlePollId.slice());
            //     ids.forEach((ele) => {
            //         db.collection("companies")
            //             .doc(loginUser.company)
            //             .collection("SURVEY_POLLS")
            //             .doc(ele)
            //             .collection("ANSWERS")
            //             .where("timestamp", "<=", (today - 604800000))
            //             .orderBy('timestamp', "desc")
            //             //  .limit(15) 
            //             .onSnapshot((querySnapshot) => {
            //                 querySnapshot.docChanges().forEach((change) => {
            //                     if (change.type === "added") {
            //                         answers.push(change.doc.data());
            //                     }
            //                 });
            //                 SetSurveysGalicia(answers.slice());
            //             })
            //     })
            // }
            // catch (err) {
            //     console.log(err)
            // }
        }
    };


    useEffect(() => {
        getPolls(startDate, endDate);
    }, [filterOn, startDate, endDate]);


    let onChange = (valor) => {
        setArrayDeOpciones(valor.map(ele => ele.value))
    }

    const reset = (arrayDeOpciones, date1, date2) => {
        setStartDate("");
        setEndDate("");
        arrayDeOpciones = ""
        date1.value = ("");
        date2.value = ("");
        setOptionFilter("");
        setDisplayCharts(false);
        setFilterOn(false);
    };

    const handleClick = (arrayDeOpciones, date1, date2) => {
        if (arrayDeOpciones != "" && date1.value != 0 && date2.value != 0) {
            setOptionFilter(arrayDeOpciones)
            setStartDate(new Date(date1.value).getTime());
            setEndDate(new Date(date2.value).getTime());
            setFilterOn(true);
        }
        else if (arrayDeOpciones != "") {
            setOptionFilter(arrayDeOpciones)
            setFilterOn(true);
        }
        else if (date1.value != 0 && date2.value != 0) {
            setStartDate(new Date(date1.value).getTime());
            setEndDate(new Date(date2.value).getTime());
            setFilterOn(true);
        }
    };

    return (
        <div>
            <Sidebar />
            <SurveyAnalytics
                screenSize={screenSize}
                darkModeState={darkTheme}
                language={language}
                startDate={startDate}
                endDate={endDate}
                reset={reset}
                handleClick={handleClick}
                onChange={onChange}
                opcionFilter={optionFilter}
                arrayDeOpciones={arrayDeOpciones}
                displayCharts={displayCharts}
                surveysGalicia={surveyAnswers}
                surveyPolls={surveyPolls}
                Oldsurveys={oldSurveys}
                FilterOn={filterOn}
                surveyCampaigns={surveyCampaigns}
                surveyQuantityChat={surveyQuantityChat}
                surveyQuantityAudio={surveyQuantityAudio}
            />
        </div>
    );
}

export default SurveyAnalyticsContainer;