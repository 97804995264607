import React, { useState, useEffect, memo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginUserPreferences } from "../store/actions/loginAction";
import SurveyAnswers from "../components/SurveyAnswers";
import Sidebar from "./Sidebar";
import firebase from "../config/firebaseIO";

const db = firebase.firestore()

const SurveyAnswersContainer = () => {
    const [screenSize, setScreenSize] = useState(window.innerWidth);

    const [arrayDeOpciones, setArrayDeOpciones] = useState([]);
    const [opcionFilter, setEl2] = useState("");

    const [fecha1, setFecha1] = useState("");
    const [fecha2, setFecha2] = useState("");
    const [search, setSearch] = useState("");

    const [surveyAnswers, setAnswers] = useState([]);

    const [oldSurveys, setOldSurveys] = useState([]);
    const [surveyPolls, setSurveysPolls] = useState([]);

    const [filterOn, setFilterOn] = useState(false);

    const loginUser = useSelector(state => state.user.loginUser);
    const language = useSelector(state => state.user.language);
    const darkTheme = useSelector(state => state.user.darkTheme);


    let getTime = new Date().getTime() / 1000;
    // let oneWeekOld = getTime - 604800;
    let oneDayOld = getTime - 86400;


    let getPolls2 = async (start, end) => {
        if (filterOn == true) {
            try {
                return new Promise(async (resolve) => {
                    let surveys = []
                    let oldPolls = await db
                        .collection("companies")
                        .doc(loginUser.company)
                        .collection("SURVEY_POLLS")
                        .get();
                    oldPolls.docs.forEach(async (singleSurvey) => {
                        await db
                            .collection("companies")
                            .doc(loginUser.company)
                            .collection("SURVEY_POLLS")
                            .doc(singleSurvey.id)
                            .collection("ANSWERS")
                            .where("timestamp", ">=", start)
                            .where("timestamp", "<=", (end + 86400000))
                            //  .limit(25)
                            .get()
                            .then((docsAnswers) => {
                                docsAnswers.forEach(singleAnswer =>
                                    singleSurvey.data().questions.slice(0, singleAnswer.data().nestedAnswers.length).map((element, index) =>
                                        surveys.push(
                                            {
                                                name: singleSurvey.data().name ? singleSurvey.data().name : "",
                                                channel: singleSurvey.data().channel ? singleSurvey.data().channel : "",
                                                question: element.question ? element.question : "",
                                                value: singleAnswer.data().nestedAnswers[index] ? singleAnswer.data().nestedAnswers[index] : "",
                                                agentName: singleAnswer.data().agentName ? (singleAnswer.data().agentName == "0 - undefined" || singleAnswer.data().agentName == " - undefined" ? language == "english" ? "Anonymous" : "Anónimo" : singleAnswer.data().agentName) : "---",
                                                date: singleAnswer.data().date,
                                                agentUser: singleAnswer.data().agentUser ? singleAnswer.data().agentUser : "---",
                                                dni: singleAnswer.data().dni ? singleAnswer.data().dni : "---",
                                                sessionNumber: singleAnswer.data().sessionNumber ? singleAnswer.data().sessionNumber : "--",
                                                service: singleAnswer.data().service ? singleAnswer.data().service : "Otros",
                                                phoneNumber: singleAnswer.data().nroTelefono ? singleAnswer.data().nroTelefono == "Unknown" || singleAnswer.data().nroTelefono == "anonymous" || singleAnswer.data().nroTelefono == "Anonymous" || singleAnswer.data().nroTelefono == "Anónimo" || singleAnswer.data().nroTelefono == "" ? language == "english" ? "Anonymous" : "Anónimo" : singleAnswer.data().nroTelefono : "",
                                                time: singleAnswer.data().time,
                                                timestamp: singleAnswer.data().timestamp
                                            }
                                        ))
                                );
                                surveys = surveys.slice();
                                resolve(setOldSurveys(surveys));
                            });
                    })
                })
            } catch (err) {
                console.log(err)
            }
        } else {
            try {
                let polls = [];
                let poll = await db
                    .collection("companies")
                    .doc(loginUser.company)
                    .collection("SURVEY_POLLS")
                    .get()
                    .then((querySnapshot) => {
                        querySnapshot.forEach((ele) => {
                            polls.push({
                                ...ele.data(),
                                id: ele.id,
                            });
                            setSurveysPolls(polls.slice());
                            let respuesta = [];
                            polls.forEach((ele) => {
                                db.collection("companies")
                                    .doc(loginUser.company)
                                    .collection("SURVEY_POLLS")
                                    .doc(ele.id)
                                    .collection("ANSWERS")
                                    // .where("timestamp", ">=", oneWeekOld)
                                    .where("timestamp", ">=", oneDayOld)
                                    .orderBy("timestamp", "desc")
                                    //   .limit(25)
                                    .onSnapshot((querySnapshot) => {
                                        querySnapshot.docChanges().forEach((change) => {
                                            if (change.type === "added") {
                                                ele.questions.slice(0, change.doc.data().nestedAnswers.length).map((element, index) =>
                                                    respuesta.push({
                                                        name: ele.name ? ele.name : "",
                                                        channel: ele.channel ? ele.channel : "",
                                                        question: element.question ? element.question : "",
                                                        value: change.doc.data().nestedAnswers[index] ? change.doc.data().nestedAnswers[index] : "",
                                                        agentName: change.doc.data().agentName ? (change.doc.data().agentName == "0 - undefined" || change.doc.data().agentName == " - undefined" ? language == "english" ? "Anonymous" : "Anónimo" : change.doc.data().agentName) : "---",
                                                        agentUser: change.doc.data().agentUser ? change.doc.data().agentUser : "---",
                                                        date: change.doc.data().date,
                                                        dni: change.doc.data().dni ? change.doc.data().dni : "---",
                                                        sessionNumber: change.doc.data().sessionNumber ? change.doc.data().sessionNumber : "--",
                                                        service: change.doc.data().service ? change.doc.data().service : "Otros",
                                                        phoneNumber: change.doc.data().nroTelefono ? change.doc.data().nroTelefono == "Unknown" || change.doc.data().nroTelefono == "anonymous" || change.doc.data().nroTelefono == "Anonymous" || change.doc.data().nroTelefono == "Anónimo" || change.doc.data().nroTelefono == "" ? language == "english" ? "Anonymous" : "Anónimo" : change.doc.data().nroTelefono : "",
                                                        time: change.doc.data().time,
                                                        timestamp: change.doc.data().timestamp
                                                    })
                                                )
                                            }
                                        });
                                        setAnswers(respuesta.slice())
                                    });
                            });
                        });
                    });
            } catch (err) {
                console.log(err);
            }
        }
    };

    useEffect(() => {
        getPolls2(fecha1, fecha2);
    }, [filterOn, fecha1, fecha2])

    let onChange = (valor) => {
        setArrayDeOpciones(valor.map(ele => ele.value))
    }

    let reset = (startDate, endDate, searchBar, arrayDeOpciones) => {
        setEl2([]);
        setFecha1("");
        setFecha2("");
        setSearch("");
        startDate.value = "";
        endDate.value = "";
        arrayDeOpciones.value = [];
        searchBar.value = "";
        setFilterOn(false);
        setOldSurveys([]);
    };

    let handleClick = (arrayDeOpciones, startDate, endDate, searchBar) => {
        if (startDate.value != "" && endDate.value != "" && arrayDeOpciones != "" && searchBar.value != "") {
            setFecha1(new Date(startDate.value).getTime() / 1000);
            setFecha2(new Date(endDate.value).getTime() / 1000);
            setEl2(arrayDeOpciones);
            setSearch(searchBar.value);
            setFilterOn(true);
        } else if (startDate.value != "" && endDate.value != "" && arrayDeOpciones != "") {
            setFecha1(new Date(startDate.value).getTime() / 1000);
            setFecha2(new Date(endDate.value).getTime() / 1000);
            setEl2(arrayDeOpciones);
            setSearch(searchBar.value);
            setFilterOn(true);
        } else if (startDate.value != "" && endDate.value != "" && searchBar.value != "") {
            setFecha1(new Date(startDate.value).getTime() / 1000);
            setFecha2(new Date(endDate.value).getTime() / 1000);
            setSearch(searchBar.value);
            setEl2(arrayDeOpciones);
            setFilterOn(true);
        } else if (startDate.value != "" && endDate.value != "") {
            setFecha1(new Date(startDate.value).getTime() / 1000);
            setFecha2(new Date(endDate.value).getTime() / 1000);
            setEl2("")
            setSearch("");
            setFilterOn(true);
        } else if (searchBar.value != "" && arrayDeOpciones != "") {
            setSearch(searchBar.value);
            setEl2(arrayDeOpciones);
            setFecha1("");
            setFecha2("");
        } else if (arrayDeOpciones != "") {
            setEl2(arrayDeOpciones);
            setFecha1("");
            setFecha2("");
            setSearch("");
        } else if (searchBar.value != "") {
            setSearch(searchBar.value);
            setEl2("");
            setFecha1("");
            setFecha2("");
        } else {
            setEl2("");
            setFecha1("");
            setFecha2("");
            setSearch("");
            setFilterOn(false);
        }
    };

    return (
        <div>
            <Sidebar />
            <SurveyAnswers
                screenSize={screenSize}
                darkModeState={darkTheme}
                language={language}
                reports={surveyAnswers}
                handleClick={handleClick}
                reset={reset}
                opcionFilter={opcionFilter}
                fecha1={fecha1}
                fecha2={fecha2}
                search={search}
                onChange={onChange}
                arrayDeOpciones={arrayDeOpciones}
                surveyPolls={surveyPolls}
                oldSurveys={oldSurveys}
                loginUser={loginUser}
            />
        </div>
    );
}

export default SurveyAnswersContainer;