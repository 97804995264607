import React from "react";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
    Chart as ChartJS,
    CategoryScale,
    RadialLinearScale,
    LinearScale,
    PointElement,
    BarElement,
    ArcElement,
    LineElement,
    Filler,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Bar, Doughnut } from 'react-chartjs-2';

export default ({ screenSize, darkModeState, language, loggedAgents }) => {

    let clientes = { galicia: 0, bbva: 0, dia: 0, aonrisk: 0, tigreset: 0, x28: 0, piano: 0, airliquide: 0, amarilla: 0, prudential: 0 };

    loggedAgents.slice(0, 10).forEach((ele) => {
        for (let propiedad in clientes) {
            if (ele.client == propiedad) {
                clientes[propiedad] = ele.loggedAgents;
            }
        }
    });
    // console.log("clientes>", clientes)

    let fecha = loggedAgents.slice(0, 10).map(ele => ele.fecha);
    // console.log("logged>", loggedAgents)

    let servidores = { noxus: 0 };

    // let diaHoy = new Date().getDate().toString();
    // console.log('diaHoy>', new Date().getDate().toString())

    loggedAgents.slice(0, 10).forEach(ele => {
        if (ele.server == "noxus") {
            servidores.noxus += ele.loggedAgents;
        }
    });
    // console.log("servidores>", servidores)


    //CONTRUCTORA DE CLIENTES
    class Clients {
        constructor(ocho, nueve, diez, once, doce, trece, catorce, quince, dieciseis, diecisiete, dieciocho, diecinueve, veinte) {
            this.ocho = ocho;
            this.nueve = nueve;
            this.diez = diez;
            this.once = once;
            this.doce = doce;
            this.trece = trece;
            this.catorce = catorce;
            this.quince = quince;
            this.dieciseis = dieciseis;
            this.diecisiete = diecisiete;
            this.dieciocho = dieciocho;
            this.diecinueve = diecinueve;
            this.veinte = veinte;
        }
    };
    //-------------IPLAN-----------------------------
    let galicia = new Clients(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
    let bbva = new Clients(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
    let aon = new Clients(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
    let dia = new Clients(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
    let x28 = new Clients(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
    let piano = new Clients(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
    let prudential = new Clients(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
    let amarilla = new Clients(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
    let airliquide = new Clients(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
    let tigreset = new Clients(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);

    //galicia
    loggedAgents.forEach((ele) => {
        if (ele.client == "galicia"
            //  && ele.dia == diaHoy
        ) {
            switch (ele.hora) {
                case "08":
                    galicia.ocho = ele.loggedAgents;
                    break;
                case "09":
                    galicia.nueve = ele.loggedAgents;
                    break;
                case "10":
                    galicia.diez = ele.loggedAgents;
                    break;
                case "11":
                    galicia.once = ele.loggedAgents;
                    break;
                case "12":
                    galicia.doce = ele.loggedAgents;
                    break;
                case "13":
                    galicia.trece = ele.loggedAgents;
                    break;
                case "14":
                    galicia.catorce = ele.loggedAgents;
                    break;
                case "15":
                    galicia.quince = ele.loggedAgents;
                    break;
                case "16":
                    galicia.dieciseis = ele.loggedAgents;
                    break;
                case "17":
                    galicia.diecisiete = ele.loggedAgents;
                    break;
                case "18":
                    galicia.dieciocho = ele.loggedAgents;
                    break;
                case "19":
                    galicia.diecinueve = ele.loggedAgents;
                    break;
                case "20":
                    galicia.veinte = ele.loggedAgents;
                    break;

            }
        }
    });
    //bbva
    loggedAgents.forEach((ele) => {
        if (ele.client == "bbva"
            //  && ele.dia == diaHoy
        ) {
            switch (ele.hora) {
                case "08":
                    bbva.ocho = ele.loggedAgents;
                    break;
                case "09":
                    bbva.nueve = ele.loggedAgents;
                    break;
                case "10":
                    bbva.diez = ele.loggedAgents;
                    break;
                case "11":
                    bbva.once = ele.loggedAgents;
                    break;
                case "12":
                    bbva.doce = ele.loggedAgents;
                    break;
                case "13":
                    bbva.trece = ele.loggedAgents;
                    break;
                case "14":
                    bbva.catorce = ele.loggedAgents;
                    break;
                case "15":
                    bbva.quince = ele.loggedAgents;
                    break;
                case "16":
                    bbva.dieciseis = ele.loggedAgents;
                    break;
                case "17":
                    bbva.diecisiete = ele.loggedAgents;
                    break;
                case "18":
                    bbva.dieciocho = ele.loggedAgents;
                    break;
                case "19":
                    bbva.diecinueve = ele.loggedAgents;
                    break;
                case "20":
                    bbva.veinte = ele.loggedAgents;
                    break;

            }
        }
    });
    //aon
    loggedAgents.forEach((ele) => {
        if (ele.client == "aonrisk") {
            switch (ele.hora) {
                case "08":
                    aon.ocho = ele.loggedAgents;
                    break;
                case "09":
                    aon.nueve = ele.loggedAgents;
                    break;
                case "10":
                    aon.diez = ele.loggedAgents;
                    break;
                case "11":
                    aon.once = ele.loggedAgents;
                    break;
                case "12":
                    aon.doce = ele.loggedAgents;
                    break;
                case "13":
                    aon.trece = ele.loggedAgents;
                    break;
                case "14":
                    aon.catorce = ele.loggedAgents;
                    break;
                case "15":
                    aon.quince = ele.loggedAgents;
                    break;
                case "16":
                    aon.dieciseis = ele.loggedAgents;
                    break;
                case "17":
                    aon.diecisiete = ele.loggedAgents;
                    break;
                case "18":
                    aon.dieciocho = ele.loggedAgents;
                    break;
                case "19":
                    aon.diecinueve = ele.loggedAgents;
                    break;
                case "20":
                    aon.veinte = ele.loggedAgents;
                    break;

            }
        }
    });
    //--------------DIA ---------------------------
    loggedAgents.forEach((ele) => {
        if (ele.client == "dia") {
            switch (ele.hora) {
                case "08":
                    dia.ocho = ele.loggedAgents;
                    break;
                case "09":
                    dia.nueve = ele.loggedAgents;
                    break;
                case "10":
                    dia.diez = ele.loggedAgents;
                    break;
                case "11":
                    dia.once = ele.loggedAgents;
                    break;
                case "12":
                    dia.doce = ele.loggedAgents;
                    break;
                case "13":
                    dia.trece = ele.loggedAgents;
                    break;
                case "14":
                    dia.catorce = ele.loggedAgents;
                    break;
                case "15":
                    dia.quince = ele.loggedAgents;
                    break;
                case "16":
                    dia.dieciseis = ele.loggedAgents;
                    break;
                case "17":
                    dia.diecisiete = ele.loggedAgents;
                    break;
                case "18":
                    dia.dieciocho = ele.loggedAgents;
                    break;
                case "19":
                    dia.diecinueve = ele.loggedAgents;
                    break;
                case "20":
                    dia.veinte = ele.loggedAgents;
                    break;
            }
        }
    });

    //----------------X28-------------------------
    loggedAgents.forEach((ele) => {
        if (ele.client == "x28") {
            switch (ele.hora) {
                case "08":
                    x28.ocho = ele.loggedAgents;
                    break;
                case "09":
                    x28.nueve = ele.loggedAgents;
                    break;
                case "10":
                    x28.diez = ele.loggedAgents;
                    break;
                case "11":
                    x28.once = ele.loggedAgents;
                    break;
                case "12":
                    x28.doce = ele.loggedAgents;
                    break;
                case "13":
                    x28.trece = ele.loggedAgents;
                    break;
                case "14":
                    x28.catorce = ele.loggedAgents;
                    break;
                case "15":
                    x28.quince = ele.loggedAgents;
                    break;
                case "16":
                    x28.dieciseis = ele.loggedAgents;
                    break;
                case "17":
                    x28.diecisiete = ele.loggedAgents;
                    break;
                case "18":
                    x28.dieciocho = ele.loggedAgents;
                    break;
                case "19":
                    x28.diecinueve = ele.loggedAgents;
                    break;
                case "20":
                    x28.veinte = ele.loggedAgents;
                    break;
            }
        }
    });
    //-------------PIANO-------------------------
    loggedAgents.forEach((ele) => {
        if (ele.client == "piano") {
            switch (ele.hora) {
                case "08":
                    piano.ocho = ele.loggedAgents;
                    break;
                case "09":
                    piano.nueve = ele.loggedAgents;
                    break;
                case "10":
                    piano.diez = ele.loggedAgents;
                    break;
                case "11":
                    piano.once = ele.loggedAgents;
                    break;
                case "12":
                    piano.doce = ele.loggedAgents;
                    break;
                case "13":
                    piano.trece = ele.loggedAgents;
                    break;
                case "14":
                    piano.catorce = ele.loggedAgents;
                    break;
                case "15":
                    piano.quince = ele.loggedAgents;
                    break;
                case "16":
                    piano.dieciseis = ele.loggedAgents;
                    break;
                case "17":
                    piano.diecisiete = ele.loggedAgents;
                    break;
                case "18":
                    piano.dieciocho = ele.loggedAgents;
                    break;
                case "19":
                    piano.diecinueve = ele.loggedAgents;
                    break;
                case "20":
                    piano.veinte = ele.loggedAgents;
                    break;
            }
        }
    });
    //------------PRUDENTIAL---------------------
    loggedAgents.forEach((ele) => {
        if (ele.client == "prudential") {
            switch (ele.hora) {
                case "08":
                    prudential.ocho = ele.loggedAgents;
                    break;
                case "09":
                    prudential.nueve = ele.loggedAgents;
                    break;
                case "10":
                    prudential.diez = ele.loggedAgents;
                    break;
                case "11":
                    prudential.once = ele.loggedAgents;
                    break;
                case "12":
                    prudential.doce = ele.loggedAgents;
                    break;
                case "13":
                    prudential.trece = ele.loggedAgents;
                    break;
                case "14":
                    prudential.catorce = ele.loggedAgents;
                    break;
                case "15":
                    prudential.quince = ele.loggedAgents;
                    break;
                case "16":
                    prudential.dieciseis = ele.loggedAgents;
                    break;
                case "17":
                    prudential.diecisiete = ele.loggedAgents;
                    break;
                case "18":
                    prudential.dieciocho = ele.loggedAgents;
                    break;
                case "19":
                    prudential.diecinueve = ele.loggedAgents;
                    break;
                case "20":
                    prudential.veinte = ele.loggedAgents;
                    break;
            }
        }
    });
    //-----------AMARILLA-------
    loggedAgents.forEach((ele) => {
        if (ele.client == "amarilla") {
            switch (ele.hora) {
                case "08":
                    amarilla.ocho = ele.loggedAgents;
                    break;
                case "09":
                    amarilla.nueve = ele.loggedAgents;
                    break;
                case "10":
                    amarilla.diez = ele.loggedAgents;
                    break;
                case "11":
                    amarilla.once = ele.loggedAgents;
                    break;
                case "12":
                    amarilla.doce = ele.loggedAgents;
                    break;
                case "13":
                    amarilla.trece = ele.loggedAgents;
                    break;
                case "14":
                    amarilla.catorce = ele.loggedAgents;
                    break;
                case "15":
                    amarilla.quince = ele.loggedAgents;
                    break;
                case "16":
                    amarilla.dieciseis = ele.loggedAgents;
                    break;
                case "17":
                    amarilla.diecisiete = ele.loggedAgents;
                    break;
                case "18":
                    amarilla.dieciocho = ele.loggedAgents;
                    break;
                case "19":
                    amarilla.diecinueve = ele.loggedAgents;
                    break;
                case "20":
                    amarilla.veinte = ele.loggedAgents;
                    break;
            }
        }
    });
    //-------AIRLIQUIDE
    loggedAgents.forEach((ele) => {
        if (ele.client == "airliquide") {
            switch (ele.hora) {
                case "08":
                    airliquide.ocho = ele.loggedAgents;
                    break;
                case "09":
                    airliquide.nueve = ele.loggedAgents;
                    break;
                case "10":
                    airliquide.diez = ele.loggedAgents;
                    break;
                case "11":
                    airliquide.once = ele.loggedAgents;
                    break;
                case "12":
                    airliquide.doce = ele.loggedAgents;
                    break;
                case "13":
                    airliquide.trece = ele.loggedAgents;
                    break;
                case "14":
                    airliquide.catorce = ele.loggedAgents;
                    break;
                case "15":
                    airliquide.quince = ele.loggedAgents;
                    break;
                case "16":
                    airliquide.dieciseis = ele.loggedAgents;
                    break;
                case "17":
                    airliquide.diecisiete = ele.loggedAgents;
                    break;
                case "18":
                    airliquide.dieciocho = ele.loggedAgents;
                    break;
                case "19":
                    airliquide.diecinueve = ele.loggedAgents;
                    break;
                case "20":
                    airliquide.veinte = ele.loggedAgents;
                    break;
            }
        }
    });
    //---------------TIGRESET -------------------
    loggedAgents.forEach((ele) => {
        if (ele.client == "tigreset") {
            switch (ele.hora) {
                case "08":
                    tigreset.ocho = ele.loggedAgents;
                    break;
                case "09":
                    tigreset.nueve = ele.loggedAgents;
                    break;
                case "10":
                    tigreset.diez = ele.loggedAgents;
                    break;
                case "11":
                    tigreset.once = ele.loggedAgents;
                    break;
                case "12":
                    tigreset.doce = ele.loggedAgents;
                    break;
                case "13":
                    tigreset.trece = ele.loggedAgents;
                    break;
                case "14":
                    tigreset.catorce = ele.loggedAgents;
                    break;
                case "15":
                    tigreset.quince = ele.loggedAgents;
                    break;
                case "16":
                    tigreset.dieciseis = ele.loggedAgents;
                    break;
                case "17":
                    tigreset.diecisiete = ele.loggedAgents;
                    break;
                case "18":
                    tigreset.dieciocho = ele.loggedAgents;
                    break;
                case "19":
                    tigreset.diecinueve = ele.loggedAgents;
                    break;
                case "20":
                    tigreset.veinte = ele.loggedAgents;
                    break;
            }
        }
    });

    let totalServidores = servidores.noxus
    let porcentajeDemacia = 0;
    let porcentajeIplan = 0;
    let porcentajeOc3 = 0;
    //------------------------------------------------------
    ChartJS.register(CategoryScale, LinearScale, RadialLinearScale, PointElement, BarElement, PointElement, ArcElement, LineElement, Filler, Title, Tooltip, Legend);

    const opcionesClientesPorHora = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: "Usuarios conectados " + fecha[0],
            },
        },
    };

    const dataClientesPorHora = {
        labels: [`Galicia (${(Math.round(clientes.galicia * 100 / 113))}%)`, `BBVA (${Math.round(clientes.bbva * 100 / 55)}%)`, `Aon (${Math.round(clientes.aon * 100 / 30)}%)`, `Dia (${Math.round(clientes.dia * 100 / 22)}%)`, `Iké (${Math.round(clientes.ike * 100 / 37)}%)`, `X28 (${Math.round(clientes.x28 * 100 / 48)}%)`, `Banco Piano (${Math.round(clientes.piano * 100 / 143)}%)`, `Prudential (${Math.round(clientes.prudential * 100 / 16)}%)`, `Borigen (${Math.round(clientes.borigen * 100 / 12)}%)`, `Amgas (${Math.round(clientes.amgas * 100 / 16)}%)`, `Air-Liquide (${Math.round(clientes.airliquide * 100 / 22)}%)`, `TigreSet (${Math.round(clientes.tigreset * 100 / 10)}%)`],
        datasets: [
            {
                label: "Agentes conectados",
                data: [clientes.galicia, clientes.bbva, clientes.aon, clientes.dia, clientes.ike, clientes.x28, clientes.piano, clientes.prudential, clientes.borigen, clientes.amgas, clientes.airliquide, clientes.tigreset],
                backgroundColor: [
                    '#EF5E23',  // galicia
                    '#004581',  // bbva
                    '#EB0117', // aon
                    '#D52D20',// dia
                    '#004A87',//Ike
                    '#004494',//x28
                    '#02B0F3', // piano        
                    '#007AC0', // prudential
                    '#D32A28',//Borigen
                    '#FAAA19', //amgas,
                    '#DF1B2D', //air,
                    '#FF0202', //tigreset

                ],
                datalabels: {
                    color: 'white',
                    font: {
                        weight: 'bold',
                        size: 16,

                    },

                },
                borderColor: [
                    '#EF5E23',  // galicia
                    '#004581',  // bbva

                    '#EB0117', // aon
                    '#D52D20',// dia

                    '#004A87',//Ike
                    '#004494',//x28
                    '#02B0F3', // piano          
                    '#007AC0', // prudential
                    '#D32A28',//Borigen
                    '#FAAA19', //amgas,

                    '#DF1B2D', //air,
                    '#FF0202', //tigreset
                ],
                borderWidth: 2
            }
        ],
    };
    const opcionesLicenciasPorClientes = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: "Licencias totales por cliente",
            },
        },
    };
    const dataLicenciasTotalesPorClientes = {
        labels: ["Galicia (113)", "BBVA (55)", "Aon (30)", "Dia(22)", "Iké (37)", "X28 (48)", "Banco Piano (143)", "Prudential (16)", "Borigen (12)", "Amarilla Gas (16)", "Air-Liquide (22)", "Minicipio de Tigre (10)"],
        datasets: [
            {
                label: "Agentes conectados",
                data: [113, 55, 30, 22, 37, 48, 143, 16, 12, 16, 22, 10],
                backgroundColor: [
                    '#EF5E23',  // galicia
                    '#004581',  // bbva
                    '#EB0117', // aon
                    '#D52D20',// dia
                    '#004A87',//Ike
                    '#004494',//x28
                    '#02B0F3', // piano        
                    '#007AC0', // prudential
                    '#D32A28',//Borigen
                    '#FAAA19', //amgas,

                    '#DF1B2D', //air,
                    '#FF0202', //tigreset

                ],
                datalabels: {
                    color: 'white',
                    font: {
                        weight: 'bold',
                        size: 16,

                    },

                },
                borderColor: [
                    '#EF5E23',  // galicia
                    '#004581',  // bbva
                    '#EB0117', // aon
                    '#D52D20',// dia
                    '#004A87',//Ike
                    '#004494',//x28
                    '#02B0F3', // piano        
                    '#007AC0', // prudential
                    '#D32A28',//Borigen
                    '#FAAA19', //amgas,
                    '#DF1B2D', //air,
                    '#FF0202', //tigreset

                ],
                borderWidth: 2
            }
        ],
    };

    const opcionesClientesPorServidor = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: "Clientes por servidor " + fecha[0],
            },
        },
    };
    const dataClientesPorServidor = {
        labels: [`Demacia (${servidores.Demacia})`, `IPLAN (${servidores.Iplan})`, `OC3 (${servidores.Oc3})`],
        datasets: [
            {
                label: 'Usuario Conectados en el horario ',
                data: [Math.round(porcentajeDemacia), Math.round(porcentajeIplan), Math.round(porcentajeOc3)],
                backgroundColor: [
                    'rgb(255, 99, 132)',
                    'rgb(54, 162, 235)',
                    'rgb(255, 205, 86)'
                ],
                datalabels: {
                    color: 'white',
                    formatter: function (value, context) {
                        return value + '%';
                    },
                    font: {
                        weight: 'bold',
                        size: 16,

                    },
                },


                borderColor: [
                    'rgb(255, 99, 132)',
                    'rgb(54, 162, 235)',
                    'rgb(255, 205, 86)'
                ],
                borderWidth: 2,
            },
        ],
    };

    const opcionesLicenciasPorServidor = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: "Licencias en uso por servidor " + fecha[0],
            },
        },
    };
    const datalicenciasPorServidor = {
        labels: [`Demacia (35/-13)`, `IPLAN (222/+2)`, `OC3 (199/-55)`],
        datasets: [
            {
                label: 'Usuario Conectados en el horario ',
                data: [Math.round(servidores.Demacia * 100 / 35), Math.round(servidores.Iplan * 100 / 222), Math.round(servidores.Oc3 * 100 / 199)],
                backgroundColor: [
                    'rgb(255, 99, 132)',
                    'rgb(54, 162, 235)',
                    'rgb(255, 205, 86)'
                ],
                datalabels: {
                    color: 'white',
                    formatter: function (value, context) {
                        return value + "%";
                    },
                    font: {
                        weight: 'bold',
                        size: 16,

                    },
                },


                borderColor: [
                    'rgb(255, 99, 132)',
                    'rgb(54, 162, 235)',
                    'rgb(255, 205, 86)'
                ],
                borderWidth: 2,
            },
        ],
    };

    //IPLAN
    let galiciaArray = [galicia.ocho, galicia.nueve, galicia.diez, galicia.once, galicia.doce, galicia.trece, galicia.catorce, galicia.quince, galicia.dieciseis, galicia.diecisiete, galicia.dieciocho, galicia.diecinueve, galicia.veinte];
    let bbvaArray = [bbva.ocho, bbva.nueve, bbva.diez, bbva.once, bbva.doce, bbva.trece, bbva.catorce, bbva.quince, bbva.dieciseis, bbva.diecisiete, bbva.dieciocho, bbva.diecinueve, bbva.veinte];
    let aonArray = [aon.ocho, aon.nueve, aon.diez, aon.once, aon.doce, aon.trece, aon.catorce, aon.quince, aon.dieciseis, aon.diecisiete, aon.dieciocho, aon.diecinueve, aon.veinte];
    let diaArray = [dia.ocho, dia.nueve, dia.diez, dia.once, dia.doce, dia.trece, dia.catorce, dia.quince, dia.dieciseis, dia.diecisiete, dia.dieciocho, dia.diecinueve, dia.veinte];
    let x28Array = [x28.ocho, x28.nueve, x28.diez, x28.once, x28.doce, x28.trece, x28.catorce, x28.quince, x28.dieciseis, x28.diecisiete, x28.dieciocho, x28.diecinueve, x28.veinte];
    let pianoArray = [piano.ocho, piano.nueve, piano.diez, piano.once, piano.doce, piano.trece, piano.catorce, piano.quince, piano.dieciseis, piano.diecisiete, piano.dieciocho, piano.diecinueve, piano.veinte];
    let prudentialArray = [prudential.ocho, prudential.nueve, prudential.diez, prudential.once, prudential.doce, prudential.trece, prudential.catorce, prudential.quince, prudential.dieciseis, prudential.diecisiete, prudential.dieciocho, prudential.diecinueve, prudential.veinte];
    let amgasArray = [amarilla.ocho, amarilla.nueve, amarilla.diez, amarilla.once, amarilla.doce, amarilla.trece, amarilla.catorce, amarilla.quince, amarilla.dieciseis, amarilla.diecisiete, amarilla.dieciocho, amarilla.diecinueve, amarilla.veinte];
    let airliquideArray = [airliquide.ocho, airliquide.nueve, airliquide.diez, airliquide.once, airliquide.doce, airliquide.trece, airliquide.catorce, airliquide.quince, airliquide.dieciseis, airliquide.diecisiete, airliquide.dieciocho, airliquide.diecinueve, airliquide.veinte];
    let tigresetArray = [tigreset.ocho, tigreset.nueve, tigreset.diez, tigreset.once, tigreset.doce, tigreset.trece, tigreset.catorce, tigreset.quince, tigreset.dieciseis, tigreset.diecisiete, tigreset.dieciocho, tigreset.diecinueve, tigreset.veinte];

    //--------------CLIENTES POR HORA GRAFICOOOOOOOOOOOS ---------------

    //-----galicia--------------
    const galiciaOptPorHora = {
        plugins: {
            title: {
                display: false,
                text: "Agentes GALICIA por hora",
            },
        },
        responsive: true,
        interaction: {
            mode: 'index',
        },
        scales: {
            x: {
                stacked: true,
                beginAtZero: true,
                max: 13,
                min: 0
            },
            y: {
                stacked: true,
                beginAtZero: false,
                max: 113,
                min: 0
            },
        },
    };
    const galiciaDataPorHora = {
        labels: [`8h (${Math.round(galicia.ocho * 100 / 113)}%)`, `9h (${Math.round(galicia.nueve * 100 / 113)}%)`, `10h (${Math.round(galicia.diez * 100 / 113)}%)`, `11h (${Math.round(galicia.once * 100 / 113)})%`, `12h (${Math.round(galicia.doce * 100 / 113)}%)`, `13h (${Math.round(galicia.trece * 100 / 113)}%)`, `14h (${Math.round(galicia.catorce * 100 / 113)}%)`, `15h (${Math.round(galicia.quince * 100 / 113)}%)`, `16h (${Math.round(galicia.dieciseis * 100 / 113)}%)`, `17h (${Math.round(galicia.diecisiete * 100 / 113)}%)`, `18h (${Math.round(galicia.dieciocho * 100 / 113)}%)`, `19h (${Math.round(galicia.diecinueve * 100 / 113)}%)`, `20h (${Math.round(galicia.veinte * 100 / 113)}%)`],
        datasets: [
            {
                label: "Galicia Seguros",
                data: galiciaArray,
                backgroundColor: galiciaArray.map((ele) => {
                    let colores = [];
                    if (ele >= 105) {
                        colores.push('rgb(255, 0, 0)');
                    } else {
                        colores.push('#EF5E23');
                    }
                    return colores;
                }),
                stack: 'Stack 0',
                datalabels: {
                    color: 'white',
                    font: {
                        weight: 'bold',
                        size: 15,
                    },
                },
            },

        ],
    };
    //..........BBVA ...........
    const BBVAOptPorHora = {
        plugins: {
            title: {
                display: false,
                text: "Agentes BBVA por hora",
            },
        },
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {

                beginAtZero: true,
                max: 100,
                min: 0

            },
            y: {

                beginAtZero: false,
                max: 55,
                min: 0

            }
        },
    };

    const BBVADataPorHora = {
        labels: [`8h (${Math.round(bbva.ocho * 100 / 55)}%)`, `9h (${Math.round(bbva.nueve * 100 / 55)}%)`, `10h (${Math.round(bbva.diez * 100 / 55)}%)`, `11h (${Math.round(bbva.once * 100 / 55)})%`, `12h (${Math.round(bbva.doce * 100 / 55)}%)`, `13h (${Math.round(bbva.trece * 100 / 55)}%)`, `14h (${Math.round(bbva.catorce * 100 / 55)}%)`, `15h (${Math.round(bbva.quince * 100 / 55)}%)`, `16h (${Math.round(bbva.dieciseis * 100 / 55)}%)`, `17h (${Math.round(bbva.diecisiete * 100 / 55)}%)`, `18h (${Math.round(bbva.dieciocho * 100 / 55)}%)`, `19h (${Math.round(bbva.diecinueve * 100 / 55)}%)`, `20h (${Math.round(bbva.veinte * 100 / 55)}%)`],
        datasets: [
            {
                label: "BBVA Seguros",
                data: bbvaArray,
                backgroundColor: bbvaArray.map((ele) => {
                    let colores = [];
                    if (ele >= 50) {
                        colores.push('rgb(255, 0, 0)');
                    } else {
                        colores.push('#004581');
                    }
                    return colores;
                }),
                stack: 'Stack 0',
                datalabels: {
                    color: 'white',
                    font: {
                        weight: 'bold',
                        size: 15,
                    },
                },
            }
        ],
    };
    //-----------AON ------------------
    const aonOptPorHora = {
        plugins: {
            title: {
                display: false,
                text: "Agentes AON por hora",
            },
        },
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {

                beginAtZero: true,
                max: 100,
                min: 0
            },
            y: {

                beginAtZero: false,
                max: 30,
                min: 0
            }
        },
    };
    const aonDataPorHora = {
        labels: [`8h (${Math.round(aon.ocho * 100 / 30)}%)`, `9h (${Math.round(aon.nueve * 100 / 30)}%)`, `10h (${Math.round(aon.diez * 100 / 30)}%)`, `11h (${Math.round(aon.once * 100 / 30)})%`, `12h (${Math.round(aon.doce * 100 / 30)}%)`, `13h (${Math.round(aon.trece * 100 / 30)}%)`, `14h (${Math.round(aon.catorce * 100 / 30)}%)`, `15h (${Math.round(aon.quince * 100 / 30)}%)`, `16h (${Math.round(aon.dieciseis * 100 / 30)}%)`, `17h (${Math.round(aon.diecisiete * 100 / 30)}%)`, `18h (${Math.round(aon.dieciocho * 100 / 30)}%)`, `19h (${Math.round(aon.diecinueve * 100 / 30)}%)`, `20h (${Math.round(aon.veinte * 100 / 30)}%)`],
        datasets: [
            {
                label: "AON",
                data: aonArray,
                backgroundColor: aonArray.map((ele) => {
                    let colores = [];
                    if (ele >= 25) {
                        colores.push('rgb(255, 0, 0)');
                    } else {
                        colores.push('#EB0117');
                    }
                    return colores;
                }),
                stack: 'Stack 0',
                datalabels: {
                    color: 'white',
                    font: {
                        weight: 'bold',
                        size: 15,
                    },
                },
            }
        ],
    };
    //----------- DIA -----------------------
    const diaOptPorHora = {
        plugins: {
            title: {
                display: false,
                text: "Agentes DIA por hora",
            },
        },
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {
                beginAtZero: true,
                max: 100,
                min: 0

            },
            y: {
                beginAtZero: false,
                max: 22,
                min: 0
            }
        },
    };
    const diaDataPorHora = {
        labels: [`8h (${Math.round(dia.ocho * 100 / 22)}%)`, `9h (${Math.round(dia.nueve * 100 / 22)}%)`, `10h (${Math.round(dia.diez * 100 / 22)}%)`, `11h (${Math.round(dia.once * 100 / 22)})%`, `12h (${Math.round(dia.doce * 100 / 22)}%)`, `13h (${Math.round(dia.trece * 100 / 22)}%)`, `14h (${Math.round(dia.catorce * 100 / 22)}%)`, `15h (${Math.round(dia.quince * 100 / 22)}%)`, `16h (${Math.round(dia.dieciseis * 100 / 22)}%)`, `17h (${Math.round(dia.diecisiete * 100 / 22)}%)`, `18h (${Math.round(dia.dieciocho * 100 / 22)}%)`, `19h (${Math.round(dia.diecinueve * 100 / 22)}%)`, `20h (${Math.round(dia.veinte * 100 / 22)}%)`],
        datasets: [
            {
                label: "DIA",
                data: diaArray,
                backgroundColor: diaArray.map((ele) => {
                    let colores = [];
                    if (ele >= 17) {
                        colores.push('rgb(255, 0, 0)');
                    } else {
                        colores.push('#D52D20');
                    }
                    return colores;
                }),
                stack: 'Stack 0',
                datalabels: {
                    color: 'white',
                    font: {
                        weight: 'bold',
                        size: 15,
                    },
                },
            }
        ],
    };
    //----------------- piano -----------------
    const ikeOptPorHora = {
        plugins: {
            title: {
                display: false,
                text: "Agentes IKE por hora",
            },
        },
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {

                beginAtZero: true,
                max: 100,
                min: 0

            },
            y: {

                beginAtZero: false,
                max: 37,
                min: 0

            }
        },
    };

    //--------------X28----------------------
    const x28OptPorHora = {
        plugins: {
            title: {
                display: false,
                text: "Agentes X28 por hora",
            },
        },
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {

                beginAtZero: true,
                max: 100,
                min: 0

            },
            y: {

                beginAtZero: false,
                max: 48,
                min: 0

            }
        },
    };
    const x28DataPorHora = {
        labels: [`8h (${Math.round(x28.ocho * 100 / 48)}%)`, `9h (${Math.round(x28.nueve * 100 / 48)}%)`, `10h (${Math.round(x28.diez * 100 / 48)}%)`, `11h (${Math.round(x28.once * 100 / 48)})%`, `12h (${Math.round(x28.doce * 100 / 48)}%)`, `13h (${Math.round(x28.trece * 100 / 48)}%)`, `14h (${Math.round(x28.catorce * 100 / 48)}%)`, `15h (${Math.round(x28.quince * 100 / 48)}%)`, `16h (${Math.round(x28.dieciseis * 100 / 48)}%)`, `17h (${Math.round(x28.diecisiete * 100 / 48)}%)`, `18h (${Math.round(x28.dieciocho * 100 / 48)}%)`, `19h (${Math.round(x28.diecinueve * 100 / 48)}%)`, `20h (${Math.round(x28.veinte * 100 / 48)}%)`],
        datasets: [
            {
                label: "X28",
                data: x28Array,
                backgroundColor: x28Array.map((ele) => {
                    let colores = [];
                    if (ele > 43) {
                        colores.push('rgb(255, 0, 0)');
                    } else {
                        colores.push('#004494');
                    }
                    return colores;
                }),
                stack: 'Stack 0',
                datalabels: {
                    color: 'white',
                    font: {
                        weight: 'bold',
                        size: 15,
                        anchor: 'end',
                        align: 'top',
                    },
                },
            }
        ],
    };

    //------------PIANO---------------------

    const pianoOptPorHora = {
        plugins: {
            title: {
                display: false,
                text: "Agentes PIANO por hora",
            },
        },
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {

                beginAtZero: true,
                max: 100,
                min: 0

            },
            y: {

                beginAtZero: false,
                max: 143,
                min: 0

            }
        },
    };
    const pianoDataPorHora = {
        labels: [`8h (${Math.round(piano.ocho * 100 / 143)}%)`, `9h (${Math.round(piano.nueve * 100 / 143)}%)`, `10h (${Math.round(piano.diez * 100 / 143)}%)`, `11h (${Math.round(piano.once * 100 / 143)})%`, `12h (${Math.round(piano.doce * 100 / 143)}%)`, `13h (${Math.round(piano.trece * 100 / 143)}%)`, `14h (${Math.round(piano.catorce * 100 / 143)}%)`, `15h (${Math.round(piano.quince * 100 / 143)}%)`, `16h (${Math.round(piano.dieciseis * 100 / 143)}%)`, `17h (${Math.round(piano.diecisiete * 100 / 143)}%)`, `18h (${Math.round(piano.dieciocho * 100 / 143)}%)`, `19h (${Math.round(piano.diecinueve * 100 / 143)}%)`, `20h (${Math.round(piano.veinte * 100 / 143)}%)`],
        datasets: [
            {
                label: "Banco Piano",
                data: pianoArray,
                backgroundColor: pianoArray.map((ele) => {
                    let colores = [];
                    if (ele >= 138) {
                        colores.push('rgb(255, 0, 0)');
                    } else {
                        colores.push('#02B0F3');
                    }
                    return colores;
                }),
                stack: 'Stack 0',
                datalabels: {
                    color: 'white',
                    font: {
                        weight: 'bold',
                        size: 15,
                    },
                },
            }
        ],
    };

    //----------PRUNDETIAL------------------
    const prudentialOptPorHora = {
        plugins: {
            title: {
                display: false,
                text: "Agentes PRUDENTIAL por hora",
            },
        },
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {

                beginAtZero: true,
                max: 100,
                min: 0

            },
            y: {

                beginAtZero: false,
                max: 16,
                min: 0

            }
        },
    };
    const prudentialDataPorHora = {
        labels: [`8h (${Math.round(prudential.ocho * 100 / 16)}%)`, `9h (${Math.round(prudential.nueve * 100 / 16)}%)`, `10h (${Math.round(prudential.diez * 100 / 16)}%)`, `11h (${Math.round(prudential.once * 100 / 16)}%)`, `12h (${Math.round(prudential.doce * 100 / 16)}%)`, `13h (${Math.round(prudential.trece * 100 / 16)}%)`, `14h (${Math.round(prudential.catorce * 100 / 16)}%)`, `15h (${Math.round(prudential.quince * 100 / 16)}%)`, `16h (${Math.round(prudential.dieciseis * 100 / 16)}%)`, `17h (${Math.round(prudential.diecisiete * 100 / 16)}%)`, `18h (${Math.round(prudential.dieciocho * 100 / 16)}%)`, `19h (${Math.round(prudential.diecinueve * 100 / 16)}%)`, `20h (${Math.round(prudential.veinte * 100 / 16)}%)`],
        datasets: [
            {
                label: "Prudential",
                data: prudentialArray,

                backgroundColor: prudentialArray.map((ele) => {
                    let colores = [];
                    if (ele >= 11) {
                        colores.push('rgb(255, 0, 0)');
                    } else {
                        colores.push('#007AC0');
                    }
                    return colores;
                }),
                stack: 'Stack 0',
                datalabels: {
                    color: 'white',
                    font: {
                        weight: 'bold',
                        size: 15,
                    },
                },
            }
        ],
    };
    //------  AMGAS -------------------
    const amgasOptPorHora = {
        plugins: {
            title: {
                display: false,
                text: "Agentes AMGAS por hora",
            },
        },
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {

                beginAtZero: true,
                max: 100,
                min: 0

            },
            y: {

                beginAtZero: false,
                max: 16,
                min: 0

            }
        },
    };
    const amgasDataPorHora = {
        labels: [`8h (${Math.round(amarilla.ocho * 100 / 16)}%)`, `9h (${Math.round(amarilla.nueve * 100 / 16)}%)`, `10h (${Math.round(amarilla.diez * 100 / 16)}%)`, `11h (${Math.round(amarilla.once * 100 / 16)}%)`, `12h (${Math.round(amarilla.doce * 100 / 16)}%)`, `13h (${Math.round(amarilla.trece * 100 / 16)}%)`, `14h (${Math.round(amarilla.catorce * 100 / 16)}%)`, `15h (${Math.round(amarilla.quince * 100 / 16)}%)`, `16h (${Math.round(amarilla.dieciseis * 100 / 16)}%)`, `17h (${Math.round(amarilla.diecisiete * 100 / 16)}%)`, `18h (${Math.round(amarilla.dieciocho * 100 / 16)}%)`, `19h (${Math.round(amarilla.diecinueve * 100 / 16)}%)`, `20h (${Math.round(amarilla.veinte * 100 / 16)}%)`],
        datasets: [
            {
                label: "Amarilla Gas",
                data: amgasArray,
                backgroundColor: amgasArray.map((ele) => {
                    let colores = [];
                    if (ele >= 11) {
                        colores.push('rgb(255, 0, 0)');
                    } else {
                        colores.push('#FAAA19');
                    }
                    return colores;
                }),
                stack: 'Stack 0',
                datalabels: {
                    color: 'white',
                    font: {
                        weight: 'bold',
                        size: 15,
                    },
                },
            }
        ],
    };
    //-------- AIRLIQUIDE ------------

    const airliquideOptPorHora = {
        plugins: {
            title: {
                display: false,
                text: "Agentes AIR-LIQUIDE por hora",
            },
        },
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {

                beginAtZero: true,
                max: 50,
                min: 0

            },
            y: {

                beginAtZero: false,
                max: 22,
                min: 0

            }
        },
    };
    const airliquideDataPorHora = {
        labels: [`8h (${Math.round(airliquide.ocho * 100 / 22)}%)`, `9h (${Math.round(airliquide.nueve * 100 / 22)}%)`, `10h (${Math.round(airliquide.diez * 100 / 22)}%)`, `11h (${Math.round(airliquide.once * 100 / 22)}%)`, `12h (${Math.round(airliquide.doce * 100 / 22)}%)`, `13h (${Math.round(airliquide.trece * 100 / 22)}%)`, `14h (${Math.round(airliquide.catorce * 100 / 22)}%)`, `15h (${Math.round(airliquide.quince * 100 / 22)}%)`, `16h (${Math.round(airliquide.dieciseis * 100 / 22)}%)`, `17h (${Math.round(airliquide.diecisiete * 100 / 16)}%)`, `18h (${Math.round(airliquide.dieciocho * 100 / 16)}%)`, `19h (${Math.round(airliquide.diecinueve * 100 / 16)}%)`, `20h (${Math.round(airliquide.veinte * 100 / 16)}%)`],
        datasets: [
            {
                label: "Air Liquide",
                data: airliquideArray,
                backgroundColor: airliquideArray.map((ele) => {
                    let colores = [];
                    if (ele >= 17) {
                        colores.push('rgb(255, 0, 0)');
                    } else {
                        colores.push('#DF1B2D');
                    }
                    return colores;
                }),
                stack: 'Stack 0',
                datalabels: {
                    color: 'white',
                    align: "top",
                    font: {
                        weight: 'bold',
                        size: 15,
                    },
                },
            }
        ],
    };

    //-----TIGRESET----------------
    const tigresetOptPorHora = {
        plugins: {
            title: {
                display: false,
                text: "Agentes TIGRE por hora",
            },
        },
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {

                beginAtZero: true,
                max: 50,
                min: 0

            },
            y: {

                beginAtZero: false,
                max: 10,
                min: 0

            }
        },
    };
    const tigresetDataPorHora = {
        labels: [`8h (${Math.round(tigreset.ocho * 100 / 10)}%)`, `9h (${Math.round(tigreset.nueve * 100 / 10)}%)`, `10h (${Math.round(tigreset.diez * 100 / 10)}%)`, `11h (${Math.round(tigreset.once * 100 / 10)}%)`, `12h (${Math.round(tigreset.doce * 100 / 10)}%)`, `13h (${Math.round(tigreset.trece * 100 / 10)}%)`, `14h (${Math.round(tigreset.catorce * 100 / 10)}%)`, `15h (${Math.round(tigreset.quince * 100 / 10)}%)`, `16h (${Math.round(tigreset.dieciseis * 100 / 10)}%)`, `17h (${Math.round(tigreset.diecisiete * 100 / 10)}%)`, `18h (${Math.round(tigreset.dieciocho * 100 / 10)}%)`, `19h (${Math.round(tigreset.diecinueve * 100 / 10)}%)`, `20h (${Math.round(tigreset.veinte * 100 / 10)}%)`],
        datasets: [
            {
                label: "Municipio de Tigre",
                data: tigresetArray,
                backgroundColor: tigresetArray.map((ele) => {
                    let colores = [];
                    if (ele >= 7) {
                        colores.push('rgb(255, 0, 0)');
                    } else {
                        colores.push('#FF0202');
                    }
                    return colores;
                }),
                stack: 'Stack 0',
                datalabels: {
                    color: 'white',
                    align: "top",
                    font: {
                        weight: 'bold',
                        size: 15,
                    },
                },
            }
        ],
    };

    const styles = {

        backColor: {
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            height: "100%",
            backgroundColor: `${darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"
                }`,
        },
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 800 ? ("3% 2% 3% 20%") : ("30px")}`,
            padding: `${screenSize > 800 ? "3% 2% 3% 280px" : "30px"}`,
            //  padding: `${screenSize > 800 ? "1% 0.1%" : "30px"}`,
            height: "100%",
            overflow: `auto`,
        },
        containerLoader: {
            display: "flex",
            alignItem: "center",
            justifyContent: "center",
            height: "80%",
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 800 ? "0px 20px" : "0px"}`,
            marginBottom: `${screenSize > 800 ? "0px" : "30px"}`,
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
        },
        titleNavbar: {
            marginBottom: `${screenSize > 800 ? "5px" : "10px"}`,
            fontSize: "28px",
            color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
            fontFamily: "InterBold",
            lineHeight: "1.4",
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            fontFamily: "InterBold",
        },
        toogleNavbar: {
            display: "flex",
            flex: 4,
            alignItems: "center",
            justifyContent: `${screenSize > 800 ? "flex-end" : "center"}`,
            flexDirection: `${screenSize > 800 ? "row" : "column"}`,
        },
        textToogle: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: "inherit",
            border: "none",
            outline: "none",
            padding: "12px",
            backgroundColor: `transparent`,
        },
        textUser: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: "inherit",
            border: "none",
            outline: "none",
            padding: 0,
            backgroundColor: `transparent`,
        },
        buttonNew: {
            textDecoration: "none",
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: "none",
            margin: "20px",
            // margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
            width: `${screenSize > 800 ? "180px" : <></>}`,
        },
        reports: {
            display: "flex",
            flex: 11,
            flexWrap: "wrap",
            width: `${screenSize > 800 ? "100%" : "80vw"}`,
            height: `${screenSize > 800 ? "100vh" : "80vw"}`,
            alignItems: "center",
            //  margin: `${screenSize > 800 ? ("20px 20px 0px") : ("0px")}`,
            margin: `${screenSize > 800 ? "0px 0px" : "0px"}`,
        },
        singleReport: {
            display: "flex",
            width: `${screenSize > 800 ? "50%" : "80vw"}`,
            height: `${screenSize > 800 ? "670px" : "80vw"}`,
            alignItems: "flex-start",
            justifyContent: "flex-start",
            borderRadius: "5px",
            padding: "10px 25px",
            textDecoration: "none",
            border: "none",
            outline: "none",
            marginBottom: "5%"
            //  margin: `${screenSize > 800 ? "1.4% 0px -10px" : "10px"}`,
        },
        barCharts: {
            display: "flex",
            //   width: `${screenSize > 800 ? ("95%") : ("80vw")}`,
            width: `${screenSize > 800 ? "50%" : "80vw"}`,
            height: `${screenSize > 800 ? "325px" : "80vw"}`,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "5px",
            padding: "10px 25px",
            textDecoration: "none",
            border: "none",
            outline: "none",
            //margin: `${screenSize > 800 ? "1.4% 0px -10px" : "10px"}`,
            marginTop: ` ${screenSize > 2000 ? "23%" : "8%"}`
        },
        //----------------------------------------------------------------
        barChartsBig: {
            display: "flex",
            //   width: `${screenSize > 800 ? ("95%") : ("80vw")}`,
            width: `${screenSize > 800 ? "95%" : "80vw"}`,
            height: `${screenSize > 800 ? "625px" : "80vw"}`,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "5px",
            padding: "10px 25px",
            textDecoration: "none",
            border: "none",
            outline: "none",
            margin: `${screenSize > 800 ? "1.4% 0px -10px" : "10px"}`,
        },

        reports: {
            display: "flex",
            flex: 11,
            flexDirection: 'column',
            margin: `${screenSize > 800 ? "0px 20px" : "0px"}`,
            overflow: `auto`,
            padding: '0px 0px 0px 0px',
            borderRadius: "10px",
            // backgroundColor: 'red'
        },
        singleLineReport: {
            display: "flex",
            flexDirection: 'row',
        },
        singleDoughnutChart: {
            //       display: "flex",
            flex: 1,
            alignItems: "flex-start",
            justifyContent: "flex-start",
            borderRadius: "10px",
            //  backgroundColor: 'red',
            padding: "25px",
            textDecoration: "none",
            border: "none",
            outline: "none",
            margin: `${screenSize > 800 ? "5px" : "10px"}`,
            width: '100px',
            // height: '500px'
        },
        singleBarCharts: {
            flex: 1,
            alignItems: "flex-start",
            justifyContent: "flex-start",
            borderRadius: "10px",
            //   backgroundColor: 'red',
            padding: "25px",
            textDecoration: "none",
            border: "none",
            outline: "none",
            margin: `${screenSize > 800 ? "5px" : "10px"}`,
            width: '100px',
            // height: '500px'
        },
        singleIndicator: {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            alignItems: "flex-start",
            justifyContent: "flex-start",
            borderRadius: "10px",
            //    backgroundColor: 'red',
            padding: "18px 25px",
            textDecoration: "none",
            border: "none",
            outline: "none",
            margin: `${screenSize > 800 ? "5px" : "10px"}`,
            //       backgroundColor: 'red'
        },
        singleIndicatorInfo: {
            flex: 2,
            flexDirection: 'column',
            //       backgroundColor: 'green'
        },
        singleIndicatorTitle: {
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            margin: "0px 0px 5px",
            fontSize: "18px",
            fontFamily: "InterBold",
            textAlign: "start",
        },
        singleIndicatorSubtitle: {
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            margin: "0px",
            fontSize: "12px",
            fontFamily: "InterBold",
            textAlign: "start",
        },
        singleIndicatorNumber: {
            flex: 1,
            alignSelf: 'flex-end',
            color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
            margin: "0px",
            fontSize: "40px",
            fontFamily: "InterBold",
            textAlign: "end",
            //     backgroundColor: 'blue'
        },


        //---------------------------------------------------------------


        lineDetail: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        phoneNumber: {
            display: "flex",
            flex: 4,
            color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
            margin: "0px",
            fontSize: "18px",
            fontFamily: "InterBold",
            textAlign: "start",
            alignSelf: "center",
        },
        option: {
            display: "flex",
            flex: 6,
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            margin: 0,
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
        },
        date: {
            display: "flex",
            flex: 2,
            color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
            margin: 0,
            fontSize: "14px",
            fontFamily: "InterBold",
            // textAlign: 'end'
            alignSelf: "center",
            justifyContent: "flex-end",
        },
        loader: {
            display: "flex",
            alignSelf: "center",
        },
        try: {
            position: "absolute",
        },
        generalIndicators: {
            display: "flex",
            flex: 1,
            flexWrap: "wrap",
            width: '100%',
            justifyContent: 'space-between',
            // alignItems: "center",
            margin: `${screenSize > 800 ? "0px 0px" : "0px"}`,
            padding: "0px 20px"
        },
        singleDetIndicator: {
            display: "flex",
            flex: 1,
            // width: `${screenSize > 800 ? ("49%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: "25px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            //  margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
            margin: `${screenSize > 800 ? ('20px 5px') : ("10px")}`,
        },
        contentSingleIndicatorLineTop: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            marginBottom: `12px`,
        },
        contentSingleIndicatorLineDown: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
        },
        contentSingleIndicatorLineTop: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            marginBottom: `12px`,
        },
        contentSingleIndicatorLineDown: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
        },
        textDetailCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            //  color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "14px",
            fontFamily: "InterBold",
        },
        icons: {
            width: '20px',
            height: '20px',
            color: '#4dc2f1'
        },
        numberLittleCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "30px",
            fontFamily: "InterBold",
        },
    };
    return (
        <>
            <div style={styles.backColor} />
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <h3 style={styles.titleNavbar}>{language === "english" ? "Licenses" : "Licencias"} 😃 </h3>
                        <h3 style={styles.subtitleNavbar}>{language === "english" ? "All licenses information." : "Todas la información de licencias."}</h3>
                    </div>
                </div>
                {loggedAgents.length ?
                    <>
                        <div style={styles.generalIndicators}>
                            <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleDetIndicator}>
                                <div style={styles.contentSingleIndicatorLineTop}>
                                    <div style={styles.textDetailCard}>{language === 'english' ? ('Actual licenses usage') : ('Licencias en uso ahora')}</div>
                                    {/* <svg style={styles.icons} fill="currentColor" class="bi bi-chat-dots" viewBox="0 0 16 16">
                                        <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                        <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z" />
                                    </svg> */}
                                </div>
                                <div style={styles.contentSingleIndicatorLineDown}>
                                    <div style={styles.numberLittleCard}>{loggedAgents.length ? servidores.noxus : <></>}</div>
                                </div>
                            </div>
                            <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleDetIndicator}>
                                <div style={styles.contentSingleIndicatorLineTop}>
                                    <div style={styles.textDetailCard}>{language === 'english' ? ('Total licenses') : ('Licencias totales')}</div>
                                    {/* <svg style={styles.icons} fill="currentColor" class="bi bi-phone" viewBox="0 0 16 16">
                                        <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                                        <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                    </svg> */}
                                </div>
                                <div style={styles.contentSingleIndicatorLineDown}>
                                    <div style={styles.numberLittleCard}>--</div>
                                </div>
                            </div>
                        </div>
                        <div style={styles.reports}>
                            <div style={styles.singleLineReport}>
                                <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleBarCharts}><Bar options={galiciaOptPorHora} plugins={[ChartDataLabels]} data={galiciaDataPorHora} /></div>
                                <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleBarCharts}><Bar options={BBVAOptPorHora} plugins={[ChartDataLabels]} data={BBVADataPorHora} /></div>
                            </div>
                            <div style={styles.singleLineReport}>
                                <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleBarCharts}><Bar options={aonOptPorHora} plugins={[ChartDataLabels]} data={aonDataPorHora} /></div>
                                <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleBarCharts}><Bar options={diaOptPorHora} plugins={[ChartDataLabels]} data={diaDataPorHora} /></div>
                            </div>
                            <div style={styles.singleLineReport}>
                                <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleBarCharts}><Bar options={x28OptPorHora} plugins={[ChartDataLabels]} data={x28DataPorHora} /></div>
                                <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleBarCharts}><Bar options={pianoOptPorHora} plugins={[ChartDataLabels]} data={pianoDataPorHora} /></div>

                            </div>
                            <div style={styles.singleLineReport}>
                                <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleBarCharts}><Bar options={prudentialOptPorHora} plugins={[ChartDataLabels]} data={prudentialDataPorHora} /></div>
                                <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleBarCharts}><Bar options={amgasOptPorHora} plugins={[ChartDataLabels]} data={amgasDataPorHora} /></div>
                            </div>
                            <div style={styles.singleLineReport}>
                                <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleBarCharts}><Bar options={airliquideOptPorHora} plugins={[ChartDataLabels]} data={airliquideDataPorHora} /></div>
                                <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleBarCharts}><Bar options={tigresetOptPorHora} plugins={[ChartDataLabels]} data={tigresetDataPorHora} /></div>
                            </div>
                        </div>
                    </>
                    : <></>}
            </div>
        </>
    );
};








