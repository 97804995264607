import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginUserPreferences } from "../store/actions/loginAction";
import ReportsCollab from "../components/ReportsCollab";
import Sidebar from "./Sidebar";
import firebase from '../config/firebaseIO';

const db = firebase.firestore();

const ReportsCollabClient = () => {
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [loggedAgents, setLoggedAgents] = useState([]);
    const dispatch = useDispatch();
    const loginUser = useSelector(state => state.user.loginUser);
    const language = useSelector(state => state.user.language);
    const darkTheme = useSelector(state => state.user.darkTheme);

    useEffect(() => {
        db.collection("companies").doc("47o0el3cqrHMAg71Yk9f").collection("collab-licenses").orderBy("timeStamp", "desc")
            .limit(130)
            .onSnapshot((ele) => {
                let estado = [];
                ele.forEach(elem => {
                    estado.push(elem.data())
                    // console.log("como llega>", elem.data())
                })
                setLoggedAgents(estado)
            });
    }, []);

    return (
        <div>
            <Sidebar />
            <ReportsCollab
                screenSize={screenSize}
                darkModeState={darkTheme}
                language={language}
                loggedAgents={loggedAgents}

            />
        </div>
    );
}

export default ReportsCollabClient;