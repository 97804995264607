import React from "react";
import { ExportToCsv } from "export-to-csv";
import ReactExport from "react-export-excel";
import Select from 'react-select'

// Para exportar los archivos en Excel
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


export default
    ({
        screenSize,
        darkModeState,
        language,
        reports,
        handleClick,
        opcionFilter,
        fecha1,
        fecha2,
        search,
        reset,
        onChange,
        arrayDeOpciones,
        surveyPolls,
        Oldsurveys,


    }) => {
        const styles = {
            backColor: {
                position: "fixed",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                height: "100%",
                backgroundColor: `${darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"
                    }`,
            },
            container: {
                position: "fixed",
                bottom: 0,
                top: 0,
                left: 0,
                right: 0,
                display: "flex",
                flexDirection: "column",
                //   padding: `${screenSize > 800 ? ("3% 2% 3% 20%") : ("30px")}`,
                padding: `${screenSize > 800 ? "3% 2% 3% 280px" : "30px"}`,
                height: "100%",
                // overflow: `auto`,
            },
            containerLoader: {
                display: "flex",
                alignItem: "center",
                justifyContent: "center",
                height: "80%",
            },
            navbar: {
                display: "flex",
                flex: 1.5,
                margin: `${screenSize > 800 ? "0px 20px" : "0px"}`,
                marginBottom: `${screenSize > 800 ? "0px" : "30px"}`,
            },
            textsNavbar: {
                display: "flex",
                flex: 8,
                flexDirection: "column",
            },
            titleNavbar: {
                marginBottom: `${screenSize > 800 ? "5px" : "10px"}`,
                fontSize: "28px",
                color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
                fontFamily: "InterBold",
                lineHeight: "1.4",
            },
            subtitleNavbar: {
                margin: 0,
                fontSize: "14px",
                color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
                fontFamily: "InterBold",
            },
            toogleNavbar: {
                display: "flex",
                flex: 4,
                alignItems: "center",
                justifyContent: `${screenSize > 800 ? "flex-end" : "center"}`,
                flexDirection: `${screenSize > 800 ? "row" : "column"}`,
            },
            textToogle: {
                fontSize: "14px",
                fontFamily: "InterBold",
                alignSelf: "center",
                textDecoration: "none",
                color: "inherit",
                border: "none",
                outline: "none",
                padding: "12px",
                backgroundColor: `transparent`,
            },
            textUser: {
                fontSize: "14px",
                fontFamily: "InterBold",
                alignSelf: "center",
                textDecoration: "none",
                color: "inherit",
                border: "none",
                outline: "none",
                padding: 0,
                backgroundColor: `transparent`,
            },
            buttonNew: {
                textDecoration: "none",
                color: "#fff",
                backgroundColor: "#4dc2f1",
                border: "none",
                margin: "20px",
                // margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
                width: `${screenSize > 800 ? "180px" : <></>}`,
            },
            total: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                margin: `${screenSize > 800 ? "10px 20px 10px" : "0px"}`,
            },
            filters: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: "flex-end",
                justifyContent: "space-between",
                margin: `${screenSize > 800 ? "10px 20px 20px" : "0px"}`,
            },
            filterGroup: {
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                padding: '0px 20px 0px 0px'
            },
            filterDateGroup: {
                display: "flex",
                flexDirection: "row",
            },
            filterLabel: {
                fontFamily: "InterBold",
                color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
                padding: '0px 5px'
            },
            filterInputDate: {
                border: 'none',
                color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
                fontFamily: "InterBold",
                width: '100%',
                height: '40px',
                borderRadius: "5px",
                padding: '5px 10px',
                margin: '0px 5px 0px 0px'
            },
            filterInput: {
                border: 'none',
                color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
                fontFamily: "InterBold",
                width: '100%',
                height: '40px',
                borderRadius: "5px",
                padding: '5px 10px'
            },
            filterButton: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textDecoration: "none",
                outline: 0,
                color: "#fff",
                backgroundColor: "#4dc2f1",
                border: 'none',
                borderRadius: 5,
                margin: 0,
                padding: '8px 13px',
                width: '100px'
            },
            resetButton: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textDecoration: "none",
                outline: 0,
                color: "#4dc2f1",
                backgroundColor: "transparent",
                border: '1px #4dc2f1 solid',
                borderRadius: 5,
                margin: "0px 0px 0px 10px",
                padding: '8px 13px',
                width: '100px'
            },
            singleReportReference: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
                margin: `${screenSize > 800 ? "0px 20px" : "0px"}`,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "5px",
                padding: "0px 25px",
                textDecoration: "none",
                border: "none",
                outline: "none",
                // margin: `${screenSize > 800 ? "1.4% 0px -10px" : "10px"}`,
            },
            reports: {
                display: "flex",
                flex: 11,
                flexDirection: "column",
                alignItems: "center",
                margin: `${screenSize > 800 ? "0px 20px" : "0px"}`,
                overflow: `auto`,

            },
            singleReport: {
                display: "flex",
                //   width: `${screenSize > 800 ? ("95%") : ("80vw")}`,
                width: `${screenSize > 800 ? "100%" : "80vw"}`,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "5px",
                padding: "10px 25px",
                textDecoration: "none",
                border: "none",
                outline: "none",
                margin: `${screenSize > 800 ? "1.4% 0px -10px" : "10px"}`,
            },

            singleBadReport: {
                display: "flex",
                //   width: `${screenSize > 800 ? ("95%") : ("80vw")}`,
                width: `${screenSize > 800 ? "100%" : "80vw"}`,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "5px",
                padding: "10px 25px",
                textDecoration: "none",
                border: "none",
                outline: "none",
                margin: `${screenSize > 800 ? "1.4% 0px -10px" : "10px"}`,
                backgroundColor: "rgba(255, 0, 0, 0.2)",
            },



            lineDetail: {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            },
            date: {
                display: "flex",
                flex: 1,
                color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
                margin: 0,
                fontSize: "12px",
                fontFamily: "InterBold",
                textAlign: 'left',
                alignSelf: "center",
                justifyContent: "flex-start",
                paddingRight: '15px'
            },
            phoneNumber: {
                display: "flex",
                flex: 2,
                color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
                margin: 0,
                fontSize: "12px",
                fontFamily: "InterBold",
                textAlign: "left",
                alignSelf: "center",
                justifyContent: "flex-start",
                paddingRight: '15px'
            },
            option: {
                display: "flex",
                flex: 3,
                color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
                margin: 0,
                fontSize: "12px",
                fontFamily: "InterBold",
                textAlign: "left",
                alignSelf: "center",
                justifyContent: "flex-start",
                paddingRight: '15px'
            },
            information: {
                display: "flex",
                flex: 2,
                color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
                margin: 0,
                fontSize: "12px",
                fontFamily: "InterBold",
                textAlign: "left",
                alignSelf: "center",
                justifyContent: "flex-start",
                paddingRight: '15px'
            },
            answer: {
                display: "flex",
                flex: 1,
                color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
                margin: 0,
                fontSize: "12px",
                fontFamily: "InterBold",
                textAlign: 'left',
                alignSelf: "center",
                justifyContent: "center",
                paddingRight: '15px'
            },
            time: {
                display: "flex",
                flex: 1,
                color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
                margin: 0,
                fontSize: "12px",
                fontFamily: "InterBold",
                textAlign: 'left',
                alignSelf: "center",
                justifyContent: "flex-start",
                //   paddingRight: '0px'
            },
            singleCounter: {
                display: "flex",
                width: `${screenSize > 800 ? "100%" : "80vw"}`,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "5px",
                padding: "10px 25px",
                textDecoration: "none",
                border: "none",
                outline: "none",
                margin: `${screenSize > 800 ? "2.4% 0px -10px" : "10px"}`,
                backgroundColor: '#4dc2f1',
                color: '#fff'
            },
            titleCounter: {
                display: "flex",
                flex: 4,
                color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
                margin: "0px",
                fontSize: "18px",
                fontFamily: "InterBold",
                textAlign: "start",
                alignSelf: "center",
                color: '#fff'
            },
            numberCounter: {
                display: "flex",
                flex: 2,
                color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
                margin: 0,
                fontSize: "14px",
                fontFamily: "InterBold",
                textAlign: 'end',
                alignSelf: "center",
                justifyContent: "flex-end",
                color: '#fff'
            },
            downloadsContainer: {
                display: "flex",
                width: `${screenSize > 800 ? "100%" : "80vw"}`,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
                borderRadius: "5px",
                padding: "10px 0px",
                textDecoration: "none",
                border: "none",
                outline: "none",
                margin: `${screenSize > 800 ? "1.4% 0px 0px" : "10px"}`,
                backgroundColor: 'transparent',
            },
            downloadsButton: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textDecoration: "none",
                outline: 0,
                color: "#4dc2f1",
                backgroundColor: "transparent",
                border: '1px #4dc2f1 solid',
                borderRadius: 5,
                margin: "0px 0px 0px 10px",
                padding: '8px 13px',
                width: '180px'
            },
            loader: {
                display: "flex",
                alignSelf: "center",
            },
        };
        let encuestas = [];
        // si existe filtro se cambian la cantidad de encuestas que trae

        let unixTimestamp = new Date().getTime()
        let unixTimestampARG = unixTimestamp - 10800000
        let date = new Date(unixTimestampARG);
        let day = date.getDate()
        let dayString = day.toString()
        if (dayString.length === 1) { dayString = '0' + dayString }
        let month = date.getMonth() + 1
        let monthString = month.toString()
        if (monthString.length === 1) { monthString = '0' + monthString }
        let year = date.getFullYear()
        let dateToday = year + "-" + monthString + "-" + dayString;



        if (fecha1 && fecha2) {
            for (let j = 0; j < surveyPolls.length; j++) {
                for (let i = 0; i < Oldsurveys.length; i++) {
                    if (Oldsurveys[i].survey_id == surveyPolls[j].data.id_survey) {
                        for (let k = 0; k < surveyPolls[j].data.questions.length; k++) {
                            if (Oldsurveys[i].nestedAnswers[k]) {
                                encuestas.push({
                                    name: surveyPolls[j].data.name,
                                    channel: surveyPolls[j].data.channel,
                                    service: Oldsurveys[i].service == "" ? "Otros" : Oldsurveys[i].service,
                                    timestamp: Oldsurveys[i].timestamp * 1000,
                                    date: Oldsurveys[i].date + " " + Oldsurveys[i].time,
                                    value: Oldsurveys[i].value_answer,
                                    question: surveyPolls[j].data.questions[k].question,
                                    sessionNumber: Oldsurveys[i].sessionNumber ? Oldsurveys[i].sessionNumber : "",
                                    dni: !Oldsurveys[i].dni ? "---" : Oldsurveys[i].dni == "" ? "---" : Oldsurveys[i].dni,
                                    phoneNumber: !Oldsurveys[i].nroTelefono ? "vino vacio" :
                                        Oldsurveys[i].nroTelefono == "anonymous" || Oldsurveys[i].nroTelefono == "Anonymous" ? "Anónimo" :
                                            Oldsurveys[i].nroTelefono,
                                    agentName: Oldsurveys[i].agentName == "0 - undefined" || Oldsurveys[i].agentName == " - undefined" ? "Anónimo" :
                                        Oldsurveys[i].agentName == "" || Oldsurveys[i].agentName == " - " ? " ---"
                                            : !Oldsurveys[i].agentName ? "Anónimo"
                                                : Oldsurveys[i].agentName,
                                    value:
                                        (surveyPolls[j].data.questions[k].question == "¿Tu consulta fue resuelta en este contacto?" && Oldsurveys[i].nestedAnswers[k] == 1) ||
                                            (surveyPolls[j].data.questions[k].question == "¿Resolvimos el motivo de tu llamado?" && Oldsurveys[i].nestedAnswers[k] == 1) ||
                                            (surveyPolls[j].data.questions[k].question == "¿Su consulta fue resuelta en este contacto?" && Oldsurveys[i].nestedAnswers[k] == 1)
                                            ? "Si" :
                                            (surveyPolls[j].data.questions[k].question == "¿Tu consulta fue resuelta en este contacto?" && Oldsurveys[i].nestedAnswers[k] == 2) ||
                                                (surveyPolls[j].data.questions[k].question == "¿Resolvimos el motivo de tu llamado?" && Oldsurveys[i].nestedAnswers[k] == 2) ||
                                                (surveyPolls[j].data.questions[k].question == "¿Su consulta fue resuelta en este contacto?" && Oldsurveys[i].nestedAnswers[k] == 2)
                                                ? "No"
                                                : Oldsurveys[i].nestedAnswers[k],
                                });
                            }
                        }
                    }
                };
            }
        } else {
            for (let j = 0; j < surveyPolls.length; j++) {
                for (let i = 0; i < reports.length; i++) {
                    if (reports[i].survey_id == surveyPolls[j].data.id_survey) {
                        for (let k = 0; k < surveyPolls[j].data.questions.length; k++) {
                            if (reports[i].nestedAnswers[k]) {
                                encuestas.push({
                                    name: surveyPolls[j].data.name,
                                    channel: surveyPolls[j].data.channel,
                                    service: reports[i].service == "" ? "Otros" : reports[i].service,
                                    sessionNumber: reports[i].sessionNumber ? reports[i].sessionNumber : "",
                                    timestamp: reports[i].timestamp * 1000,
                                    date: reports[i].date + " " + reports[i].time,
                                    value: reports[i].value_answer,
                                    question: surveyPolls[j].data.questions[k].question,
                                    dni: !reports[i].dni ? "---" : reports[i].dni == "" ? "---" : reports[i].dni,
                                    phoneNumber: !reports[i].nroTelefono ? "Anónimo" :
                                        reports[i].nroTelefono == "anonymous" || reports[i].nroTelefono == "Anonymous" ? "Anónimo" :
                                            reports[i].nroTelefono,
                                    agentName: reports[i].agentName == "0 - undefined" || reports[i].agentName == " - undefined" ? "Anónimo" :
                                        reports[i].agentName == "" || reports[i].agentName == " - " ? "Anónimo"
                                            : !reports[i].agentName ? "Anónimo"
                                                : reports[i].agentName,
                                    value:
                                        (surveyPolls[j].data.questions[k].question == "¿Tu consulta fue resuelta en este contacto?" && reports[i].nestedAnswers[k] == 1) ||
                                            (surveyPolls[j].data.questions[k].question == "¿Resolvimos el motivo de tu llamado?" && reports[i].nestedAnswers[k] == 1) ||
                                            (surveyPolls[j].data.questions[k].question == "¿Su consulta fue resuelta en este contacto?" && reports[i].nestedAnswers[k] == 1)
                                            ? "Si" :
                                            (surveyPolls[j].data.questions[k].question == "¿Tu consulta fue resuelta en este contacto?" && reports[i].nestedAnswers[k] == 2) ||
                                                (surveyPolls[j].data.questions[k].question == "¿Resolvimos el motivo de tu llamado?" && reports[i].nestedAnswers[k] == 2) ||
                                                (surveyPolls[j].data.questions[k].question == "¿Su consulta fue resuelta en este contacto?" && reports[i].nestedAnswers[k] == 2)
                                                ? "No"
                                                : reports[i].nestedAnswers[k],
                                });
                            }
                        }
                    }
                };
            }
        };

        let opcionesArrayFiltrar = [];

        let preguntasunicas = Array.from(new Set(encuestas.map(ele => ele.question)));

        preguntasunicas.forEach(ele => opcionesArrayFiltrar.unshift({ value: ele, label: ele }))
        //array que guardara los datos a exportar y muestra la longitud de los datos mostrados en pantalla
        let csvFile = [];

        //opciones para la descarga de cvs

        let titleCSV = `OneReport-${dateToday}`

        const options = {
            fieldSeparator: ',',
            filename: titleCSV,
            quoteStrings: '"',
            decimalSeparator: ".",
            showLabels: true,
            showTitle: false,
            useBom: true,
            useTextFile: false,
            useKeysAsHeaders: false,
            headers: ["Fecha", "Número", "Dni", "Agente", "Canal", "Campaña", "Encuesta", "Pregunta", "Respuesta"]
        };

        //captura los valores de los filtros
        // let elemento = document.getElementById("opciones");
        let startDate = document.getElementById("startDate");
        let endDate = document.getElementById("endDate");
        let searchBar = document.getElementById("searchBar");
        // resetea los valores de los filtros

        //funcion para descargar en formato cvs
        let downloadCvs = () => {
            const csvExporter = new ExportToCsv(options);
            csvExporter.generateCsv(csvFile);
        };

        //AQUI 
        if (opcionFilter != "" && fecha2 != "" && fecha1 != "" && search != "") {
            // FILTRO POR OPCION FECHA Y BUSQUEDA

            if ((fecha2 + 86400000) - fecha1 >= (2629743000 + 86400000)) {
                window.alert("La fecha debe ser menor a un mes");
                reset(startDate, endDate, searchBar, arrayDeOpciones)
            } else {

                encuestas = encuestas.filter((singleReport) => {

                    singleReport.service.toLowerCase();
                    singleReport.value.toLowerCase();
                    singleReport.name.toLowerCase();
                    singleReport.agentName.toLowerCase();

                    if (
                        (opcionFilter.includes(singleReport.question) &&
                            singleReport.timestamp >= fecha1 &&
                            singleReport.timestamp <= fecha2 + 86400 * 1000 &&
                            singleReport.name.toLowerCase().match(search.toLowerCase())

                        ) ||
                        (opcionFilter.includes(singleReport.question) &&
                            singleReport.timestamp >= fecha1 &&
                            singleReport.timestamp <= fecha2 + 86400 * 1000 &&
                            singleReport.service.toLowerCase().match(search.toLowerCase())

                        ) ||
                        (
                            opcionFilter.includes(singleReport.question) &&
                            singleReport.timestamp >= fecha1 &&
                            singleReport.timestamp <= fecha2 + 86400 * 1000 &&
                            singleReport.value.toLowerCase().match(search.toLowerCase())
                        ) ||
                        (
                            opcionFilter.includes(singleReport.question) &&
                            singleReport.timestamp >= fecha1 &&
                            singleReport.timestamp <= fecha2 + 86400 * 1000 &&
                            singleReport.agentName.toLowerCase().match(search.toLowerCase())
                        ) ||
                        (opcionFilter.includes(singleReport.question) &&
                            singleReport.timestamp >= fecha1 &&
                            singleReport.timestamp <= fecha2 + 86400 * 1000 &&
                            singleReport.dni.match(search.toLowerCase())
                        ) ||
                        (opcionFilter.includes(singleReport.question) &&
                            singleReport.timestamp >= fecha1 &&
                            singleReport.timestamp <= fecha2 + 86400 * 1000 &&
                            singleReport.phoneNumber.match(search)
                        )
                    ) {
                        return singleReport;
                    }
                });
            }
        }
        else if (fecha1 != "" && opcionFilter != "" && fecha2 != "") {
            //FILTRO POR FECHAS Y OPTION BAR 
            if ((fecha2 + 86400000) - fecha1 >= (2629743000 + 86400000)) {
                window.alert("La fecha debe ser menor a un mes");
                reset(startDate, endDate, searchBar, arrayDeOpciones)
            } else {
                encuestas = encuestas.filter((singleReport) => {
                    if (
                        opcionFilter.includes(singleReport.question) &&
                        singleReport.timestamp >= fecha1 &&
                        singleReport.timestamp <= fecha2 + 86400 * 1000
                    ) {
                        return singleReport;
                    }
                });
            }
        }
        else if (fecha1 != "" && search != "" && fecha2 != "") {
            //FILTRO POR SEARCH BAR Y FECHA
            if ((fecha2 + 86400000) - fecha1 >= (2629743000 + 86400000)) {
                window.alert("La fecha debe ser menor a un mes");
                reset(startDate, endDate, searchBar, arrayDeOpciones)
            } else {
                encuestas = encuestas.filter((singleReports) => {
                    singleReports.name.toLowerCase();
                    singleReports.service.toLowerCase();
                    singleReports.agentName.toLowerCase();

                    if (
                        (singleReports.name.toLowerCase().match(search.toLowerCase()) &&
                            singleReports.timestamp >= fecha1 &&
                            singleReports.timestamp <= fecha2 + 86400 * 1000

                        ) ||

                        (singleReports.service.toLowerCase().match(search.toLowerCase()) &&
                            singleReports.timestamp >= fecha1 &&
                            singleReports.timestamp <= fecha2 + 86400 * 1000

                        ) ||

                        (singleReports.value.toLowerCase().match(search.toLowerCase()) &&
                            singleReports.timestamp >= fecha1 &&
                            singleReports.timestamp <= fecha2 + 86400 * 1000

                        ) ||

                        (singleReports.dni.toLowerCase().match(search.toLowerCase()) &&
                            singleReports.timestamp >= fecha1 &&
                            singleReports.timestamp <= fecha2 + 86400 * 1000

                        ) ||
                        (singleReports.phoneNumber.toLowerCase().match(search.toLowerCase()) &&
                            singleReports.timestamp >= fecha1 &&
                            singleReports.timestamp <= fecha2 + 86400 * 1000
                        ) ||

                        (singleReports.agentName.toLowerCase().match(search.toLowerCase()) &&
                            singleReports.timestamp >= fecha1 &&
                            singleReports.timestamp <= fecha2 + 86400 * 1000)

                    ) {
                        return singleReports;
                    }
                });

            }

        }
        else if (search != "" && opcionFilter != "") {
            //FILTRO POR SEARCH BAR Y OPTION BAR
            encuestas = encuestas.filter((singleReports) => {
                singleReports.name.toLowerCase();
                singleReports.service.toLowerCase();
                singleReports.agentName.toLowerCase();

                if (
                    (opcionFilter.includes(singleReports.question) && singleReports.name.toLowerCase().match(search.toLowerCase())) ||
                    (opcionFilter.includes(singleReports.question) && singleReports.service.toLowerCase().match(search.toLowerCase())) ||
                    (opcionFilter.includes(singleReports.question) && singleReports.value.toLowerCase().match(search.toLowerCase())) ||
                    (opcionFilter.includes(singleReports.question) && singleReports.phoneNumber.toLowerCase().match(search.toLowerCase())) ||
                    (opcionFilter.includes(singleReports.question) && singleReports.dni.toLowerCase().match(search.toLowerCase()) ||
                        opcionFilter.includes(singleReports.question) && singleReports.agentName.toLowerCase().match(search.toLowerCase())

                    )

                    ||
                    (opcionFilter.includes(singleReports.question) && singleReports.agentName.toLowerCase().match(search.toLowerCase())

                    )
                ) {
                    return singleReports;
                }
            });
        }
        else if (search != "") {
            //FILTRO POR SEARCH BAR
            encuestas = encuestas.filter((singleReports) => {
                singleReports.name.toLowerCase()
                singleReports.service.toLowerCase()
                if (
                    singleReports.name.toLowerCase().match(search.toLowerCase()) ||
                    singleReports.service.toLowerCase().match(search.toLowerCase()) ||
                    singleReports.value.toLowerCase().match(search.toLowerCase()) ||
                    singleReports.dni.toLowerCase().match(search.toLowerCase()) ||
                    singleReports.phoneNumber.toLowerCase().match(search.toLowerCase()) ||
                    singleReports.agentName.toLowerCase().match(search.toLowerCase()

                    )

                ) {
                    return singleReports;
                }
            });
        }
        else if (opcionFilter != "") {
            //FILTRO POR OPTION BAR
            encuestas = encuestas.filter(
                ((singleReport) => {
                    if (opcionFilter.includes(singleReport.question)) {
                        return singleReport;
                    }
                }
                )
            );
        }
        else if (fecha1 != "" && fecha2 != "") {
            //FILTRO POR FECHA
            if ((fecha2 + 86400000) - fecha1 >= (2629743000 + 86400000)) {
                window.alert("Las fechas a filtrar deben ser menor a un mes");
                reset(startDate, endDate, searchBar, arrayDeOpciones)
            } else {
                encuestas = encuestas.filter(
                    (singleReport) => singleReport.timestamp >= fecha1 && singleReport.timestamp <= fecha2 + 86400000);
            }


        }
        else if (opcionFilter == "" && fecha1 == "" && search == "") {
            encuestas = encuestas;
        };
        return (
            <>
                <div style={styles.backColor} />
                <div style={styles.container}>
                    <div style={styles.navbar}>
                        <div style={styles.textsNavbar}>
                            <h3 style={styles.titleNavbar}>{language === "english" ? "Reports" : "Reportes"} 😃</h3>
                            <h3 style={styles.subtitleNavbar}>{language === "english" ? "All surveys reports." : "Todos los reportes de encuestas."}</h3>
                        </div>
                    </div>


                    <div style={styles.filters}>
                        <div style={styles.filterGroup}>
                            <label style={styles.filterLabel}>{language === "english" ? "Date filter" : "Filtro por fecha"}</label>
                            <div style={styles.filterDateGroup}>
                                <input className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.filterInputDate} type="date" id="startDate" />
                                <input className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.filterInput} type="date" id="endDate" />
                            </div>
                        </div>
                        <div style={styles.filterGroup}>
                            <label style={styles.filterLabel}>{language === "english" ? "Question filter" : "Filtro por pregunta"}</label>
                            <Select name="opciones" id="opciones" isMulti options={opcionesArrayFiltrar} onChange={(valor) => { onChange(valor) }} className="basic-multi-select" classNamePrefix="select" />
                        </div>
                        <div style={styles.filterGroup}>
                            <label style={styles.filterLabel}>{language === "english" ? "Search filter" : "Filtro por búsqueda"}</label>
                            <input className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.filterInput} type="text" name="searchBar" id="searchBar" placeholder={language === "english" ? "Search..." : "Buscar..."} />
                        </div>
                        <button style={styles.filterButton} onClick={() => { handleClick(arrayDeOpciones, startDate, endDate, searchBar) }}>{language === "english" ? "Apply" : "Filtrar"}</button>
                        <button style={styles.resetButton} onClick={() => { reset(startDate, endDate, searchBar, arrayDeOpciones) }}>{language === "english" ? "Reset" : "Limpiar"}</button>
                    </div>


                    <div
                        className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleReportReference}>
                        <div style={styles.date}>{language === "english" ? "Date" : "Fecha"}</div>
                        <div style={styles.phoneNumber}>{language === "english" ? "Phone Number" : "Número"}</div>
                        <div style={styles.date}>{language === "english" ? "DNI" : "DNI"}</div>
                        <div style={styles.date}>{language === "english" ? "Agent" : "Agente"}</div>


                        <div style={styles.date}>{language === "english" ? "Channel" : "Canal"}</div>
                        <div style={styles.option}>{language === "english" ? "Survey" : "Encuesta"}</div>
                        <div style={styles.option}>{language === "english" ? "Campaign" : "Campaña"}</div>
                        <div style={styles.option}>{language === "english" ? "Question" : "Pregunta"}</div>
                        <div style={styles.answer}>{language === "english" ? "Answer" : "Respuesta"}</div>
                    </div>
                    <div style={styles.reports}>

                        {encuestas != [] ? (<>

                            {
                                //Se usa el metodo sort para ordenar por timestamp las interacciones
                                encuestas.sort((x, y) => { return y.timestamp - x.timestamp }).map((singleReport) => {
                                    //los usuarios encontrados se empujan a una variable, asi se muestra en pantalla todos los usuarios encontrados
                                    // y facilita su descarga en tanto en excel como en csv
                                    csvFile.push({
                                        date: singleReport.date,
                                        phoneNumber: singleReport.phoneNumber ? singleReport.phoneNumber : "",
                                        dni: singleReport.dni,
                                        agente: singleReport.agentName ? singleReport.agentName : "Anónimo",
                                        channel: singleReport.channel == "audio" ? "Audio" : "WhatsApp",
                                        encuesta: singleReport.name,
                                        campaña: singleReport.service,
                                        // sessionNumber: singleReport.sessionNumber,
                                        question: singleReport.question,
                                        value: singleReport.value,
                                    });
                                    //verifica si el campo tiene mas de una letra, si es asi eleva la primera letra a mayuscula, lo mismo el apellido.
                                    return (
                                        <div className={darkModeState ? "buttonDark" : "buttonLight"}
                                            style={
                                                singleReport.question == "¿Resolvimos el motivo de tu llamado?" && singleReport.value != "Si" ||
                                                    singleReport.question == "¿Tu consulta fue resuelta en este contacto?" && singleReport.value != "Si" ||
                                                    singleReport.question == "¿Su consulta fue resuelta en este contacto?" && singleReport.value != "Si" ? styles.singleBadReport :

                                                    singleReport.question != "¿Resolvimos el motivo de tu llamado?" && singleReport.value < 3 ||
                                                        singleReport.question != "¿Tu consulta fue resuelta en este contacto?" && singleReport.value < 3 ||
                                                        singleReport.question != "¿Su consulta fue resuelta en este contacto?" && singleReport.value < 3 ? styles.singleBadReport :

                                                        styles.singleReport}>

                                            <div style={styles.date}>{singleReport.date}</div>
                                            <div style={styles.phoneNumber}>{singleReport.phoneNumber}</div>
                                            <div style={styles.date}>{singleReport.dni}</div>
                                            <div style={styles.date}>{singleReport.agentName}</div>
                                            <div style={styles.date}>{singleReport.channel == "audio" ? ("Audio") : ("WhatsApp")}</div>
                                            <div style={styles.option}>{singleReport.name}</div>
                                            <div style={styles.option}>{singleReport.service}</div>
                                            <div style={styles.option}>{singleReport.question} </div>
                                            <div style={styles.answer}>{singleReport.value}</div>
                                        </div>
                                    );
                                })}
                            <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.singleCounter}>
                                <div style={styles.titleCounter}>Total</div>
                                <div style={styles.numberCounter}>{csvFile.length}</div>
                            </div>

                            <div className={darkModeState ? "buttonDark" : "buttonLight"} style={styles.downloadsContainer}>
                                <ExcelFile element={<button style={styles.downloadsButton}>{language === "english" ? "Download Excel" : "Descargar Excel"}</button>} filename={`OneReport - ${dateToday}`}>
                                    <ExcelSheet data={csvFile} name={`Reporte - ${dateToday}`}>
                                        <ExcelColumn label="Fecha" value="date" />
                                        <ExcelColumn label="Número" value="phoneNumber" />
                                        <ExcelColumn label="DNI" value="dni" />
                                        <ExcelColumn label="Agente" value="agente" />
                                        <ExcelColumn label="Canal" value="channel" />
                                        <ExcelColumn label="Campaña" value="campaña" />
                                        <ExcelColumn label="Encuesta" value="encuesta" />
                                        <ExcelColumn label="Pregunta" value="question" />
                                        <ExcelColumn label="Respuesta" value="value" />
                                    </ExcelSheet>
                                </ExcelFile>
                                <button style={styles.downloadsButton} onClick={downloadCvs}>{language === "english" ? "Download CSV" : "Descargar CSV"}</button>
                            </div>

                        </>) : (<>
                        </>)

                        }

                    </div>
                </div>
            </>
        );
    }