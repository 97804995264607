import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginUserPreferences } from "../store/actions/loginAction";
import ChartsGalicia from "../components/ChartsGalicia";

import Sidebar from "./Sidebar";
import firebase from "../config/firebaseIO";

const db = firebase.firestore();

const ChartClientsGalicia = () => {

    const [screenSize, setScreenSize] = useState(window.innerWidth);

    const [opcionFilter, setEl2] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [arrayDeOpciones, setArrayDeOpciones] = useState([]);
    const [displayCharts, setDisplayCharts] = useState(false);

    const dispatch = useDispatch()
    const loginUser = useSelector(state => state.user.loginUser)
    const language = useSelector(state => state.user.language)
    const darkTheme = useSelector(state => state.user.darkTheme)

    const [Oldsurveys, SetOldSurveys] = useState([]);
    const [FilterOn, setfilterOn] = useState(false);


    const [surveysGalicia, SetSurveysGalicia] = useState([]);
    const [surveyPolls, SetSurveysPolls] = useState([]);

    const getPolls = async (startDate, endDate) => {

        if (FilterOn == true) {
            try {

                let singlePollId = await db.collection("companies")
                    .doc("HZZb5MxJI1zVkQJ8MJwk")
                    .collection("SURVEY_POLLS").get();

                let oldAnswers = [];

                singlePollId.docs.forEach(async (singleSurvey) => {
                    await db
                        .collection("companies")
                        .doc("HZZb5MxJI1zVkQJ8MJwk")
                        .collection("SURVEY_POLLS")
                        .doc(singleSurvey.id)
                        .collection("ANSWERS")
                        .where("timestamp", ">=", (startDate + 10800000) / 1000)
                        .where("timestamp", "<=", ((endDate + 86400000 + 10799900) / 1000))
                        //    .limit(15)
                        .orderBy("timestamp", "desc")
                        .get().then((docs) => {
                            docs.forEach((ele) => oldAnswers.push(ele.data()));
                            oldAnswers = oldAnswers.slice();
                            SetOldSurveys(oldAnswers);

                        });

                });
            }
            catch (err) {
                console.log(err)
            }
        }
        else {
            try {

                let today = new Date().getTime();
                let answers = [];
                let singlePollId = await db.collection("companies")
                    .doc("HZZb5MxJI1zVkQJ8MJwk")
                    .collection("SURVEY_POLLS").get();

                let ids = [];
                singlePollId.docs.forEach((ele) => ids.push(ele.id));
                let single = singlePollId;
                singlePollId = singlePollId.docs.map(ele => ele.data());

                SetSurveysPolls(singlePollId.slice());
                ids.forEach((ele) => {
                    db.collection("companies")
                        .doc("HZZb5MxJI1zVkQJ8MJwk")
                        .collection("SURVEY_POLLS")
                        .doc(ele)
                        .collection("ANSWERS")
                        .where("timestamp", "<=", (today - 604800000))
                        .orderBy('timestamp', "desc")
                        //  .limit(15) 
                        .onSnapshot((querySnapshot) => {
                            querySnapshot.docChanges().forEach((change) => {
                                if (change.type === "added") {
                                    answers.push(change.doc.data());
                                }
                            });
                            SetSurveysGalicia(answers.slice());
                        })
                })
            }
            catch (err) {
                console.log(err)
            }
        }
    };


    useEffect(() => {
        getPolls(startDate, endDate);

    }, [FilterOn, startDate, endDate]);


    let onChange = (valor) => {
        setArrayDeOpciones(valor.map(ele => ele.value))
    }

    const reset = (arrayDeOpciones, date1, date2) => {
        setStartDate("");
        setEndDate("");
        arrayDeOpciones = ""
        date1.value = ("");
        date2.value = ("");
        setEl2("");
        setDisplayCharts(false);
        setfilterOn(false);
    };

    const handleClick = (arrayDeOpciones, date1, date2) => {
        if (arrayDeOpciones != "" && date1.value != 0 && date2.value != 0) {

            setEl2(arrayDeOpciones)
            setStartDate(new Date(date1.value).getTime());
            setEndDate(new Date(date2.value).getTime());
            setfilterOn(true);
        }
        else if (arrayDeOpciones != "") {
            setfilterOn(true);

            setEl2(arrayDeOpciones)
        }
        else if (date1.value != 0 && date2.value != 0) {

            setStartDate(new Date(date1.value).getTime());
            setEndDate(new Date(date2.value).getTime());
            setfilterOn(true);
        }
    };



    return (
        <div>
            <Sidebar />
            <ChartsGalicia
                screenSize={screenSize}
                darkModeState={darkTheme}
                language={language}
                startDate={startDate}
                endDate={endDate}
                reset={reset}
                handleClick={handleClick}
                onChange={onChange}
                opcionFilter={opcionFilter}
                arrayDeOpciones={arrayDeOpciones}
                displayCharts={displayCharts}
                surveysGalicia={surveysGalicia}
                surveyPolls={surveyPolls}
                Oldsurveys={Oldsurveys}
                FilterOn={FilterOn}
            />
        </div>
    );
}

export default ChartClientsGalicia;